import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};


export type AccountSpecEntity = {
  __typename?: 'accountSpecEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  loanPlusProjectId?: Maybe<Scalars['Float']>;
  loanPlusInfo: Scalars['JSONObject'];
  accountCode?: Maybe<Scalars['String']>;
  integrationAutoCapitalize: Scalars['Boolean'];
  leadAssignmentActivated: Scalars['Boolean'];
  purchaserAccountRef?: Maybe<PurchaserAccountReferenceEntity>;
  projectIntegration?: Maybe<Array<ProjectIntegrationEntity>>;
  smptInfo?: Maybe<SmtpInfoOutput>;
  purchaserAppCss?: Maybe<PurchaserAppCssOutput>;
  openLeadEmailRequired: Scalars['Boolean'];
};

export type ActiveLoanStatusOutput = {
  __typename?: 'ActiveLoanStatusOutput';
  count: Scalars['Float'];
  openCount: Scalars['Float'];
  unreachableCount: Scalars['Float'];
  docsSubmittedCount: Scalars['Float'];
  loanAppealedCount: Scalars['Float'];
  pendingDocsCount: Scalars['Float'];
  loanIssuedCount: Scalars['Float'];
  loanDocSignedCount: Scalars['Float'];
  loanSignedCount: Scalars['Float'];
  assignedCount: Scalars['Float'];
  unassignedCount: Scalars['Float'];
};

export type AddELaunchBookingJointBuyerInput = {
  nationality_id: Scalars['String'];
  name: Scalars['String'];
  identity_no: Scalars['String'];
  email: Scalars['String'];
  isNric: Scalars['Boolean'];
  sale_id?: Maybe<Scalars['String']>;
  phone_no: Scalars['String'];
  sst_reg_no?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  identity_type?: Maybe<Scalars['String']>;
  customer_origin?: Maybe<Scalars['String']>;
  is_foreign_purchase?: Maybe<Scalars['Boolean']>;
  is_bumi?: Maybe<Scalars['Boolean']>;
  isEInvoiceRequired?: Maybe<Scalars['Boolean']>;
  isGovAuth?: Maybe<Scalars['Boolean']>;
};

export type AddEmailLogSchemeInput = {
  refId: Scalars['String'];
  refType: Scalars['String'];
  content: Scalars['String'];
  subject: Scalars['String'];
  recipientEmail: Scalars['String'];
  ccEmails?: Maybe<Scalars['String']>;
  bccEmails?: Maybe<Scalars['String']>;
  isEmailSent: Scalars['Boolean'];
  errorMessage?: Maybe<Scalars['String']>;
};

export type AddJointBuyerOutput = {
  __typename?: 'AddJointBuyerOutput';
  isPurchaserEmailExist: Scalars['Boolean'];
  isJointBuyerExist: Scalars['Boolean'];
  jointBuyer?: Maybe<JointBuyerEntity>;
};

/** JsonType */
export type Address = {
  __typename?: 'Address';
  ID?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  siteName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  postCodeName?: Maybe<Scalars['String']>;
  AddressType?: Maybe<AddressType>;
};

export type AddressInput = {
  ID?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  siteName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  postCodeName?: Maybe<Scalars['String']>;
  AddressType?: Maybe<AddressType>;
};

export type AddressJson = {
  __typename?: 'AddressJson';
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  postCodeName?: Maybe<Scalars['String']>;
  AddressType?: Maybe<Scalars['String']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
};

export type AddressJsonInput = {
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  postCodeName?: Maybe<Scalars['String']>;
  AddressType?: Maybe<Scalars['String']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
};

export enum AddressType {
  Primary = 'PRIMARY',
  Delivery = 'DELIVERY',
  Site = 'SITE'
}

export type AgencyAppointedCount = {
  __typename?: 'agencyAppointedCount';
  totalAppointed?: Maybe<Scalars['Float']>;
  lastAppointedDate?: Maybe<Scalars['DateTime']>;
};

export type AgencyAppointedEntity = AuditEntity & {
  __typename?: 'agencyAppointedEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  agencyId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  email: Scalars['String'];
  officeTel: Scalars['String'];
  address: AddressJson;
  contactName: Scalars['String'];
  contactPhoneNo: Scalars['String'];
  contactEmail: Scalars['String'];
  invitationLink: Scalars['String'];
  agentNegotiator: Scalars['Boolean'];
  logoUrl?: Maybe<Scalars['String']>;
  projectAgencyDiscounts?: Maybe<Array<ProjectAgencyDiscountEntity>>;
  agencyContract: Array<AgencyContractEntity>;
  projectAgencyPackages?: Maybe<Array<ProjectAgencyPackageEntity>>;
  projectAgencyRebates?: Maybe<Array<ProjectAgencyRebateEntity>>;
  commonStatus: Scalars['String'];
  sales?: Maybe<Array<SaleEntity>>;
  agencyUnitCounts?: Maybe<AgentUnitCounts>;
  pictureBlockUrl?: Maybe<Scalars['String']>;
  agencyContractProjectCount?: Maybe<Scalars['Float']>;
};


export type AgencyAppointedEntityAgencyUnitCountsArgs = {
  projectId: Scalars['String'];
};


export type AgencyAppointedEntityPictureBlockUrlArgs = {
  block: Scalars['String'];
};

export type AgencyContactEntity = AuditEntity & {
  __typename?: 'agencyContactEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  userId: Scalars['String'];
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phoneNo?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  user: AgencyUserEntity;
  agencyUserRole: AgencyUserRoleEntity;
  agencyAppointed: AgencyAppointedEntity;
  languageSet: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  reportingTo?: Maybe<Scalars['String']>;
  reportingToUserId?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  isSuspendible: Scalars['Boolean'];
  isDeletable: Scalars['Boolean'];
  userLanguageChoose: LanguageOutput;
  getLangMasterData: Array<LanguageNamesOutput>;
};

export type AgencyContractEntity = AuditEntity & {
  __typename?: 'agencyContractEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  agencyId: Scalars['String'];
  projectId: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  allowUpdateLoanStatus: Scalars['Boolean'];
  allowWaitinglist: Scalars['Boolean'];
  isExpired: Scalars['Boolean'];
  allocatedUnitId: Array<Scalars['String']>;
  commonStatus: Scalars['String'];
  agency: AgencyAppointedEntity;
  isDiscountAllocated: Scalars['Boolean'];
  isPackageAllocated: Scalars['Boolean'];
  isRebateAllocated: Scalars['Boolean'];
  project: ProjectEntity;
  attachment?: Maybe<Array<AttachmentEntity>>;
};

export type AgencyEntity = AuditEntity & {
  __typename?: 'agencyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  commonStatus: Scalars['String'];
};

export type AgencyLeadNotificationInput = {
  loanAssignment?: Maybe<LoanAssignmentInput>;
};

export type AgencyLeadNotificationOutput = {
  __typename?: 'AgencyLeadNotificationOutput';
  loanAssignment?: Maybe<LoanAssignmentOutput>;
};

export type AgencyNotificationEntity = {
  __typename?: 'agencyNotificationEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  permissionId: Scalars['Float'];
  subject: Scalars['String'];
  body: Scalars['String'];
  agencyNotificationRecipient: Array<AgencyNotificationRecipientEntity>;
};

export type AgencyNotificationPolicyEntity = {
  __typename?: 'agencyNotificationPolicyEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  userId: Scalars['String'];
  permissionId: Scalars['Float'];
  notificationDuration: Scalars['Float'];
};

export type AgencyNotificationRecipientEntity = AuditEntity & {
  __typename?: 'agencyNotificationRecipientEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  notificationId: Scalars['String'];
  recipientId: Scalars['String'];
  isRead: Scalars['Boolean'];
  agencyNotification: AgencyNotificationEntity;
};

export type AgencySalesChartOutput = {
  __typename?: 'agencySalesChartOutput';
  unit_id: Scalars['String'];
  unit_status: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  unit_type: Scalars['String'];
  unit_no: Scalars['String'];
  coordinates: Scalars['String'];
  price: Scalars['Float'];
};

export type AgencyUnitAllocationEntity = AuditEntity & {
  __typename?: 'agencyUnitAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  agencyId: Scalars['String'];
  projectId: Scalars['String'];
  unitId: Scalars['String'];
  agency: AgencyEntity;
  project: ProjectEntity;
  unit: UnitEntity;
};

export type AgencyUserEntity = AuditEntity & {
  __typename?: 'agencyUserEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  loginId: Scalars['String'];
  passwordHash?: Maybe<Scalars['String']>;
  activated: Scalars['Boolean'];
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  forgotPasswordLink?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
};

export type AgencyUserRoleEntity = AuditEntity & {
  __typename?: 'agencyUserRoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  agencyId: Scalars['String'];
  userId: Scalars['String'];
  reportPersonId?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  commonStatus: Scalars['String'];
  agency: AgencyEntity;
  reportPerson: AgencyUserEntity;
  user: AgencyUserEntity;
};

export type AgentPermissionEntity = AuditEntity & {
  __typename?: 'agentPermissionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  agencyId: Scalars['String'];
  agencyUserId: Scalars['String'];
  projectId: Scalars['String'];
  allowBook: Scalars['Boolean'];
  commonStatus: Scalars['String'];
  agency: AgencyEntity;
  agencyUser: AgencyUserEntity;
  project: ProjectEntity;
  agencyContact?: Maybe<AgencyContactEntity>;
};

export type AgentUnitCounts = {
  __typename?: 'agentUnitCounts';
  allocatedunit?: Maybe<Scalars['Float']>;
  bookedunit?: Maybe<Scalars['Float']>;
  spasignedunit?: Maybe<Scalars['Float']>;
};

export type AllocationEntity = {
  __typename?: 'allocationEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  debitRefTable: Scalars['String'];
  debitId: Scalars['String'];
  creditRefTable: Scalars['String'];
  creditId: Scalars['String'];
  allocateAmount: Scalars['Float'];
  financialBillingId?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  creditNote?: Maybe<CreditNoteEntity>;
  receipt?: Maybe<ReceiptEntity>;
  rebate?: Maybe<RebateEntity>;
  project: ProjectEntity;
  progressBilling?: Maybe<ProgressBillingEntity>;
  miscBilling?: Maybe<MiscBillingEntity>;
  debitNote?: Maybe<DebitNoteEntity>;
  refund?: Maybe<RefundEntity>;
  contra?: Maybe<ContraEntity>;
  interest?: Maybe<LateInterestEntity>;
};

export type AllStandardCoreBaseEntity = StandardAuditEntity & {
  __typename?: 'AllStandardCoreBaseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};

export type Amenities = {
  __typename?: 'Amenities';
  category: Scalars['String'];
  items: Array<Scalars['String']>;
};

export type AmenitiesInput = {
  category: Scalars['String'];
  items: Array<Scalars['String']>;
};

export type AppointmentEntity = {
  __typename?: 'AppointmentEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  teamId: Scalars['String'];
  salesPersonId: Scalars['String'];
  salesPersonOrigin: Scalars['String'];
  appointmentDate: Scalars['DateTime'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  appointmentTypeId: Scalars['String'];
  contactId: Scalars['String'];
  remarks: Scalars['String'];
  needManagerAssistant: Scalars['Boolean'];
  commonStatus: Scalars['String'];
  createdBy: Scalars['String'];
  createdTs: Scalars['DateTime'];
  modBy: Scalars['String'];
  modTs: Scalars['DateTime'];
  participantLimit: Scalars['Float'];
  leadId: Scalars['String'];
  salePerson: SUserEntity;
  saleTeam: SaleTeamEntity;
  appointmentPurpose: AppointmentTypeEntity;
  lead: LeadContactEntity;
  project: ProjectEntity;
  leadSourceFromLead?: Maybe<LeadEntity>;
};

export type AppointmentSchedulingEntity = {
  __typename?: 'appointmentSchedulingEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  participantLimit: Scalars['Float'];
  salesGalleryCloseDate: Array<SalesGalleryCloseDateEntity>;
};

export type AppointmentTypeEntity = {
  __typename?: 'AppointmentTypeEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  name: Scalars['String'];
  seqNo: Scalars['Float'];
  defaulted: Scalars['Boolean'];
  systemUse: Scalars['Boolean'];
  commonStatus: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
};

/** JsonType */
export type ApprovalListJson = {
  __typename?: 'ApprovalListJson';
  stepNo: Scalars['Float'];
  roleId: Scalars['String'];
  roleName?: Maybe<Scalars['String']>;
  predicate?: Maybe<Scalars['String']>;
};

export type ApprovalListJsonInput = {
  stepNo: Scalars['Float'];
  roleId: Scalars['String'];
  roleName?: Maybe<Scalars['String']>;
  predicate?: Maybe<Scalars['String']>;
};

export type ApprovalPolicyAssignmentEntity = {
  __typename?: 'approvalPolicyAssignmentEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  entityId: Scalars['String'];
  category: Scalars['String'];
  approvalList: Array<ApprovalListJson>;
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  commonStatus: Scalars['String'];
};

export type ApprovalPolicyEntity = {
  __typename?: 'approvalPolicyEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  category: Scalars['String'];
  approvalList: Array<ApprovalListJson>;
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  commonStatus: Scalars['String'];
};

export type ArchitectCertEntity = {
  __typename?: 'architectCertEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  certificateDate: Scalars['DateTime'];
  certificateNo: Scalars['String'];
  commonStatus: Scalars['String'];
  architectCertWorkProgress?: Maybe<Array<ArchitectCertWorkProgressEntity>>;
  architectCertUnit?: Maybe<Array<ArchitectCertUnitEntity>>;
};

export type ArchitectCertUnitEntity = {
  __typename?: 'architectCertUnitEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  architectCertId: Scalars['String'];
  unitId: Scalars['String'];
  status: Scalars['String'];
  commonStatus: Scalars['String'];
  architectCert: ArchitectCertEntity;
  units?: Maybe<UnitEntity>;
};

export type ArchitectCertWorkProgressEntity = {
  __typename?: 'architectCertWorkProgressEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  architectCertId: Scalars['String'];
  workProgressId: Scalars['String'];
  commonStatus: Scalars['String'];
  architectCerts: ArchitectCertEntity;
  progress: Array<WorkProgressEntity>;
  paymentPlanDetail?: Maybe<Array<PaymentPlanDetailEntity>>;
};

export type AreaVarianceEntity = {
  __typename?: 'areaVarianceEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  saleId: Scalars['String'];
  builtUp: Scalars['Float'];
  actualBuiltUp: Scalars['Float'];
  landArea: Scalars['Float'];
  actualLandArea: Scalars['Float'];
  formula: Scalars['String'];
  compensateAmount: Scalars['Float'];
  documentNo?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
  refId?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  sales?: Maybe<SaleEntity>;
};

export type AssignedBatchDetails = {
  __typename?: 'AssignedBatchDetails';
  batch_id: Scalars['String'];
  batch_title: Scalars['String'];
  record_count: Scalars['Float'];
  isRoundRobinUser?: Maybe<Scalars['Boolean']>;
  projects?: Maybe<Array<AssignedProjectDetails>>;
  createdTs?: Maybe<Scalars['DateTime']>;
  countLeadSource?: Maybe<LeadBatchCountLeadSourceOutput>;
};

export type AssignedProjectDetails = {
  __typename?: 'AssignedProjectDetails';
  assigned_date: Scalars['DateTime'];
  project_name: Scalars['String'];
  batch_id: Scalars['String'];
  lead_count: Scalars['Float'];
};

export type AttachmentEntity = AuditEntity & {
  __typename?: 'attachmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  refTable: Scalars['String'];
  refType: Scalars['String'];
  refId: Scalars['String'];
  mediaType: Scalars['String'];
  resourceUrl: Scalars['String'];
  fileName: Scalars['String'];
  bucketFileName: Scalars['String'];
  isPrivate: Scalars['Boolean'];
  seqNo: Scalars['Float'];
  softwareCode: Scalars['String'];
  allowDelete?: Maybe<Scalars['Boolean']>;
};

export type AttachmentOutput = {
  __typename?: 'AttachmentOutput';
  fileName?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  brochureUrl?: Maybe<Scalars['String']>;
};

export type AuditEntity = {
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
};

export type AutoReleaseNotificationReminderInput = {
  reminderDay?: Maybe<Scalars['Float']>;
  salesUserReceive?: Maybe<Scalars['Boolean']>;
  agencyUserReceive?: Maybe<Scalars['Boolean']>;
};

export type AutoReleaseNotificationReminderOutput = {
  __typename?: 'autoReleaseNotificationReminderOutput';
  reminderDay?: Maybe<Scalars['Float']>;
  salesUserReceive?: Maybe<Scalars['Boolean']>;
  agencyUserReceive?: Maybe<Scalars['Boolean']>;
};

export type BankEntity = {
  __typename?: 'bankEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  commonStatus: Scalars['String'];
  financier: Array<FinancierEntity>;
};

export type BaseInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type BillItemEntity = {
  __typename?: 'billItemEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  name: Scalars['String'];
  billItemType: Scalars['String'];
  systemUse: Scalars['Boolean'];
  commonStatus: Scalars['String'];
  msicId?: Maybe<Scalars['String']>;
  classificationId?: Maybe<Scalars['String']>;
};

export type BookingAttachmentsOutput = {
  __typename?: 'bookingAttachmentsOutput';
  bookingFeeAttachments: Array<AttachmentEntity>;
  bookingCcrAttachments: Array<AttachmentEntity>;
  bookingNricAttachments: Array<AttachmentEntity>;
  bookingPdpaAttachments: Array<AttachmentEntity>;
  solicitorAgreementAttachments: Array<AttachmentEntity>;
  solicitorOthersAttachments: Array<AttachmentEntity>;
};

export type BookingCancellationNotificationInput = {
  salesUserReceive?: Maybe<Scalars['Boolean']>;
  agencyUserReceive?: Maybe<Scalars['Boolean']>;
  purchaserReceive?: Maybe<Scalars['Boolean']>;
};

export type BookingCancellationNotificationOutput = {
  __typename?: 'BookingCancellationNotificationOutput';
  salesUserReceive?: Maybe<Scalars['Boolean']>;
  agencyUserReceive?: Maybe<Scalars['Boolean']>;
  purchaserReceive?: Maybe<Scalars['Boolean']>;
};

export type BookingConfirmationStatusOutput = {
  __typename?: 'BookingConfirmationStatusOutput';
  total: Scalars['Float'];
  pendingCount?: Maybe<Scalars['Float']>;
  reservationCount: Scalars['Float'];
  purchaserAppCount: Scalars['Float'];
  eLaunchAppCount: Scalars['Float'];
  integrationCount: Scalars['Float'];
  cancelledCount: Scalars['Float'];
};

export type BookingDetailOutput = {
  __typename?: 'BookingDetailOutput';
  salesId: Scalars['String'];
  salesNo: Scalars['String'];
  projectId: Scalars['String'];
  isPurchaserInfoComplete?: Maybe<Scalars['Boolean']>;
  buyerId: Scalars['String'];
  buyerName: Scalars['String'];
  buyerNric: Scalars['String'];
  buyerNationality: Scalars['String'];
  buyerCompanyRegNo?: Maybe<Scalars['String']>;
  buyerCompanyName?: Maybe<Scalars['String']>;
  isCompany?: Maybe<Scalars['Boolean']>;
  buyerPhoneNo?: Maybe<Scalars['String']>;
  buyerEmail?: Maybe<Scalars['String']>;
  unitId: Scalars['String'];
  unitNo: Scalars['String'];
  unitLayout?: Maybe<Scalars['String']>;
  builtUp: Scalars['String'];
  unitLayoutDes?: Maybe<Scalars['String']>;
  landArea?: Maybe<Scalars['String']>;
  extraLandArea?: Maybe<Scalars['Float']>;
  isBumi?: Maybe<Scalars['Boolean']>;
  sellingPrice: Scalars['Float'];
  listPrice: Scalars['Float'];
  salesPerson?: Maybe<Scalars['String']>;
  salesStatus: Scalars['String'];
  salesPersonId?: Maybe<Scalars['String']>;
  salesTeamId?: Maybe<Scalars['String']>;
  teamName?: Maybe<Scalars['String']>;
  personName?: Maybe<Scalars['String']>;
  agentPersonId?: Maybe<Scalars['String']>;
  agencyAcctId?: Maybe<Scalars['String']>;
  agencyContractId?: Maybe<Scalars['String']>;
  agencyName?: Maybe<Scalars['String']>;
  agencyUserName?: Maybe<Scalars['String']>;
  bookingRemark?: Maybe<Scalars['String']>;
  isEInvoiceRequired?: Maybe<Scalars['Boolean']>;
  isGovAuth?: Maybe<Scalars['Boolean']>;
  identityType?: Maybe<Scalars['String']>;
  isjointBuyerComplete?: Maybe<Scalars['Boolean']>;
  isAttachmentComplete?: Maybe<Scalars['Boolean']>;
  attachmentCount?: Maybe<BookingListAttachmentOutput>;
  joinBuyer: Array<JointBuyerEntity>;
  packageList: Array<PackageEntity>;
  saleDiscount: Array<SaleDiscountEntity>;
};

export type BookingDetails = {
  __typename?: 'BookingDetails';
  data: SaleEntity;
  isPurchaserInfoComplete?: Maybe<Scalars['Boolean']>;
};

export type BookingFormDataOutput = {
  __typename?: 'BookingFormDataOutput';
  projectSpec?: Maybe<ProjectSpecOutput>;
};

export type BookingLeadsOutput = {
  __typename?: 'BookingLeadsOutput';
  lead_id?: Maybe<Scalars['String']>;
  contact_id: Scalars['String'];
  full_name: Scalars['String'];
  phone_no: Scalars['String'];
  email: Scalars['String'];
  military_ic?: Maybe<Scalars['String']>;
  ic_no?: Maybe<Scalars['String']>;
  nationality_id?: Maybe<Scalars['String']>;
  lead_source_id?: Maybe<Scalars['String']>;
};

export type BookingListAttachmentOutput = {
  __typename?: 'BookingListAttachmentOutput';
  bookingFeeAttachment?: Maybe<Scalars['Float']>;
  bookingNricAttachments?: Maybe<Scalars['Float']>;
  bookingCcrAttachments?: Maybe<Scalars['Float']>;
  bookingPdpaAttachments?: Maybe<Scalars['Float']>;
  requiredDocCount?: Maybe<Scalars['Float']>;
  completedDocCount?: Maybe<Scalars['Float']>;
  isAttachmentComplete?: Maybe<Scalars['Boolean']>;
};

export type BookingListDataOutput = {
  __typename?: 'BookingListDataOutput';
  salesId: Scalars['String'];
  projectId: Scalars['String'];
  salesStatus: Scalars['String'];
  sellingPrice: Scalars['String'];
  listPrice: Scalars['String'];
  saleDate: Scalars['DateTime'];
  unitNo: Scalars['String'];
  unitId: Scalars['String'];
  buyerId: Scalars['String'];
  unitLayout?: Maybe<Scalars['String']>;
  builtUp: Scalars['String'];
  buyerName: Scalars['String'];
  buyerNric: Scalars['String'];
  salesNo: Scalars['String'];
  cancelledDate?: Maybe<Scalars['DateTime']>;
  cancelledReason?: Maybe<Scalars['String']>;
  cancelledByName?: Maybe<Scalars['String']>;
  salePaymentId?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<Scalars['String']>;
  paidAmount?: Maybe<Scalars['Float']>;
  salePersonName?: Maybe<Scalars['String']>;
  agentPersonName?: Maybe<Scalars['String']>;
  buyerCompanyName?: Maybe<Scalars['String']>;
  buyerCompanyRegNo?: Maybe<Scalars['String']>;
  buyerPhoneNo?: Maybe<Scalars['String']>;
  buyerEmail?: Maybe<Scalars['String']>;
  salePaymentStatus?: Maybe<Scalars['String']>;
  isCompany?: Maybe<Scalars['Boolean']>;
  isPurchaserInfoComplete?: Maybe<Scalars['Boolean']>;
  bookingRemark?: Maybe<Scalars['String']>;
};

export type BookingListJointBuyerOutput = {
  __typename?: 'BookingListJointBuyerOutput';
  isNotCompleteCount?: Maybe<Scalars['Float']>;
};

export type BookingListLoanScreeningOutput = {
  __typename?: 'BookingListLoanScreeningOutput';
  caseCode: Scalars['String'];
  status: Scalars['String'];
  CCRIS?: Maybe<Scalars['String']>;
  CTOS?: Maybe<Scalars['String']>;
};

export type BookingNotificationInput = {
  assignmentSalesUserReceive?: Maybe<Scalars['Boolean']>;
  assignmentAgencyUserReceive?: Maybe<Scalars['Boolean']>;
  assignmentPurchaserReceive?: Maybe<Scalars['Boolean']>;
  attachTr?: Maybe<Scalars['Boolean']>;
  attachBookingAttachment?: Maybe<Scalars['Boolean']>;
};

export type BookingNotificationOutput = {
  __typename?: 'BookingNotificationOutput';
  assignmentSalesUserReceive?: Maybe<Scalars['Boolean']>;
  assignmentAgencyUserReceive?: Maybe<Scalars['Boolean']>;
  assignmentPurchaserReceive?: Maybe<Scalars['Boolean']>;
  attachTr?: Maybe<Scalars['Boolean']>;
  attachBookingAttachment?: Maybe<Scalars['Boolean']>;
};

export type BookingWaitlistEntity = {
  __typename?: 'bookingWaitlistEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  contactId: Scalars['String'];
  salesPersonId: Scalars['String'];
  leadId: Scalars['String'];
  teamId: Scalars['String'];
  agentPersonId?: Maybe<Scalars['String']>;
  agencyAcctId?: Maybe<Scalars['String']>;
  queueTime: Scalars['DateTime'];
  leaveTime?: Maybe<Scalars['DateTime']>;
  reason?: Maybe<Scalars['String']>;
  releaseDate?: Maybe<Scalars['DateTime']>;
  commonStatus: Scalars['String'];
  seqno: Scalars['Float'];
  contact: LeadContactEntity;
  project: ProjectEntity;
  unit: UnitEntity;
  lead: LeadEntity;
  team: SaleTeamEntity;
  salesPerson?: Maybe<UserEntity>;
  agency?: Maybe<UserEntity>;
};

export type BookStatusOutput = {
  __typename?: 'BookStatusOutput';
  total?: Maybe<Scalars['Float']>;
  reserveCount?: Maybe<Scalars['Float']>;
  bookedCount?: Maybe<Scalars['Float']>;
  signedCount?: Maybe<Scalars['Float']>;
  cancelledCount?: Maybe<Scalars['Float']>;
  totalSellingPrice?: Maybe<Scalars['Float']>;
};

export type BridgingFinancierEntity = {
  __typename?: 'bridgingFinancierEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  financierId: Scalars['String'];
  financierReferenceNo: Scalars['String'];
  solicitorId: Scalars['String'];
  solicitorReferenceNo: Scalars['String'];
  chargeExecutionType: Scalars['String'];
  chargeExecutionValue: Scalars['Float'];
  commonStatus: Scalars['String'];
  bridgingFinancierUnit: Array<BridgingFinancierUnitEntity>;
};

export type BridgingFinancierUnitEntity = {
  __typename?: 'bridgingFinancierUnitEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  projectBridgingFinancierId: Scalars['String'];
  unitId: Scalars['String'];
  amount: Scalars['Float'];
  commonStatus: Scalars['String'];
  bridgingFinancier: BridgingFinancierEntity;
};

export type CampaignEntity = {
  __typename?: 'campaignEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  commonStatus?: Maybe<Scalars['String']>;
  events: Array<EventsEntity>;
  saleRebatePackage: SaleRebatePackageEntity;
};

export type CityMasterDataOutput = {
  __typename?: 'CityMasterDataOutput';
  ID: Scalars['String'];
  name: Scalars['String'];
  stateID: Scalars['String'];
  commonStatus: Scalars['String'];
};

export type CloudStorageOutput = {
  __typename?: 'CloudStorageOutput';
  BucketFileName: Scalars['String'];
  FileName: Scalars['String'];
  Url: Scalars['String'];
  MediaType: Scalars['String'];
  IsPrivate?: Maybe<Scalars['Boolean']>;
  PrivateUrl?: Maybe<Scalars['String']>;
};

export type CollectionActivityEntity = {
  __typename?: 'collectionActivityEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  saleId: Scalars['String'];
  activityDate: Scalars['DateTime'];
  activityRemark: Scalars['String'];
  completedDate: Scalars['DateTime'];
  activityStatus: Scalars['String'];
  commonStatus: Scalars['String'];
};

export enum CommonStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Block = 'BLOCK'
}

export type CompanyArAgingPolicyEntity = {
  __typename?: 'companyArAgingPolicyEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  companyId: Scalars['String'];
  period1: Scalars['Float'];
  period2: Scalars['Float'];
  period3: Scalars['Float'];
  period4: Scalars['Float'];
  period5: Scalars['Float'];
  period6: Scalars['Float'];
  commonStatus: Scalars['String'];
  company: CompanyEntity;
};

export type CompanyBankAccountEntity = {
  __typename?: 'companyBankAccountEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  companyId: Scalars['String'];
  bankProfileId: Scalars['String'];
  code: Scalars['String'];
  accountNo: Scalars['String'];
  accountType: Scalars['String'];
  swiftCode: Scalars['String'];
  currency: Scalars['String'];
  effectiveDate: Scalars['DateTime'];
  commonStatus: Scalars['String'];
  bankProfile: CompanyBankProfileEntity;
  collection?: Maybe<Array<ReceiptEntity>>;
  receiptReversal?: Maybe<Array<ReceiptReversalEntity>>;
};

export type CompanyBankProfileEntity = {
  __typename?: 'companyBankProfileEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  companyId: Scalars['String'];
  name: Scalars['String'];
  address: Scalars['String'];
  phoneNo: Scalars['String'];
  commonStatus: Scalars['String'];
  company: CompanyEntity;
  bankAccount: Array<CompanyBankAccountEntity>;
};

export type CompanyEntity = {
  __typename?: 'companyEntity';
  id: Scalars['String'];
  parentCompanyId?: Maybe<Scalars['String']>;
  accountId: Scalars['String'];
  baseCurrencyId: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  companyRegNo?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  address?: Maybe<AddressJson>;
  contactNo?: Maybe<Scalars['String']>;
  officeTel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  sstNo?: Maybe<Scalars['String']>;
  gstNo?: Maybe<Scalars['String']>;
  gstExpiryDate?: Maybe<Scalars['DateTime']>;
  tourismTaxNo?: Maybe<Scalars['String']>;
  groupGstRegNo?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['String']>;
  imageLogoPosition?: Maybe<Scalars['String']>;
  watermarkImplementation?: Maybe<Scalars['Boolean']>;
  watermarkWording?: Maybe<Scalars['String']>;
  isoImplementation?: Maybe<Scalars['Boolean']>;
  isoLogoPosition?: Maybe<Scalars['String']>;
  companyTax?: Maybe<CompanyTax>;
  recordStatus: Scalars['String'];
  tin?: Maybe<Scalars['String']>;
  isEinvIntegrated: Scalars['Boolean'];
  eInvStartDate?: Maybe<Scalars['DateTime']>;
  eInvEndDate?: Maybe<Scalars['DateTime']>;
  eInvClientId?: Maybe<Scalars['String']>;
  eInvClientSecret?: Maybe<Scalars['String']>;
  eInvClientSecret2?: Maybe<Scalars['String']>;
  development: ProjectEntity;
  holding: ProjectEntity;
  companyArAgingPolicy?: Maybe<CompanyArAgingPolicyEntity>;
  township: Array<TownshipEntity>;
  bankProfile: Array<CompanyBankProfileEntity>;
};

export type CompanyMsicAssignmentEntity = AuditEntity & {
  __typename?: 'CompanyMsicAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  eInvoiceMsicCodeID: Scalars['String'];
  eInvoiceMsicCode: EInvoiceMsicEntity;
};

export type CompanyNumberSetupEntity = {
  __typename?: 'companyNumberSetupEntity';
  id: Scalars['String'];
  nextNumber: Scalars['Float'];
  companyId: Scalars['String'];
  numberFormatId: Scalars['String'];
  numberFormat: NumberFormatEntity;
};

export enum CompanyTax {
  NoTax = 'NO_TAX',
  SstNo = 'SST_NO',
  GstNo = 'GST_NO'
}

export type Contact = {
  __typename?: 'Contact';
  contactId: Scalars['String'];
  date?: Maybe<Scalars['String']>;
  link: Scalars['String'];
  default?: Maybe<Scalars['Boolean']>;
  contactInfo?: Maybe<FinancierContactEntity>;
};

export type ContactAddressEntity = {
  __typename?: 'contactAddressEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  address: AddressJson;
  contactId: Scalars['String'];
  contact: ContactEntity;
  isPrimary: Scalars['Boolean'];
};

export type ContactEntity = {
  __typename?: 'contactEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  fullName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phoneNo?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  icNo?: Maybe<Scalars['String']>;
  militaryIc?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companyRegNo?: Maybe<Scalars['String']>;
  address?: Maybe<AddressJson>;
  homeTel?: Maybe<Scalars['String']>;
  officeTel?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  bookingWaitlists: Array<BookingWaitlistEntity>;
  jointBuyers: Array<JointBuyerEntity>;
  leads?: Maybe<Array<LeadEntity>>;
  sales: Array<SaleEntity>;
  salutation?: Maybe<SalutationEntity>;
  nationality?: Maybe<SNationalityEntity>;
  race?: Maybe<RaceEntity>;
  maritalStatus?: Maybe<MaritalStatusEntity>;
  incomeRange?: Maybe<IncomeRangeEntity>;
  contactExt?: Maybe<ContactExtensionEntity>;
  addressList?: Maybe<Array<ContactAddressEntity>>;
  designation?: Maybe<ParameterEntity>;
  signatureUrl?: Maybe<Scalars['String']>;
  jointBuyerSignature?: Maybe<Scalars['String']>;
  isPurchaserRegisted?: Maybe<Scalars['Boolean']>;
  spouse?: Maybe<ContactEntity>;
  contactAddress?: Maybe<Array<AddressJson>>;
  jointBuyer?: Maybe<JointBuyerEntity>;
};


export type ContactEntityLeadsArgs = {
  project_id?: Maybe<Scalars['String']>;
};

export type ContactExtensionEntity = AuditEntity & {
  __typename?: 'contactExtensionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  contactId: Scalars['String'];
  identityType: Scalars['String'];
  customerOrigin: Scalars['String'];
  isBumi: Scalars['Boolean'];
  isVip: Scalars['Boolean'];
  isForeignPurchase: Scalars['Boolean'];
  subscribeMaterialSms: Scalars['Boolean'];
  subscribeMaterialWhatsapp: Scalars['Boolean'];
  subscribeMaterialEmail: Scalars['Boolean'];
  subscribeMaterialPaNews: Scalars['Boolean'];
  mailingMethod: Scalars['String'];
  tin?: Maybe<Scalars['String']>;
  sstNo?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  contact?: Maybe<ContactEntity>;
};

/** JsonType */
export type ContactPerson = {
  __typename?: 'ContactPerson';
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ContactPersonInput = {
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ContraEntity = {
  __typename?: 'contraEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  saleId: Scalars['String'];
  documentNo?: Maybe<Scalars['String']>;
  documentRef?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  documentDate: Scalars['DateTime'];
  documentAmount: Scalars['Float'];
  requestedBy?: Maybe<Scalars['String']>;
  glGenerationBatch?: Maybe<Scalars['String']>;
  glGenerated: Scalars['String'];
  submittedBy?: Maybe<Scalars['String']>;
  submittedDate?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  approvedDate?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
  commonStatus: Scalars['String'];
  allocation?: Maybe<Array<AllocationEntity>>;
  billItemId: Scalars['String'];
  voidBy?: Maybe<Scalars['String']>;
  voidDate?: Maybe<Scalars['DateTime']>;
  allowReversal: Scalars['Boolean'];
};

export type CoreBaseEntity = AuditEntity & {
  __typename?: 'CoreBaseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};

export type CountryMasterDataOutput = {
  __typename?: 'CountryMasterDataOutput';
  ID: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  commonStatus: Scalars['String'];
};

export type CountWaitingUnitOutput = {
  __typename?: 'CountWaitingUnitOutput';
  waitList?: Maybe<Scalars['Float']>;
  activeUnits?: Maybe<Scalars['Float']>;
  leaveQueue?: Maybe<Scalars['Float']>;
};

export type CreateAuditInput = {
  createdBy?: Maybe<Scalars['String']>;
};

export type CreditNoteEntity = {
  __typename?: 'creditNoteEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  saleId: Scalars['String'];
  documentNo?: Maybe<Scalars['String']>;
  documentRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  documentDate: Scalars['DateTime'];
  documentAmount: Scalars['Float'];
  adjustDocumentReference?: Maybe<Scalars['String']>;
  glGenerationBatch?: Maybe<Scalars['String']>;
  glGenerated: Scalars['String'];
  status: Scalars['String'];
  requestedBy?: Maybe<Scalars['String']>;
  submittedDate?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedDate?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  allocation?: Maybe<Array<AllocationEntity>>;
  billItemId?: Maybe<Scalars['String']>;
  allowReversal: Scalars['Boolean'];
  voidBy?: Maybe<Scalars['String']>;
  voidDate?: Maybe<Scalars['DateTime']>;
  isReversal: Scalars['Boolean'];
};

export type CurrencyExchangeRateEntity = {
  __typename?: 'currencyExchangeRateEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  fromCurrencyCode: Scalars['String'];
  toCurrencyCode: Scalars['String'];
  fromAmt: Scalars['Float'];
  toAmt: Scalars['Float'];
  exchangeRate: Scalars['Float'];
  currencyLog?: Maybe<Array<CurrencyLogObject>>;
  commonStatus: Scalars['String'];
};

export type CurrencyLogInput = {
  to_amt: Scalars['Float'];
  from_amt: Scalars['Float'];
  exchange_rate: Scalars['Float'];
  modifiedBy: Scalars['String'];
  modifiedName: Scalars['String'];
  modifiedDate: Scalars['DateTime'];
};

/** JsonType */
export type CurrencyLogObject = {
  __typename?: 'currencyLogObject';
  to_amt: Scalars['Float'];
  from_amt: Scalars['Float'];
  exchange_rate: Scalars['Float'];
  modifiedBy: Scalars['String'];
  modifiedName: Scalars['String'];
  modifiedDate: Scalars['DateTime'];
};

export type CustomerOriginSelection = {
  __typename?: 'customerOriginSelection';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type DataSource = {
  __typename?: 'DataSource';
  DataSource: Scalars['String'];
  Columns?: Maybe<Array<Scalars['String']>>;
  DataMember: Scalars['String'];
  IsStoreProcedure?: Maybe<Scalars['Boolean']>;
  IsMainQuery?: Maybe<Scalars['Boolean']>;
  DynamicFilterColumn?: Maybe<Scalars['String']>;
};

export type DataSourceInput = {
  DataSource: Scalars['String'];
  Columns?: Maybe<Array<Scalars['String']>>;
  DataMember: Scalars['String'];
  IsStoreProcedure?: Maybe<Scalars['Boolean']>;
  IsMainQuery?: Maybe<Scalars['Boolean']>;
  DynamicFilterColumn?: Maybe<Scalars['String']>;
};


export type DebitNoteEntity = {
  __typename?: 'debitNoteEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  saleId: Scalars['String'];
  documentNo?: Maybe<Scalars['String']>;
  documentRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  documentDate: Scalars['DateTime'];
  documentAmount: Scalars['Float'];
  dueDate: Scalars['DateTime'];
  creditTerm: Scalars['Float'];
  isIncludeHoliday: Scalars['Boolean'];
  interestRate: Scalars['Float'];
  adjustDocumentReference?: Maybe<Scalars['String']>;
  glGenerationBatch?: Maybe<Scalars['String']>;
  glGenerated: Scalars['String'];
  status: Scalars['String'];
  requestedBy?: Maybe<Scalars['String']>;
  submittedDate?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedDate?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  allocation?: Maybe<Array<AllocationEntity>>;
  sales?: Maybe<SaleEntity>;
  billItemId?: Maybe<Scalars['String']>;
  voidBy?: Maybe<Scalars['String']>;
  voidDate?: Maybe<Scalars['DateTime']>;
  allowReversal: Scalars['Boolean'];
  isReversal: Scalars['Boolean'];
};

export type DirectionEntity = {
  __typename?: 'directionEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  name: Scalars['String'];
  commonStatus: Scalars['String'];
  defaulted: Scalars['Boolean'];
  seq_no?: Maybe<Scalars['Float']>;
  units: Array<UnitEntity>;
};

export type DiscountEntity = {
  __typename?: 'discountEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  discountTypeId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  formula?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  discountVal?: Maybe<Scalars['Float']>;
  isIntegrated: Scalars['Boolean'];
  commonStatus: Scalars['String'];
  projectDiscounts: Array<ProjectDiscountEntity>;
  salesDiscount: Array<SaleDiscountEntity>;
  discountIntegration?: Maybe<Array<IntegrationDiscountEntity>>;
  saleRebatePackage?: Maybe<Array<SaleRebatePackageEntity>>;
  discountType?: Maybe<DiscountTypeEntity>;
  saleSubProduct?: Maybe<SaleSubproductEntity>;
  projectAgencyDiscount: ProjectAgencyDiscountEntity;
  projectAgencyRebate: ProjectAgencyRebateEntity;
  isDiscountRebate: Scalars['Boolean'];
};

/** JsonType */
export type DiscountListingObject = {
  __typename?: 'discountListingObject';
  discountId: Scalars['String'];
  title: Scalars['String'];
  seqNo: Scalars['Float'];
  amount: Scalars['Float'];
  formula: Scalars['String'];
  discountMethod: Scalars['String'];
};

export type DiscountListingUnitTransferInput = {
  discountId: Scalars['String'];
  title: Scalars['String'];
  seqNo: Scalars['Float'];
  amount: Scalars['Float'];
  formula: Scalars['String'];
  discountMethod: Scalars['String'];
};

/** JsonType */
export type DiscountObject = {
  __typename?: 'discountObject';
  discardBumi: Scalars['Boolean'];
  discounts?: Maybe<Array<DiscountListingObject>>;
};

export type DiscountParamaterValueOutput = {
  __typename?: 'DiscountParamaterValueOutput';
  amount: Scalars['Float'];
  discountValue: Scalars['Float'];
  type: Scalars['String'];
  title: Scalars['String'];
  prefix: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type DiscountTypeEntity = AuditEntity & {
  __typename?: 'discountTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  name: Scalars['String'];
  systemUse: Scalars['Boolean'];
  commonStatus: Scalars['String'];
  discount?: Maybe<DiscountEntity>;
};

export type DiscountUnitTransferInput = {
  discardBumi: Scalars['Boolean'];
  discounts?: Maybe<Array<DiscountListingUnitTransferInput>>;
};

export type DistrictMasterDataOutput = {
  __typename?: 'DistrictMasterDataOutput';
  ID: Scalars['String'];
  name: Scalars['String'];
  stateID: Scalars['String'];
  commonStatus: Scalars['String'];
};

export type DocumentNumberDetailEntity = {
  __typename?: 'documentNumberDetailEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  documentNumberHeaderId: Scalars['String'];
  sequence: Scalars['Float'];
  formatType: Scalars['String'];
  resetBy?: Maybe<Scalars['String']>;
  formatValue: Scalars['String'];
  nextNumber?: Maybe<Scalars['Float']>;
  startFrom?: Maybe<Scalars['Float']>;
  commonStatus: Scalars['String'];
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy: Scalars['String'];
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy: Scalars['String'];
  documentNumberHeader: DocumentNumberHeaderEntity;
};

export type DocumentNumberHeaderEntity = {
  __typename?: 'documentNumberHeaderEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  companyId: Scalars['String'];
  type: DocumentNumberHeaderTypeEnum;
  sampleOutput: Scalars['String'];
  refTable: Scalars['String'];
  refRecordId: Scalars['String'];
  isAutoDocNo?: Maybe<Scalars['Boolean']>;
  docNumGenerated?: Maybe<Scalars['Boolean']>;
  commonStatus: Scalars['String'];
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy: Scalars['String'];
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy: Scalars['String'];
  documentNumberDetails?: Maybe<Array<DocumentNumberDetailEntity>>;
  documentNumberResetBy?: Maybe<Array<DocumentNumberResetByEntity>>;
};

export enum DocumentNumberHeaderTypeEnum {
  Invoice = 'INVOICE',
  CreditNote = 'CREDIT_NOTE',
  DebitNote = 'DEBIT_NOTE',
  Refund = 'REFUND',
  Receipt = 'RECEIPT',
  Interest = 'INTEREST',
  Misc = 'MISC',
  Redemption = 'REDEMPTION',
  Rebate = 'REBATE',
  Contra = 'CONTRA',
  Lad = 'LAD',
  AreaVariance = 'AREA_VARIANCE',
  DescriptionList = 'DescriptionList',
  GetDescription = 'GetDescription'
}

export type DocumentNumberResetByEntity = {
  __typename?: 'documentNumberResetByEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  documentNumberHeaderId: Scalars['String'];
  companyId: Scalars['String'];
  year?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  nextNumber?: Maybe<Scalars['Float']>;
  commonStatus: Scalars['String'];
  documentNumberHeader: DocumentNumberHeaderEntity;
};

export type DownloadAttachmentInput = {
  attachmentUrl: Scalars['String'];
  attachmentName: Scalars['String'];
};

export type DownloadLogEntity = {
  __typename?: 'downloadLogEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userType: Scalars['String'];
  reportType: Scalars['String'];
  downloadDate: Scalars['DateTime'];
};

export type DownpaymentReminderEntity = AuditEntity & {
  __typename?: 'downpaymentReminderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  saleId: Scalars['String'];
  reminderDate: Scalars['DateTime'];
  reminderSeq: Scalars['Float'];
  progressBillingId: Scalars['String'];
  generationBatch?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  share?: Maybe<Array<ShareObject>>;
  balanceAmount: Scalars['Float'];
};

export type DropDownDataSource = {
  __typename?: 'DropDownDataSource';
  DataSource: Scalars['String'];
  DataMember: Scalars['String'];
  parameters: Array<DropDownParamterInterface>;
};

export type DropDownDataSourceInput = {
  DataSource: Scalars['String'];
  DataMember: Scalars['String'];
  parameters: Array<DropDownDataSourceParameterInput>;
};

export type DropDownDataSourceParameterInput = {
  Name: Scalars['String'];
  ParameterName: Scalars['String'];
};

export type DropDownMember = {
  __typename?: 'DropDownMember';
  ValueSource: Scalars['String'];
  DynamicSource?: Maybe<DynamicSource>;
  StaticData?: Maybe<Array<StaticData>>;
};

export type DropDownMemberInput = {
  ValueSource: Scalars['String'];
  DynamicSource?: Maybe<DynamicSourceInput>;
  StaticData?: Maybe<Array<StaticDataInput>>;
};

export type DropDownParamterInterface = {
  __typename?: 'DropDownParamterInterface';
  Name: Scalars['String'];
  ParameterName: Scalars['String'];
};

export type DynamicSource = {
  __typename?: 'DynamicSource';
  DisplayMember: Scalars['String'];
  ValueMember: Scalars['String'];
  DataSource: DropDownDataSource;
};

export type DynamicSourceInput = {
  DisplayMember: Scalars['String'];
  ValueMember: Scalars['String'];
  DataSource: DropDownDataSourceInput;
};

export type EditELaunchBookingJointBuyerInput = {
  nationality_id: Scalars['String'];
  name: Scalars['String'];
  identity_no: Scalars['String'];
  email: Scalars['String'];
  isNric: Scalars['Boolean'];
  sale_id?: Maybe<Scalars['String']>;
  phone_no: Scalars['String'];
  sst_reg_no?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  identity_type?: Maybe<Scalars['String']>;
  customer_origin?: Maybe<Scalars['String']>;
  is_foreign_purchase?: Maybe<Scalars['Boolean']>;
  is_bumi?: Maybe<Scalars['Boolean']>;
  isEInvoiceRequired?: Maybe<Scalars['Boolean']>;
  isGovAuth?: Maybe<Scalars['Boolean']>;
  contact_id?: Maybe<Scalars['String']>;
};

export type EditEmailLogSchemeInput = {
  refId: Scalars['String'];
  refType: Scalars['String'];
  content: Scalars['String'];
  subject: Scalars['String'];
  recipientEmail: Scalars['String'];
  ccEmails?: Maybe<Scalars['String']>;
  bccEmails?: Maybe<Scalars['String']>;
  isEmailSent: Scalars['Boolean'];
  errorMessage?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
};

export type EditPurchaserInfoInput = {
  sale_id: Scalars['String'];
  contact_id: Scalars['String'];
  email: Scalars['String'];
  nationality_id: Scalars['String'];
  fullName: Scalars['String'];
  nric: Scalars['String'];
  sst_reg_no?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  identity_type?: Maybe<Scalars['String']>;
  customer_origin?: Maybe<Scalars['String']>;
  is_foreign_purchase: Scalars['Boolean'];
  is_bumi: Scalars['Boolean'];
  isEInvoiceRequired: Scalars['Boolean'];
  isGovAuth: Scalars['Boolean'];
};

export type EInvoiceClassificationEntity = {
  __typename?: 'eInvoiceClassificationEntity';
  id: Scalars['String'];
  classificationCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type EInvoiceDetailsInput = {
  __typename?: 'EInvoiceDetailsInput';
  tinNo?: Maybe<Scalars['String']>;
  sstTaxRegNo?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  isForeignBuyer?: Maybe<Scalars['String']>;
  isGovOrAuth?: Maybe<Scalars['String']>;
  isEinvRequired?: Maybe<Scalars['String']>;
  debtorEinvClass?: Maybe<Scalars['String']>;
};

export type EInvoiceGeneralTinEntity = {
  __typename?: 'eInvoiceGeneralTinEntity';
  id: Scalars['String'];
  tinParty?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  displaySeq?: Maybe<Scalars['Float']>;
};

export type EInvoiceMsicEntity = {
  __typename?: 'eInvoiceMsicEntity';
  id: Scalars['String'];
  msicCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  categoryReference?: Maybe<Scalars['String']>;
  companyMsicAssignment: Array<CompanyMsicAssignmentEntity>;
};

export type EInvoiceUomEntity = {
  __typename?: 'eInvoiceUomEntity';
  id: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ELaunchAddAttachmentsInput = {
  sale_id: Scalars['String'];
  ref_type: Scalars['String'];
  attachments: Array<Scalars['Upload']>;
};

export type ELaunchAddBookingExpressInput = {
  unit_id: Scalars['String'];
  buyer: ELaunchBookingExpressBuyerInput;
  pay_method?: Maybe<Scalars['String']>;
  pay_amount?: Maybe<Scalars['Float']>;
  pay_remark?: Maybe<Scalars['String']>;
  ref_num?: Maybe<Scalars['String']>;
  bookingFeeAttachment?: Maybe<Scalars['Upload']>;
  joint_buyers?: Maybe<Array<ELaunchBookingExpressJointBuyerInput>>;
  bookingTimeTaken: Scalars['Float'];
  sst_reg_no?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  identity_type?: Maybe<Scalars['String']>;
  customer_origin?: Maybe<Scalars['String']>;
  is_foreign_purchase?: Maybe<Scalars['Boolean']>;
  isEInvoiceRequired?: Maybe<Scalars['Boolean']>;
  isGovAuth?: Maybe<Scalars['Boolean']>;
};

export type ELaunchAllocationEntity = AuditEntity & {
  __typename?: 'eLaunchAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  eLaunchId: Scalars['String'];
  unitId: Scalars['String'];
  eLaunch: ELaunchEntity;
  unit: UnitEntity;
};

export type ELaunchBookingExpressBuyerBaseInput = {
  nationality_id: Scalars['String'];
  name: Scalars['String'];
  identity_no: Scalars['String'];
  email: Scalars['String'];
  isNric: Scalars['Boolean'];
};

export type ELaunchBookingExpressBuyerInput = {
  nationality_id: Scalars['String'];
  name: Scalars['String'];
  identity_no: Scalars['String'];
  email: Scalars['String'];
  isNric: Scalars['Boolean'];
  signature?: Maybe<Scalars['String']>;
  nricBase64?: Maybe<Scalars['String']>;
  sst_reg_no?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  identity_type?: Maybe<Scalars['String']>;
  customer_origin?: Maybe<Scalars['String']>;
  is_foreign_purchase?: Maybe<Scalars['Boolean']>;
  isEInvoiceRequired?: Maybe<Scalars['Boolean']>;
  isGovAuth?: Maybe<Scalars['Boolean']>;
};

export type ELaunchBookingExpressJointBuyerInput = {
  nationality_id: Scalars['String'];
  name: Scalars['String'];
  identity_no: Scalars['String'];
  email: Scalars['String'];
  isNric: Scalars['Boolean'];
  phone_no: Scalars['String'];
  sst_reg_no?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  identity_type?: Maybe<Scalars['String']>;
  customer_origin?: Maybe<Scalars['String']>;
  is_foreign_purchase?: Maybe<Scalars['Boolean']>;
  is_bumi: Scalars['Boolean'];
  isEInvoiceRequired?: Maybe<Scalars['Boolean']>;
  isGovAuth?: Maybe<Scalars['Boolean']>;
};

export type ELaunchContactEntity = AuditEntity & {
  __typename?: 'eLaunchContactEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  userId: Scalars['String'];
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phoneNo?: Maybe<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
  elaunchUser: ELaunchUserEntity;
  getLangMasterData: Array<LanguageNamesOutput>;
  userLanguageChoose: LanguageOutput;
};

export type ELaunchDownloadAttachmentOuput = {
  __typename?: 'eLaunchDownloadAttachmentOuput';
  fileName: Scalars['String'];
  base64: Scalars['String'];
};

export type ELaunchEntity = AuditEntity & {
  __typename?: 'eLaunchEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  publishDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  terminationDate: Scalars['DateTime'];
  commonStatus: Scalars['String'];
  eLaunchAllocation?: Maybe<Array<ELaunchAllocationEntity>>;
  eLaunchLeadBatch?: Maybe<Array<ELaunchLeadBatchEntity>>;
  hasEnded?: Maybe<Scalars['Boolean']>;
  hasPublished?: Maybe<Scalars['Boolean']>;
};

export type ELaunchFavouriteUnitEntity = AuditEntity & {
  __typename?: 'eLaunchFavouriteUnitEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  userId: Scalars['String'];
  eLaunchId: Scalars['String'];
  unitId: Scalars['String'];
  commonStatus: Scalars['String'];
  eLaunchUser: ELaunchUserEntity;
};

export type ELaunchInvitationTemplateEntity = AuditEntity & {
  __typename?: 'eLaunchInvitationTemplateEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  eLaunchId: Scalars['String'];
  subject?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  salesDocId?: Maybe<Array<Scalars['String']>>;
  accountSpec: AccountSpecEntity;
  eLaunch: ELaunchEntity;
  salesDoc?: Maybe<SalesDocEntity>;
  commonStatus: Scalars['String'];
};

export type ELaunchLeadBatchEntity = AuditEntity & {
  __typename?: 'eLaunchLeadBatchEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  eLaunchId: Scalars['String'];
  batchTitle: Scalars['String'];
  recordCount: Scalars['Float'];
  lastEmailSend?: Maybe<Array<LastEmailSendOutput>>;
  eLaunch: ELaunchEntity;
};

export type ELaunchLeadEntity = AuditEntity & {
  __typename?: 'eLaunchLeadEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  eLaunchBatchId: Scalars['String'];
  leadId: Scalars['String'];
  eLaunchLeadBatch: ELaunchLeadBatchEntity;
  lead: LeadEntity;
};

export type ELaunchLogEntity = AuditEntity & {
  __typename?: 'eLaunchLogEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  eLaunchId: Scalars['String'];
  log: Scalars['JSONObject'];
  eLaunch: ELaunchEntity;
};

export type ELaunchOtpEntity = AuditEntity & {
  __typename?: 'eLaunchOtpEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  phoneNo: Scalars['String'];
  otpCode: Scalars['String'];
  otpType: Scalars['String'];
  otpExpiry: Scalars['DateTime'];
  ipAddress?: Maybe<Scalars['String']>;
};

export type ELaunchRegisterEntity = AuditEntity & {
  __typename?: 'eLaunchRegisterEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  userId: Scalars['String'];
  eLaunchId: Scalars['String'];
  leadId: Scalars['String'];
  invitedById?: Maybe<Scalars['String']>;
  invitedBy?: Maybe<Scalars['String']>;
  eLaunch: ELaunchEntity;
  eLaunchUser: ELaunchUserEntity;
};

export type ELaunchUserEntity = AuditEntity & {
  __typename?: 'eLaunchUserEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  loginId: Scalars['String'];
  activated: Scalars['Boolean'];
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  forgotPasswordLink?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  languageSet: Scalars['String'];
};

export type ELetterOutput = {
  __typename?: 'ELetterOutput';
  fileName: Scalars['String'];
  content: Scalars['String'];
};

export type EmailLetterEntity = {
  __typename?: 'emailLetterEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  name: Scalars['String'];
  emailType: Scalars['String'];
  commonStatus: Scalars['String'];
  emailLetterParameter: Array<EmailLetterParameterEntity>;
  emailLetterTemplate: Array<EmailLetterTemplateEntity>;
  seqNo: Scalars['Float'];
};

export type EmailLetterParameterEntity = {
  __typename?: 'emailLetterParameterEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  emailLetterId: Scalars['String'];
  displayName: Scalars['String'];
  columnName: Scalars['String'];
  commonStatus: Scalars['String'];
  emailLetter: EmailLetterEntity;
};

export type EmailLetterTemplateEntity = AuditEntity & {
  __typename?: 'emailLetterTemplateEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  emailLetterId: Scalars['String'];
  name: Scalars['String'];
  templateBody?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  emailLetter: EmailLetterEntity;
  systemUse: Scalars['Boolean'];
  projectEmailLetter: ProjectEmailLetterEntity;
};

export type EmailLogEntity = {
  __typename?: 'emailLogEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  refId: Scalars['String'];
  refType: Scalars['String'];
  content: Scalars['String'];
  subject: Scalars['String'];
  recipientEmail: Scalars['String'];
  ccEmails: Scalars['String'];
  bccEmails: Scalars['String'];
  isEmailSent: Scalars['Boolean'];
  errorMessage?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
};

export type EmailLogOutput = {
  __typename?: 'EmailLogOutput';
  id: Scalars['String'];
  accountId: Scalars['String'];
  refId: Scalars['String'];
  refType: Scalars['String'];
  content: Scalars['String'];
  subject: Scalars['String'];
  recipientEmail: Scalars['String'];
  ccEmails?: Maybe<Scalars['String']>;
  bccEmails?: Maybe<Scalars['String']>;
  isEmailSent: Scalars['Boolean'];
  errorMessage?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
};

export type EndfinContactEntity = {
  __typename?: 'endfinContactEntity';
  id: Scalars['String'];
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phoneNo?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  user: EndfinUserEntity;
};

export type EndfinFinancierEntity = {
  __typename?: 'endfinFinancierEntity';
  id: Scalars['String'];
  name: Scalars['String'];
  activated: Scalars['Boolean'];
  commonStatus: Scalars['String'];
  endfinUserRoles: Array<EndfinUserRoleEntity>;
  financiers: Array<FinancierEntity>;
  staffCount?: Maybe<Scalars['Float']>;
  financier?: Maybe<FinancierEntity>;
};

export type EndfinLoanAppActivityEntity = {
  __typename?: 'endfinLoanAppActivityEntity';
  id: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
  activityType: Scalars['String'];
  loanAppId: Scalars['String'];
  loanAppStatusId: Scalars['String'];
  loanApp: EndfinLoanAppEntity;
  loanAppStatus: EndfinLoanAppStatusEntity;
  createdBy?: Maybe<Scalars['String']>;
  createdDate: Scalars['DateTime'];
  activityTypeDesc: Scalars['String'];
};

export type EndfinLoanAppEntity = {
  __typename?: 'endfinLoanAppEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  applyAmount: Scalars['Float'];
  applyDate: Scalars['DateTime'];
  approvalDate?: Maybe<Scalars['DateTime']>;
  assignedDate?: Maybe<Scalars['DateTime']>;
  tenure?: Maybe<Scalars['Float']>;
  approvalAmount?: Maybe<Scalars['Float']>;
  financierId: Scalars['String'];
  loanAppStatusId: Scalars['String'];
  saleId: Scalars['String'];
  userId: Scalars['String'];
  loanType: Scalars['String'];
  financier: FinancierEntity;
  loanAppStatus: EndfinLoanAppStatusEntity;
  sale: SaleEntity;
  user?: Maybe<EndfinUserEntity>;
  endfinLoanAppActivities: Array<EndfinLoanAppActivityEntity>;
  staff?: Maybe<EndfinUserEntity>;
  activityData?: Maybe<Array<EndfinLoanAppActivityEntity>>;
  lastActivity?: Maybe<EndfinLoanAppActivityEntity>;
  documentData?: Maybe<Array<AttachmentEntity>>;
  eLetteringExistenceType?: Maybe<Array<Scalars['String']>>;
  agingDate?: Maybe<Scalars['DateTime']>;
};

export type EndfinLoanAppStatusEntity = {
  __typename?: 'endfinLoanAppStatusEntity';
  id: Scalars['String'];
  statusCode: Scalars['String'];
  statusName: Scalars['String'];
  stages: Scalars['Float'];
  seqNo: Scalars['Float'];
  endfinLoanApps: Array<EndfinLoanAppEntity>;
  endfinLoanAppActivities: Array<EndfinLoanAppActivityEntity>;
};

export type EndfinNotificationEntity = AuditEntity & {
  __typename?: 'endfinNotificationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  permissionId: Scalars['Float'];
  subject: Scalars['String'];
  body: Scalars['String'];
  endfinNotificationRecipient: Array<EndfinNotificationRecipientEntity>;
};

export type EndfinNotificationPolicyEntity = {
  __typename?: 'endfinNotificationPolicyEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  userId: Scalars['String'];
  permissionId: Scalars['Float'];
  notificationDuration: Scalars['Float'];
};

export type EndfinNotificationRecipientEntity = AuditEntity & {
  __typename?: 'endfinNotificationRecipientEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  notificationId: Scalars['String'];
  recipientId: Scalars['String'];
  isRead: Scalars['Boolean'];
  endfinNotification: EndfinNotificationEntity;
};

export type EndfinProjectFinancierEntity = {
  __typename?: 'endfinProjectFinancierEntity';
  id: Scalars['String'];
  isPanel: Scalars['Boolean'];
  commonStatus: Scalars['String'];
  accountId: Scalars['String'];
  financierId: Scalars['String'];
  projectId: Scalars['String'];
  financialLimit: Scalars['Float'];
  interestRate?: Maybe<Scalars['Float']>;
  financier: FinancierEntity;
  project: ProjectEntity;
  financierInfo: FinancierEntity;
  assignedDate: Scalars['DateTime'];
};

export type EndfinUserEntity = {
  __typename?: 'endfinUserEntity';
  id: Scalars['String'];
  loginId: Scalars['String'];
  passwordHash?: Maybe<Scalars['String']>;
  activated: Scalars['Boolean'];
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  forgotPasswordLink?: Maybe<Scalars['String']>;
  endfinContacts: EndfinContactEntity;
  endfinUserRoles: EndfinUserRoleEntity;
  endfinLoanApps: Array<EndfinLoanAppEntity>;
  contact?: Maybe<EndfinContactEntity>;
  userRole?: Maybe<EndfinUserRoleEntity>;
  assignedLoanCount?: Maybe<LoanAssignedCountOutput>;
  projectLoanCount?: Maybe<Array<LoanProjectCountOutput>>;
};

export type EndfinUserRoleEntity = {
  __typename?: 'endfinUserRoleEntity';
  id: Scalars['String'];
  role: Scalars['String'];
  commonStatus: Scalars['String'];
  financierId: Scalars['String'];
  userId: Scalars['String'];
  financier: EndfinFinancierEntity;
  user: EndfinUserEntity;
};

export type EntityRoleUserAssignmentEntity = AuditEntity & {
  __typename?: 'entityRoleUserAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  entityId: Scalars['String'];
  roleId: Scalars['String'];
  userId: Scalars['String'];
};

export type ESignProjectLetterListingOutput = {
  __typename?: 'ESignProjectLetterListingOutput';
  letterTemplateId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  letterType?: Maybe<Scalars['String']>;
  defaulted?: Maybe<Scalars['Boolean']>;
};

export type EventsEntity = {
  __typename?: 'eventsEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  campaignId: Scalars['String'];
  name: Scalars['String'];
  desc?: Maybe<Scalars['String']>;
  website: Scalars['String'];
  location: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  budget: Scalars['Float'];
  commonStatus?: Maybe<Scalars['String']>;
  campaign: CampaignEntity;
};

export type FavouritedBookedUnitOutput = {
  __typename?: 'favouritedBookedUnitOutput';
  unitId: Scalars['String'];
  unitNo: Scalars['String'];
  unitLayout?: Maybe<Scalars['String']>;
  builtUp: Scalars['String'];
  price: Scalars['Float'];
  saleId?: Maybe<Scalars['String']>;
  isBookedByOther: Scalars['Boolean'];
};

export type FieldError = {
  __typename?: 'FieldError';
  field: Scalars['String'];
  message: Scalars['String'];
};

export type FinancialBillingEntity = {
  __typename?: 'financialBillingEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  saleId: Scalars['String'];
  saleLoanId: Scalars['String'];
  financierId: Scalars['String'];
  progressBillingId: Scalars['String'];
  documentAmount?: Maybe<Scalars['Float']>;
  principalAmt: Scalars['Float'];
  taxAmount?: Maybe<Scalars['Float']>;
  commonStatus: Scalars['String'];
  progressBilling?: Maybe<ProgressBillingEntity>;
  saleLoan?: Maybe<SaleLoanEntity>;
};

export type FinancierContactEntity = {
  __typename?: 'financierContactEntity';
  id: Scalars['String'];
  name: Scalars['String'];
  email: Scalars['String'];
  phoneNo: Scalars['String'];
  financierId: Scalars['String'];
  financier: FinancierEntity;
};

export type FinancierEntity = {
  __typename?: 'financierEntity';
  id: Scalars['String'];
  name: Scalars['String'];
  address?: Maybe<AddressJson>;
  projectInvitation?: Maybe<Array<FinancierProject>>;
  officeTel?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  accountId: Scalars['String'];
  financierId?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  financierCode?: Maybe<Scalars['String']>;
  bankId?: Maybe<Scalars['String']>;
  disbursementFinancierId?: Maybe<Scalars['String']>;
  financier: EndfinFinancierEntity;
  financierContact: Array<FinancierContactEntity>;
  endfinLoanApps: Array<EndfinLoanAppEntity>;
  endfinProjectFinanciers: Array<EndfinProjectFinancierEntity>;
  saleLoan?: Maybe<Array<SaleLoanEntity>>;
  collection?: Maybe<Array<SaleLoanEntity>>;
  receiptReversal?: Maybe<Array<ReceiptReversalEntity>>;
  bank?: Maybe<BankEntity>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  assignedProjectCount: Scalars['Float'];
  loanCount: Scalars['Float'];
  financialLimit?: Maybe<Scalars['Float']>;
};

export type FinancierProject = {
  __typename?: 'FinancierProject';
  projectId: Scalars['String'];
  invitation: Array<Contact>;
};

export type FinancierUnitMortgage = {
  __typename?: 'financierUnitMortgage';
  financierName?: Maybe<Scalars['String']>;
  interestRate?: Maybe<Scalars['Float']>;
};

export type FloorOutput = {
  __typename?: 'FloorOutput';
  projectid: Scalars['String'];
  block: Scalars['String'];
  floor: Scalars['Float'];
  floorlabel: Scalars['String'];
  isSubProduct: Scalars['Boolean'];
  Unit?: Maybe<Array<UnitEntity>>;
  ActiveUnit?: Maybe<Array<UnitEntity>>;
};

export type ForgetPasswordInput = {
  accountCode: Scalars['String'];
  contactNo: Scalars['String'];
  otpCode: Scalars['String'];
  password: Scalars['String'];
  confirmPassword: Scalars['String'];
};

export type GetBlockUnitsOutput = {
  __typename?: 'GetBlockUnitsOutput';
  pictureBlockUrl: Scalars['String'];
  salesChartUnitDisplay: Array<SalesChart3DUnitOutput>;
};

export type GetPublicBrochureOutput = {
  __typename?: 'getPublicBrochureOutput';
  docName: Scalars['String'];
  attachment: ResourceUrl;
};

export type GlAccountEntity = {
  __typename?: 'glAccountEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  companyId: Scalars['String'];
  glType: Scalars['String'];
  refTable: Scalars['String'];
  refId: Scalars['String'];
  glAccountCode: Scalars['String'];
  commonStatus: Scalars['String'];
};

export type GlExportEntity = {
  __typename?: 'glExportEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  companyId: Scalars['String'];
  glDate: Scalars['DateTime'];
  glType: Scalars['String'];
  glTransactionType: Scalars['String'];
  glAccountCode: Scalars['String'];
  costCentre: Scalars['String'];
  journalType: Scalars['String'];
  refDocumentDate: Scalars['DateTime'];
  refTable: Scalars['String'];
  refId: Scalars['String'];
  refDocumentNo: Scalars['String'];
  refDescription?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  debitAmount: Scalars['Float'];
  creditAmount: Scalars['Float'];
  glGenerationBatch: Scalars['String'];
  posted: Scalars['Boolean'];
  commonStatus: Scalars['String'];
};

export type HuaweiStorageOutput = {
  __typename?: 'HuaweiStorageOutput';
  BucketFileName: Scalars['String'];
  FileName: Scalars['String'];
  Url: Scalars['String'];
  MediaType: Scalars['String'];
  IsPrivate?: Maybe<Scalars['Boolean']>;
  PrivateUrl?: Maybe<Scalars['String']>;
};

export type IdentityTypeSelection = {
  __typename?: 'identityTypeSelection';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type ImportLeadDetails = {
  __typename?: 'ImportLeadDetails';
  contact_id?: Maybe<Scalars['String']>;
  full_name: Scalars['String'];
  phone_no: Scalars['String'];
  email: Scalars['String'];
  lead_source_id: Scalars['String'];
  event_id?: Maybe<Scalars['String']>;
  salutation_id?: Maybe<Scalars['String']>;
  nationality_id?: Maybe<Scalars['String']>;
  gender_id?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['DateTime']>;
  race_id?: Maybe<Scalars['String']>;
  marital_id?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  prefer_location?: Maybe<Scalars['String']>;
  prefer_property?: Maybe<Scalars['String']>;
  annual_income?: Maybe<Scalars['String']>;
  isContactExisted: Scalars['Boolean'];
  utm_id?: Maybe<Scalars['String']>;
  utm_source?: Maybe<Scalars['String']>;
  utm_medium?: Maybe<Scalars['String']>;
  utm_campaign?: Maybe<Scalars['String']>;
  utm_term?: Maybe<Scalars['String']>;
  utm_content?: Maybe<Scalars['String']>;
  lead_status?: Maybe<Scalars['String']>;
  sales_person_assigned_id?: Maybe<Scalars['String']>;
  assign_status?: Maybe<Scalars['String']>;
  lead_remark?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
};

/** JsonType */
export type IncludeInDetail = {
  __typename?: 'includeInDetail';
  paymentPlanDetailId?: Maybe<Scalars['String']>;
};

export type IncludeInDetailInput = {
  paymentPlanDetailId?: Maybe<Scalars['String']>;
};

export type IncomeRangeEntity = {
  __typename?: 'incomeRangeEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  range: Scalars['String'];
  seqNo?: Maybe<Scalars['Float']>;
  commonStatus: Scalars['String'];
  defaulted: Scalars['Boolean'];
  sales: Array<SaleEntity>;
  contact: Array<ContactEntity>;
  leadContact: Array<LeadContactEntity>;
};

export type IntegrationDiscountEntity = {
  __typename?: 'integrationDiscountEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  discountId: Scalars['String'];
  ifcaDiscountId: Scalars['Float'];
  effectiveDate?: Maybe<Scalars['DateTime']>;
  expiredDate?: Maybe<Scalars['DateTime']>;
  commonStatus: Scalars['String'];
  discount: DiscountEntity;
  project: ProjectEntity;
  projectIntegration: ProjectIntegrationEntity;
};

export type IntegrationPackageEntity = {
  __typename?: 'integrationPackageEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  packageId: Scalars['String'];
  ifcaPackageId: Scalars['Float'];
  commonStatus: Scalars['String'];
  package: PackageEntity;
  project: ProjectEntity;
};

export type IntegrationSolicitorEntity = {
  __typename?: 'integrationSolicitorEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  solicitorId: Scalars['String'];
  ifcaSolicitorId: Scalars['Float'];
  commonStatus: Scalars['String'];
  solicitor: SolicitorAppointedEntity;
  project: ProjectEntity;
  projectIntegration: ProjectIntegrationEntity;
};

export type InterestEntity = {
  __typename?: 'interestEntity';
  id: Scalars['String'];
  overdueAmount: Scalars['String'];
  interestDate: Scalars['DateTime'];
  interestRate: Scalars['String'];
  interestAmount: Scalars['String'];
  trxStatus: Scalars['String'];
  invoiceId: Scalars['String'];
  postedDate?: Maybe<Scalars['DateTime']>;
  overdueDay: Scalars['Float'];
  commonStatus: Scalars['String'];
  batchId?: Maybe<Scalars['String']>;
  isWaive: Scalars['Boolean'];
  account_id: Scalars['String'];
  invoice: LedgerEntity;
  ledger: LedgerEntity;
  project: ProjectEntity;
  sale: SaleEntity;
};

export type Ipay88PaymentOutput = {
  __typename?: 'Ipay88PaymentOutput';
  refNo: Scalars['String'];
  amount: Scalars['String'];
  currency: Scalars['String'];
  prodDesc: Scalars['String'];
  name: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
  lang: Scalars['String'];
  method: Scalars['String'];
  paymentId: Scalars['String'];
  signature: Scalars['String'];
  signatureType: Scalars['String'];
  paymentUrl: Scalars['String'];
  merchantCode: Scalars['String'];
  redirectUrl: Scalars['String'];
  notifyUrl: Scalars['String'];
};

export type JointBuyerEntity = {
  __typename?: 'jointBuyerEntity';
  id: Scalars['String'];
  contactId: Scalars['String'];
  saleId: Scalars['String'];
  commonStatus: Scalars['String'];
  ifcaContactId: Scalars['String'];
  ifcaSaleId: Scalars['Float'];
  seq_no?: Maybe<Scalars['Float']>;
  jointBuyerName?: Maybe<Scalars['String']>;
  jointBuyerIdentityNo?: Maybe<Scalars['String']>;
  jointBuyerIdentityType?: Maybe<Scalars['String']>;
  contact: ContactEntity;
  sale: SaleEntity;
  isEInvoiceRequired: Scalars['Boolean'];
  isGovAuth: Scalars['Boolean'];
};

export type JointBuyerInfoForBookingListing = {
  __typename?: 'JointBuyerInfoForBookingListing';
  saleId?: Maybe<Scalars['String']>;
  totalJointBuyer?: Maybe<Scalars['Float']>;
  isJointBuyerInfoRequired?: Maybe<Scalars['Boolean']>;
};

export type JointBuyerParamaterValueOutput = {
  __typename?: 'JointBuyerParamaterValueOutput';
  contact: Scalars['String'];
  nationality: Scalars['String'];
  salutation: Scalars['String'];
  race: Scalars['String'];
  incomeRange: Scalars['String'];
};


export type KeyCollectionAppointmentEntity = {
  __typename?: 'keyCollectionAppointmentEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  saleId: Scalars['String'];
  keyCollectionTimeslotId: Scalars['String'];
  plumberId?: Maybe<Scalars['String']>;
  electricianId?: Maybe<Scalars['String']>;
  alarmVendorId?: Maybe<Scalars['String']>;
  siteSupervisorId?: Maybe<Scalars['String']>;
  handOverDate?: Maybe<Scalars['DateTime']>;
  keysCollected?: Maybe<Scalars['Float']>;
  sanitaryDate?: Maybe<Scalars['DateTime']>;
  sanitaryRemark?: Maybe<Scalars['String']>;
  electricalDate?: Maybe<Scalars['DateTime']>;
  electricalRemark?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  commonStatus: Scalars['String'];
  sales?: Maybe<SaleEntity>;
  keyCollectionTimeslot?: Maybe<ProjectKeyCollectionTimeSlotEntity>;
};

export type KeyCollectionAppointmentRescheduleInput = {
  salesUserReceive?: Maybe<Scalars['Boolean']>;
  agencyUserReceive?: Maybe<Scalars['Boolean']>;
  keyCollectionSalesPersonReceive?: Maybe<Scalars['Boolean']>;
  purchaserUserReceive?: Maybe<Scalars['Boolean']>;
};

export type LadEntity = {
  __typename?: 'ladEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  saleId: Scalars['String'];
  startWith: Scalars['String'];
  unitLadPercent: Scalars['Float'];
  unitDuration: Scalars['Float'];
  payoutPercent: Scalars['Float'];
  spaPercent: Scalars['Float'];
  commonFacility: Scalars['Boolean'];
  facilityLadPercent?: Maybe<Scalars['Float']>;
  facilityLadDuration?: Maybe<Scalars['Float']>;
  unitLadAmount: Scalars['Float'];
  facilityLadAmount?: Maybe<Scalars['Float']>;
  byFacilityDate?: Maybe<Scalars['DateTime']>;
  isSimulate: Scalars['Boolean'];
  contraId?: Maybe<Scalars['String']>;
  generationBatch: Scalars['String'];
  byUnitDate: Scalars['DateTime'];
  generationBatchDate: Scalars['DateTime'];
  unitDueDays?: Maybe<Scalars['Float']>;
  facilityDueDays?: Maybe<Scalars['Float']>;
  documentNo?: Maybe<Scalars['String']>;
  voidBy?: Maybe<Scalars['String']>;
  voidDate?: Maybe<Scalars['DateTime']>;
  commonStatus: Scalars['String'];
};

export type LanguageNamesOutput = {
  __typename?: 'LanguageNamesOutput';
  langCode?: Maybe<Scalars['String']>;
  langName?: Maybe<Scalars['String']>;
};

export type LanguageOutput = {
  __typename?: 'LanguageOutput';
  obj?: Maybe<Scalars['String']>;
  fileNames?: Maybe<Array<Scalars['String']>>;
};

export type LastEmailSendInput = {
  SendBy: Scalars['String'];
  SendDate: Scalars['DateTime'];
};

export type LastEmailSendOutput = {
  __typename?: 'LastEmailSendOutput';
  SendBy: Scalars['String'];
  SendDate: Scalars['DateTime'];
};

export type LateInterestEntity = {
  __typename?: 'lateInterestEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  saleId: Scalars['String'];
  refTable: Scalars['String'];
  refId: Scalars['String'];
  overdueDay: Scalars['Float'];
  documentNo?: Maybe<Scalars['String']>;
  documentRef?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  interestStartDate: Scalars['DateTime'];
  interestEndDate?: Maybe<Scalars['DateTime']>;
  documentDate?: Maybe<Scalars['DateTime']>;
  documentAmount: Scalars['Float'];
  interestAmount: Scalars['Float'];
  waiveAmount?: Maybe<Scalars['Float']>;
  calculateAmt: Scalars['Float'];
  calculateRate: Scalars['Float'];
  dueDate?: Maybe<Scalars['DateTime']>;
  creditTerm?: Maybe<Scalars['Float']>;
  isIncludeHoliday: Scalars['Boolean'];
  glGenerationBatch?: Maybe<Scalars['String']>;
  glGenerated: Scalars['String'];
  submittedDate?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedDate?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  commonStatus: Scalars['String'];
  sales?: Maybe<SaleEntity>;
  allocation?: Maybe<Array<AllocationEntity>>;
  billItemId: Scalars['String'];
  generationBatchDate?: Maybe<Scalars['DateTime']>;
  generationBatch?: Maybe<Scalars['String']>;
  voidDate?: Maybe<Scalars['DateTime']>;
  voidBy?: Maybe<Scalars['String']>;
  allowReversal: Scalars['Boolean'];
};

export type Lead3rdpartyIntegrationEntity = {
  __typename?: 'lead3rdpartyIntegrationEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId?: Maybe<Scalars['String']>;
  integrationType: Scalars['String'];
  title: Scalars['String'];
  commonStatus: Scalars['String'];
  integrationUrl?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  token?: Maybe<Scalars['String']>;
  loginId?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

export type Lead3rdPartyIntegrationPostLogEntity = {
  __typename?: 'lead3rdPartyIntegrationPostLogEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId?: Maybe<Scalars['String']>;
  integrationType: Scalars['String'];
  receiveDate: Scalars['DateTime'];
  body: Scalars['JSONObject'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  isSuccess: Scalars['Boolean'];
  response?: Maybe<Scalars['String']>;
  header: Scalars['JSONObject'];
};

export type LeadActivityDetails = {
  __typename?: 'LeadActivityDetails';
  actiontakenname?: Maybe<Scalars['String']>;
  agencyname?: Maybe<Scalars['String']>;
  lead_id?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  activitytype?: Maybe<Scalars['String']>;
  activitydesc?: Maybe<Scalars['String']>;
  leadStatus: Scalars['String'];
  activitydate: Scalars['DateTime'];
  followupdate?: Maybe<Scalars['DateTime']>;
  followupremark?: Maybe<Scalars['String']>;
  activityAudio?: Maybe<Scalars['String']>;
};

export type LeadActivityEntity = {
  __typename?: 'leadActivityEntity';
  id: Scalars['String'];
  leadId: Scalars['String'];
  activityDesc?: Maybe<Scalars['String']>;
  activityType: Scalars['String'];
  activityDate: Scalars['DateTime'];
  leadStatus: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
  followUpDate?: Maybe<Scalars['DateTime']>;
  followUpRemark?: Maybe<Scalars['String']>;
  accountId: Scalars['String'];
  actionBy?: Maybe<Scalars['String']>;
  fromPersonId?: Maybe<Scalars['String']>;
  fromProject: ProjectEntity;
  fromTeam: SaleTeamEntity;
  lead: LeadEntity;
  personId?: Maybe<Scalars['String']>;
  project: ProjectEntity;
  team: SaleTeamEntity;
  activityAudio?: Maybe<Scalars['String']>;
};

export type LeadAddressEntity = {
  __typename?: 'leadAddressEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  contactId: Scalars['String'];
  address: AddressJson;
  leadContact: LeadContactEntity;
  isPrimary: Scalars['Boolean'];
};

export type LeadAssignmentSettingEntity = {
  __typename?: 'leadAssignmentSettingEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  userId: Scalars['String'];
  weight: Scalars['Float'];
  seq: Scalars['Float'];
  leadCount: Scalars['Float'];
  lastAssign: Scalars['DateTime'];
};

export type LeadBatchCountLeadSourceOutput = {
  __typename?: 'LeadBatchCountLeadSourceOutput';
  id: Scalars['String'];
  top1: Array<LeadBatchCountLeadSourceValuesOutput>;
  top2: Array<LeadBatchCountLeadSourceValuesOutput>;
  top3: Array<LeadBatchCountLeadSourceValuesOutput>;
  others: Array<LeadBatchCountLeadSourceValuesOutput>;
};

export type LeadBatchCountLeadSourceValuesOutput = {
  __typename?: 'LeadBatchCountLeadSourceValuesOutput';
  name: Scalars['String'];
  count: Scalars['Float'];
};

export type LeadBatchEntity = AuditEntity & {
  __typename?: 'leadBatchEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  batchTitle: Scalars['String'];
  recordCount: Scalars['Float'];
  leads: Array<LeadEntity>;
  accountId: Scalars['String'];
  lastAssignedToProject: LeadActivityEntity;
  assignedToProjectLeadCount: Scalars['Float'];
};

export type LeadByBatch = {
  __typename?: 'LeadByBatch';
  id?: Maybe<Scalars['String']>;
  lead_id: Scalars['String'];
  contact_id: Scalars['String'];
  project_id?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  full_name: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  lead_source?: Maybe<Scalars['String']>;
  phone_no: Scalars['String'];
  isRegistered?: Maybe<Scalars['Boolean']>;
  project_count: Scalars['Float'];
};

export type LeadByBatchIds = {
  __typename?: 'LeadByBatchIds';
  id?: Maybe<Scalars['String']>;
  batchTitle?: Maybe<Scalars['String']>;
  createdTs?: Maybe<Scalars['DateTime']>;
  recordCount?: Maybe<Scalars['Float']>;
  isRoundRobinUser?: Maybe<Scalars['Boolean']>;
  leadData?: Maybe<Array<LeadDataOutput>>;
  assignedProjectDetails?: Maybe<Array<AssignedProjectDetails>>;
  total?: Maybe<Scalars['Float']>;
  openTotal?: Maybe<Scalars['Float']>;
  hotTotal?: Maybe<Scalars['Float']>;
  coldTotal?: Maybe<Scalars['Float']>;
  dropTotal?: Maybe<Scalars['Float']>;
  warmTotal?: Maybe<Scalars['Float']>;
};

export type LeadContactEntity = {
  __typename?: 'leadContactEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  fullName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  altEmail?: Maybe<Array<Scalars['String']>>;
  phoneNo: Scalars['String'];
  dob?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  icNo?: Maybe<Scalars['String']>;
  militaryIc?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companyRegNo?: Maybe<Scalars['String']>;
  address?: Maybe<AddressJson>;
  homeTel?: Maybe<Scalars['String']>;
  officeTel?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  distance: Scalars['Float'];
  bookingWaitlists: Array<BookingWaitlistEntity>;
  jointBuyers: Array<JointBuyerEntity>;
  leads?: Maybe<Array<LeadEntity>>;
  sales: Array<SaleEntity>;
  leadJointBuyers: Array<LeadJointBuyerEntity>;
  salutation?: Maybe<SalutationEntity>;
  nationality?: Maybe<SNationalityEntity>;
  race?: Maybe<RaceEntity>;
  maritalStatus?: Maybe<MaritalStatusEntity>;
  incomeRange?: Maybe<IncomeRangeEntity>;
  addressList?: Maybe<Array<LeadAddressEntity>>;
  designation?: Maybe<ParameterEntity>;
  signatureUrl?: Maybe<Scalars['String']>;
  jointBuyerSignature?: Maybe<Scalars['String']>;
  leadAddress?: Maybe<Array<AddressJson>>;
};

export type LeadDataOutput = {
  __typename?: 'LeadDataOutput';
  id?: Maybe<Scalars['String']>;
  lead_status: Scalars['String'];
  lead_source: Scalars['String'];
  event_name?: Maybe<Scalars['String']>;
  sales_person_id?: Maybe<Scalars['String']>;
  sales_person_name?: Maybe<Scalars['String']>;
  existing_purchaser: Scalars['Boolean'];
  activity_date?: Maybe<Scalars['DateTime']>;
  activity_desc?: Maybe<Scalars['String']>;
  follow_up_remark?: Maybe<Scalars['String']>;
  follow_up_date?: Maybe<Scalars['DateTime']>;
  contact_id: Scalars['String'];
  full_name: Scalars['String'];
  lead_id: Scalars['String'];
  email: Scalars['String'];
  phone_no: Scalars['String'];
  isRegistered?: Maybe<Scalars['Boolean']>;
  project_count: Scalars['Float'];
  converted_to_booking: Scalars['Boolean'];
  gender?: Maybe<Scalars['String']>;
  createdTs?: Maybe<Scalars['DateTime']>;
};

export type LeadEntity = {
  __typename?: 'leadEntity';
  id: Scalars['String'];
  leadStatus: Scalars['String'];
  assignStatus?: Maybe<Scalars['String']>;
  accountId: Scalars['String'];
  projectId?: Maybe<Scalars['String']>;
  batchId?: Maybe<Scalars['String']>;
  contactId: Scalars['String'];
  leadSourceId: Scalars['String'];
  eventId?: Maybe<Scalars['String']>;
  incomeRangeId?: Maybe<Scalars['String']>;
  propertyTypeId?: Maybe<Scalars['String']>;
  agencyAcctId?: Maybe<Scalars['String']>;
  preferLocation?: Maybe<Scalars['String']>;
  referralName?: Maybe<Scalars['String']>;
  referralContactNo?: Maybe<Scalars['String']>;
  referralReferenceNo?: Maybe<Scalars['String']>;
  lastLeadActId?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  batch: LeadBatchEntity;
  contact: LeadContactEntity;
  leadSource: LeadSourceEntity;
  project: ProjectEntity;
  salesPersonId?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
  team?: Maybe<SaleTeamEntity>;
  unit: UnitEntity;
  leadActivities: Array<LeadActivityEntity>;
  sales: Array<SaleEntity>;
  waitingList: Array<BookingWaitlistEntity>;
  leadJointBuyers: Array<LeadJointBuyerEntity>;
  designation?: Maybe<ParameterEntity>;
  utm_id?: Maybe<Scalars['String']>;
  utm_source?: Maybe<Scalars['String']>;
  utm_medium?: Maybe<Scalars['String']>;
  utm_campaign?: Maybe<Scalars['String']>;
  utm_term?: Maybe<Scalars['String']>;
  utm_content?: Maybe<Scalars['String']>;
  salesPerson?: Maybe<UserEntity>;
  leadDetailsActivity?: Maybe<Array<LeadActivityDetails>>;
  events?: Maybe<EventsEntity>;
  LeadActivity: Scalars['JSONObject'];
  LeadStatus: Scalars['JSONObject'];
  incomeRange?: Maybe<IncomeRangeEntity>;
  propertyType?: Maybe<PropertyTypeEntity>;
  loanScreening?: Maybe<LoanScreenStatusOutput>;
  leadAddress?: Maybe<Array<AddressJson>>;
};

export type LeadHandleByWhichUserOutput = {
  __typename?: 'LeadHandleByWhichUserOutput';
  id: Scalars['String'];
  salesPersonId?: Maybe<Scalars['String']>;
  assignStatus: Scalars['String'];
  eLaunchId?: Maybe<Scalars['String']>;
};

export type LeadJointBuyerEntity = {
  __typename?: 'leadJointBuyerEntity';
  id: Scalars['String'];
  contactId: Scalars['String'];
  leadId: Scalars['String'];
  contact: LeadContactEntity;
  lead: LeadEntity;
  commonStatus: Scalars['String'];
};

export type LeadSourceEntity = {
  __typename?: 'leadSourceEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  name: Scalars['String'];
  systemUse: Scalars['Boolean'];
  commonStatus: Scalars['String'];
  defaulted: Scalars['Boolean'];
  seqNo?: Maybe<Scalars['Float']>;
  leads: Array<LeadEntity>;
  sales: Array<SaleEntity>;
};

export type LeadStatusCountOutput = {
  __typename?: 'LeadStatusCountOutput';
  projectId?: Maybe<Scalars['String']>;
  waitingCount?: Maybe<Scalars['Float']>;
  openCount?: Maybe<Scalars['Float']>;
  hotCount?: Maybe<Scalars['Float']>;
  coldCount?: Maybe<Scalars['Float']>;
  warmCount?: Maybe<Scalars['Float']>;
  dropCount?: Maybe<Scalars['Float']>;
  bookedCount?: Maybe<Scalars['Float']>;
  reservationCount?: Maybe<Scalars['Float']>;
  signedCount?: Maybe<Scalars['Float']>;
  totalCount?: Maybe<Scalars['Float']>;
  totalConvertedAmount?: Maybe<Scalars['Float']>;
};

export type LeadTemplateReturnOutput = {
  __typename?: 'LeadTemplateReturnOutput';
  message?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  leadIds?: Maybe<Array<Scalars['String']>>;
  file?: Maybe<Scalars['String']>;
};

export type LedgerEntity = {
  __typename?: 'ledgerEntity';
  id: Scalars['String'];
  documentNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docAmount: Scalars['String'];
  referenceNo?: Maybe<Scalars['String']>;
  trxDate: Scalars['DateTime'];
  taxAmount?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  commonStatus: Scalars['String'];
  mode: Scalars['String'];
  trxStatus: Scalars['String'];
  voidDate?: Maybe<Scalars['DateTime']>;
  voidReason?: Maybe<Scalars['String']>;
  postedDate?: Maybe<Scalars['DateTime']>;
  paymentMethod?: Maybe<Scalars['String']>;
  paymentInclude?: Maybe<Scalars['Boolean']>;
  interestInvoice: Array<InterestEntity>;
  interestLegder: Array<InterestEntity>;
  account_id: Scalars['String'];
  adjustmentType?: Maybe<Scalars['String']>;
  debtorClass?: Maybe<Scalars['String']>;
  trxClass?: Maybe<Scalars['String']>;
  invoice: LedgerEntity;
  ledgers: Array<LedgerEntity>;
  project: ProjectEntity;
  sale: SaleEntity;
  schedule: ScheduleEntity;
  tax_id: Scalars['String'];
  reminders: Array<ReminderEntity>;
};

export type LetterSignDetailsEntity = {
  __typename?: 'letterSignDetailsEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  saleId: Scalars['String'];
  letterTemplateId: Scalars['String'];
  commonStatus: Scalars['String'];
};

export type LetterSignEntity = AuditEntity & {
  __typename?: 'letterSignEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  contactId: Scalars['String'];
  sale: SaleEntity;
};

export type LetterTemplateEntity = AuditEntity & {
  __typename?: 'letterTemplateEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  standardLetterId: Scalars['String'];
  name: Scalars['String'];
  templateBody?: Maybe<Scalars['String']>;
  jointBuyerSignRequired: Scalars['Boolean'];
  commonStatus: Scalars['String'];
  standardLetter: StandardLetterEntity;
  projectLetter: Array<ProjectLetterEntity>;
  standardLetterDataSourceVersion?: Maybe<Scalars['Float']>;
  isReportDesignEditor: Scalars['Boolean'];
};

export type LhdnEinvoiceSettingsEntity = {
  __typename?: 'lhdnEinvoiceSettingsEntity';
  eInvClientId: Scalars['String'];
  eInvClientSecret?: Maybe<Scalars['String']>;
  eInvClientSecret2?: Maybe<Scalars['String']>;
  eInvAccessToken?: Maybe<Scalars['String']>;
  eInvApiUrl?: Maybe<Scalars['String']>;
};

export type ListOfSalesStaffByTeam = {
  __typename?: 'ListOfSalesStaffByTeam';
  teamId: Scalars['String'];
  teamName: Scalars['String'];
  userId: Scalars['String'];
  userName: Scalars['String'];
};

export type LoanAlertAgingInput = {
  isActive: Scalars['Boolean'];
  maxMinorLoanAlertAging: Scalars['Float'];
  maxMajorLoanAlertAging: Scalars['Float'];
};

export type LoanAlertAgingOutput = {
  __typename?: 'LoanAlertAgingOutput';
  isActive: Scalars['Boolean'];
  maxMinorLoanAlertAging: Scalars['Float'];
  maxMajorLoanAlertAging: Scalars['Float'];
};

export type LoanAssignedCountOutput = {
  __typename?: 'LoanAssignedCountOutput';
  total_count: Scalars['Float'];
  active_count: Scalars['Float'];
  withdrawn_count: Scalars['Float'];
};

export type LoanAssignmentInput = {
  salesUserReceive?: Maybe<Scalars['Boolean']>;
  agencyUserReceive?: Maybe<Scalars['Boolean']>;
  endFinUserReceive?: Maybe<Scalars['Boolean']>;
};

export type LoanAssignmentOutput = {
  __typename?: 'loanAssignmentOutput';
  salesUserReceive?: Maybe<Scalars['Boolean']>;
  agencyUserReceive?: Maybe<Scalars['Boolean']>;
  endFinUserReceive?: Maybe<Scalars['Boolean']>;
};

export type LoanLeadNotificationInput = {
  leadAssignment?: Maybe<Scalars['Boolean']>;
};

export type LoanLeadNotificationOutput = {
  __typename?: 'LoanLeadNotificationOutput';
  leadAssignment?: Maybe<Scalars['Boolean']>;
};

export type LoanProjectCountOutput = {
  __typename?: 'LoanProjectCountOutput';
  project_id: Scalars['String'];
  project_name: Scalars['String'];
  total_count: Scalars['Float'];
  active_count: Scalars['Float'];
  withdrawn_count: Scalars['Float'];
};

export type LoanScreenEntity = {
  __typename?: 'loanScreenEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  serviceProvider: Scalars['String'];
  caseCode?: Maybe<Scalars['String']>;
  refCode?: Maybe<Scalars['String']>;
  reportType: Scalars['String'];
  payload: Scalars['JSONObject'];
  reportUrl?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  refTable: Scalars['String'];
  refId: Scalars['String'];
  project: ProjectEntity;
};

export type LoanScreenReportOutput = {
  __typename?: 'LoanScreenReportOutput';
  refType: Scalars['String'];
  mediaType: Scalars['String'];
  resourceUrl: Scalars['String'];
  fileName: Scalars['String'];
};

export type LoanScreenStatusOutput = {
  __typename?: 'LoanScreenStatusOutput';
  caseCode: Scalars['String'];
  status: Scalars['String'];
  CCRIS?: Maybe<Scalars['String']>;
  CTOS?: Maybe<Scalars['String']>;
  createdDate: Scalars['DateTime'];
  modifiedDate: Scalars['DateTime'];
};

export type LoanStatusOutput = {
  __typename?: 'LoanStatusOutput';
  count: Scalars['Float'];
  pendingConsentCount: Scalars['Float'];
  acceptedCount: Scalars['Float'];
  approvedCount: Scalars['Float'];
  signedCount: Scalars['Float'];
  rejectedCount: Scalars['Float'];
  withdrawnCount: Scalars['Float'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String'];
  user: UserResponse;
};

export type MaritalStatusEntity = {
  __typename?: 'maritalStatusEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  name: Scalars['String'];
  commonStatus: Scalars['String'];
  systemUse: Scalars['Boolean'];
  defaulted: Scalars['Boolean'];
  seqNo?: Maybe<Scalars['Float']>;
  contacts: Array<ContactEntity>;
};

export type MasterDataOutput = {
  __typename?: 'MasterDataOutput';
  Genders: Scalars['JSONObject'];
  LandType: Scalars['JSONObject'];
  UOM: Scalars['JSONObject'];
  ReasonCode: Array<ReasonCodeEntity>;
  Region: Array<RegionEntity>;
  Races: Array<RaceEntity>;
  Salutations: Array<SalutationEntity>;
  CurrencyCode: Array<Array<Scalars['String']>>;
  Nationalities: Array<SNationalityEntity>;
  AddressType: Scalars['JSONObject'];
  Countries: Array<CountryMasterDataOutput>;
  City: Array<SCityEntity>;
  MaritalStatus: Array<MaritalStatusEntity>;
  Designations: Array<ParameterEntity>;
  IncomeRanges: Array<IncomeRangeEntity>;
  PaymentMethods: Scalars['JSONObject'];
  PaymentStatus: Scalars['JSONObject'];
  DiscountType: Array<DiscountTypeEntity>;
  DiscountPrefix: Scalars['JSONObject'];
  Language: Scalars['JSONObject'];
  CommercialPurposes: Scalars['JSONObject'];
  PayMethod: Scalars['JSONObject'];
  Tenures: Scalars['JSONObject'];
  IntegrationTypes: Scalars['JSONObject'];
  ProjectTypes: Array<ProjectTypeEntity>;
  PropertyTypes: Array<PropertyTypeEntity>;
  UnitTypes: Array<UnitTypeEntity>;
  UnitLayoutbyProject: Array<UnitLayoutEntity>;
  LeadSources: Array<LeadSourceEntity>;
  UserPermission: Array<Scalars['Float']>;
  Events: Array<EventsEntity>;
  LoanType: Scalars['JSONObject'];
  PurchaserAppSetting: Scalars['JSONObject'];
  ELettering: Scalars['JSONObject'];
};


export type MasterDataOutputPaymentMethodsArgs = {
  saleId?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
};


export type MasterDataOutputPaymentStatusArgs = {
  saleId?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
};


export type MasterDataOutputUnitLayoutbyProjectArgs = {
  project_id: Scalars['String'];
};


export type MasterDataOutputUserPermissionArgs = {
  projectId: Scalars['String'];
};

export type MediaSource = {
  __typename?: 'MediaSource';
  url: Scalars['String'];
  caption?: Maybe<Scalars['String']>;
  videoType?: Maybe<Scalars['String']>;
};

export type MediaSourceInput = {
  url: Scalars['String'];
  caption?: Maybe<Scalars['String']>;
  videoType?: Maybe<Scalars['String']>;
};

export type MicrositeNewLeadOutput = {
  __typename?: 'MicrositeNewLeadOutput';
  projectId?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
  salesPersonId?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
  leadSourceId?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  email: Scalars['String'];
  phoneNo: Scalars['String'];
  dob?: Maybe<Scalars['String']>;
  icNo?: Maybe<Scalars['String']>;
  passportNo?: Maybe<Scalars['String']>;
  militaryIc?: Maybe<Scalars['String']>;
  homeTel?: Maybe<Scalars['String']>;
  officeTel?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  race?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  maritalStatus?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  propertyType?: Maybe<Scalars['String']>;
  preferLocation?: Maybe<Scalars['String']>;
  incomeRange?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  utmId?: Maybe<Scalars['String']>;
  utmSource?: Maybe<Scalars['String']>;
  utmMedium?: Maybe<Scalars['String']>;
  utmCampaign?: Maybe<Scalars['String']>;
  utmTerm?: Maybe<Scalars['String']>;
  utmContent?: Maybe<Scalars['String']>;
};

export type MiscBillingEntity = {
  __typename?: 'miscBillingEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  saleId: Scalars['String'];
  documentNo?: Maybe<Scalars['String']>;
  documentRef?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  documentDate: Scalars['DateTime'];
  documentAmount: Scalars['Float'];
  dueDate: Scalars['DateTime'];
  creditTerm: Scalars['Float'];
  isIncludeHoliday: Scalars['Boolean'];
  requestedBy?: Maybe<Scalars['String']>;
  interestRate?: Maybe<Scalars['Float']>;
  generationBatchDate?: Maybe<Scalars['DateTime']>;
  generationBatch?: Maybe<Scalars['String']>;
  glGenerationBatch?: Maybe<Scalars['String']>;
  glGenerated?: Maybe<Scalars['Boolean']>;
  submittedBy?: Maybe<Scalars['String']>;
  submittedDate?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  approvedDate?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
  commonStatus: Scalars['String'];
  voidDate?: Maybe<Scalars['DateTime']>;
  voidBy?: Maybe<Scalars['String']>;
  sales?: Maybe<SaleEntity>;
  billItemId: Scalars['String'];
  allowReversal: Scalars['Boolean'];
};

export type MotTrackingEntity = {
  __typename?: 'motTrackingEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  saleId: Scalars['String'];
  titleExecutionDate: Scalars['DateTime'];
  titleNotificationDate: Scalars['DateTime'];
  titleForward: Scalars['DateTime'];
  motReceiptNo: Scalars['String'];
  motTransferDate: Scalars['DateTime'];
  motSubmissionNo?: Maybe<Scalars['String']>;
  motSubmissionDate: Scalars['DateTime'];
  motMortgageNo: Scalars['String'];
  motMortgageDate: Scalars['DateTime'];
  instructionToSolicitor: Scalars['DateTime'];
  motIn: Scalars['DateTime'];
  motOut: Scalars['DateTime'];
  receiveMotCancellation: Scalars['DateTime'];
  forwardMotDirector: Scalars['DateTime'];
  receiveMotDirector: Scalars['DateTime'];
  forwardMotComSec: Scalars['DateTime'];
  receiveMotComSec: Scalars['DateTime'];
  receiveLawyerAcknowledgement: Scalars['DateTime'];
  requestForTitle: Scalars['DateTime'];
  releaseForTitle: Scalars['DateTime'];
  receivedTitleDutyTransferred: Scalars['DateTime'];
  confirmationPresentationReceipt: Scalars['DateTime'];
  receivedFrom?: Maybe<Scalars['String']>;
  stampDutyPaymentDate: Scalars['DateTime'];
  stampDutyPayment: Scalars['Float'];
  remark?: Maybe<Scalars['String']>;
  motSolicitorId: Scalars['String'];
  motSolicitorReferenceNo?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  sales?: Maybe<SaleEntity>;
  units?: Maybe<UnitEntity>;
};

export type MultipleEsignProjectLetterListingOutput = {
  __typename?: 'MultipleEsignProjectLetterListingOutput';
  letterTypeName?: Maybe<Scalars['String']>;
  letter?: Maybe<Array<ESignProjectLetterListingOutput>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  forgotPassword: Scalars['Boolean'];
  registerUser: ELaunchUserEntity;
  updateProfile: Scalars['Boolean'];
  changePassword: Scalars['Boolean'];
  newPassword: Scalars['Boolean'];
  newPasswordCheck: Scalars['Boolean'];
  EditPurchaserInfo: Scalars['Boolean'];
  AddJointBuyer: Scalars['Boolean'];
  EditJointBuyer: Scalars['Boolean'];
  deactivateJointBuyer: Scalars['Boolean'];
  saveBookingExpress: SaveBookingOutput;
  addBookingAttachments: Array<AttachmentEntity>;
  uploadNricPassport: Array<AttachmentEntity>;
  deleteBookingAttachment: Scalars['Boolean'];
  downloadAttachment: AttachmentOutput;
  updateStatusToInProgressSale: Scalars['Boolean'];
  addToFavourite: Scalars['Boolean'];
  removeFavourite: Scalars['Boolean'];
};


export type MutationForgotPasswordArgs = {
  input: ForgetPasswordInput;
};


export type MutationRegisterUserArgs = {
  input: RegisterUserInput;
};


export type MutationUpdateProfileArgs = {
  input: UserContactInput;
};


export type MutationChangePasswordArgs = {
  input: UserChangePasswordInput;
};


export type MutationNewPasswordArgs = {
  confirmPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationEditPurchaserInfoArgs = {
  input: EditPurchaserInfoInput;
};


export type MutationAddJointBuyerArgs = {
  input: AddELaunchBookingJointBuyerInput;
};


export type MutationEditJointBuyerArgs = {
  input: EditELaunchBookingJointBuyerInput;
};


export type MutationDeactivateJointBuyerArgs = {
  jointBuyerId: Scalars['String'];
};


export type MutationSaveBookingExpressArgs = {
  input: ELaunchAddBookingExpressInput;
};


export type MutationAddBookingAttachmentsArgs = {
  input: ELaunchAddAttachmentsInput;
};


export type MutationUploadNricPassportArgs = {
  input: UploadNricPassportInput;
};


export type MutationDeleteBookingAttachmentArgs = {
  attachmentId: Scalars['String'];
};


export type MutationDownloadAttachmentArgs = {
  input: DownloadAttachmentInput;
};


export type MutationUpdateStatusToInProgressSaleArgs = {
  sale_id: Scalars['String'];
};


export type MutationAddToFavouriteArgs = {
  unitId: Scalars['String'];
};


export type MutationRemoveFavouriteArgs = {
  unitId: Scalars['String'];
};

export type NewsAnnouncementEntity = AuditEntity & {
  __typename?: 'newsAnnouncementEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  isPublish: Scalars['Boolean'];
  commonStatus: Scalars['String'];
  newsProjects: Array<NewsProjectEntity>;
  attachment?: Maybe<Array<AttachmentEntity>>;
  isExpired?: Maybe<Scalars['Boolean']>;
};


export type NewsAnnouncementEntityAttachmentArgs = {
  limit?: Maybe<Scalars['Float']>;
};

export type NewsProjectEntity = AuditEntity & {
  __typename?: 'newsProjectEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  newsId: Scalars['String'];
  projectId: Scalars['String'];
  commonStatus: Scalars['String'];
  newsAnnouncement: NewsAnnouncementEntity;
};

export type NumberFormatEntity = {
  __typename?: 'numberFormatEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  numberFormat: Scalars['String'];
  numberType: Scalars['String'];
  commonStatus: Scalars['String'];
  companyNumberSetups: Array<CompanyNumberSetupEntity>;
};

/** JsonType */
export type OldSaleDetail = {
  __typename?: 'oldSaleDetail';
  discounts?: Maybe<Array<DiscountListingObject>>;
};

export type OldSaleDetailInput = {
  discounts?: Maybe<Array<DiscountListingUnitTransferInput>>;
};

export type OnlineUserEntity = AuditEntity & {
  __typename?: 'OnlineUserEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  commonStatus: Scalars['String'];
  userId: Scalars['String'];
  softwareCode?: Maybe<Scalars['String']>;
  accountId: Scalars['String'];
  accountName?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  lastLogin: Scalars['DateTime'];
  lastLogout?: Maybe<Scalars['DateTime']>;
  socketId?: Maybe<Scalars['String']>;
  softwarePackage?: Maybe<Scalars['String']>;
};

export type OpenLeadAssignmentSettingEntity = {
  __typename?: 'openLeadAssignmentSettingEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  userId: Scalars['String'];
  weight: Scalars['Float'];
  seq: Scalars['Float'];
  leadCount: Scalars['Float'];
  lastAssign: Scalars['DateTime'];
};

export type OpenLeadSummary = {
  __typename?: 'OpenLeadSummary';
  lastImportedDate?: Maybe<Scalars['DateTime']>;
  lastImportedLeadCount?: Maybe<Scalars['Float']>;
  lastAssignedLeadDate?: Maybe<Scalars['String']>;
  lastAssignedLeadCount?: Maybe<Scalars['Float']>;
  totalBatchImported?: Maybe<Scalars['Float']>;
};

export type PackageEntity = {
  __typename?: 'packageEntity';
  id: Scalars['String'];
  name: Scalars['String'];
  desc?: Maybe<Scalars['String']>;
  projectId: Scalars['String'];
  listPrice: Scalars['Float'];
  marketValue: Scalars['Float'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  isIntegrated: Scalars['Boolean'];
  commonStatus: Scalars['String'];
  project: ProjectEntity;
  salesPackage: Array<SaleEntity>;
  integrationPackage: Array<IntegrationPackageEntity>;
  packageExt?: Maybe<PackageExtEntity>;
  salesContractPackage?: Maybe<SalePackageEntity>;
  projectAgencyPackage: ProjectAgencyPackageEntity;
  isDeletable: Scalars['Boolean'];
  unitCount: Scalars['Float'];
};

export type PackageExtEntity = {
  __typename?: 'packageExtEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  packageId: Scalars['String'];
  taxSchemeId: Scalars['String'];
  defaulted: Scalars['Boolean'];
  commonStatus: Scalars['String'];
  package: PackageEntity;
};

export type PanelListDto = {
  __typename?: 'PanelListDto';
  financier_name: Scalars['String'];
  office_tel?: Maybe<Scalars['Float']>;
  bank_name: Scalars['String'];
  logo_url: Scalars['String'];
  total_staff: Scalars['Float'];
  finproject_id: Scalars['String'];
};

export type ParamaterValueOutput = {
  __typename?: 'ParamaterValueOutput';
  project: ProjectEntity;
  mainBuyerDetails?: Maybe<ContactEntity>;
  contactExt?: Maybe<ContactExtensionEntity>;
  sale?: Maybe<SaleEntity>;
  spouse?: Maybe<ContactEntity>;
  discount?: Maybe<Array<DiscountParamaterValueOutput>>;
  salePackage?: Maybe<Array<PackageEntity>>;
  developerSolicitor?: Maybe<SolicitorAppointedEntity>;
  buyerSolicitor?: Maybe<SolicitorAppointedEntity>;
  unit: UnitEntity;
  company: CompanyEntity;
  jointBuyerDetails?: Maybe<Array<ContactEntity>>;
  jointBuyerSignRequired: Scalars['Boolean'];
  payment?: Maybe<SalePaymentValueOutput>;
  jointBuyers?: Maybe<Array<JointBuyerEntity>>;
  address?: Maybe<AddressJson>;
  holdingCompany: CompanyEntity;
  developmentCompany: CompanyEntity;
  lead?: Maybe<LeadEntity>;
  saleRebate?: Maybe<Array<SaleRebateEntity>>;
};

export type ParameterEntity = {
  __typename?: 'parameterEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  parameterType: Scalars['String'];
  name: Scalars['String'];
  seqNo?: Maybe<Scalars['Float']>;
  defaulted: Scalars['Boolean'];
  commonStatus: Scalars['String'];
  saleContact: SaleContactEntity;
  sale: SaleEntity;
  contacts: ContactEntity;
  lead: LeadEntity;
  leadContact: LeadContactEntity;
};

export type PassswordErrorMsgOuput = {
  __typename?: 'PassswordErrorMsgOuput';
  RequiredLength: Scalars['String'];
  RequiredUniqueChars: Scalars['String'];
  RequireNonAlphanumeric: Scalars['String'];
  RequireLowercase: Scalars['String'];
  RequireUppercase: Scalars['String'];
  RequireDigit: Scalars['String'];
};

export type PassswordPolicyOuput = {
  __typename?: 'PassswordPolicyOuput';
  RequiredLength: Scalars['Float'];
  RequiredUniqueChars: Scalars['Float'];
  RequireNonAlphanumeric: Scalars['Boolean'];
  RequireLowercase: Scalars['Boolean'];
  RequireUppercase: Scalars['Boolean'];
  RequireDigit: Scalars['Boolean'];
  ErrorMsg: PassswordErrorMsgOuput;
};

export type PasswordPolicyEntity = {
  __typename?: 'passwordPolicyEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  requiredMinNoCharacter?: Maybe<Scalars['Float']>;
  requiredUpperLowerCase: Scalars['Boolean'];
  requiredDigit: Scalars['Boolean'];
  requiredSpecialCharacter: Scalars['Boolean'];
  passwordResetByDay?: Maybe<Scalars['Float']>;
  notAllowReusePasswordForLast?: Maybe<Scalars['Float']>;
  commonStatus: Scalars['String'];
};

export type PAuditEntity = {
  created_ref_table: Scalars['String'];
  modified_ref_table: Scalars['String'];
  created_by: Scalars['String'];
  created_date: Scalars['DateTime'];
  modified_by: Scalars['String'];
  modified_date: Scalars['DateTime'];
};

export type PaymentGatewayEntity = {
  __typename?: 'paymentGatewayEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  gatewayType: Scalars['String'];
  title: Scalars['String'];
  gatewayInfo: Scalars['JSONObject'];
  commonStatus: Scalars['String'];
  projectSpecs: Array<ProjectSpecEntity>;
};

export type PaymentPlanDetailEntity = {
  __typename?: 'paymentPlanDetailEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  paymentPlanId: Scalars['String'];
  title: Scalars['String'];
  stageSeqNo: Scalars['Float'];
  isDownpayment: Scalars['Boolean'];
  isWithInterest: Scalars['Boolean'];
  workProgressId: Scalars['String'];
  amountType: Scalars['String'];
  amountValue: Scalars['Float'];
  numberOfPayment?: Maybe<Scalars['Float']>;
  stakeholderDuration?: Maybe<Scalars['Float']>;
  isStakeholder: Scalars['Boolean'];
  isVpStage: Scalars['Boolean'];
  installmentBillingDay?: Maybe<Scalars['Float']>;
  commonStatus: Scalars['String'];
  includeInDetailId?: Maybe<Array<IncludeInDetail>>;
  workProgress?: Maybe<WorkProgressEntity>;
  architectCertWorkProgress?: Maybe<ArchitectCertWorkProgressEntity>;
  payment: PaymentPlanEntity;
  progressBilling?: Maybe<Array<ProgressBillingEntity>>;
};

export type PaymentPlanEntity = {
  __typename?: 'paymentPlanEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  name: Scalars['String'];
  payMethod?: Maybe<Scalars['String']>;
  effectiveDate: Scalars['DateTime'];
  commonStatus: Scalars['String'];
  details?: Maybe<Array<PaymentPlanDetailEntity>>;
  projectPaymentPlan?: Maybe<Array<ProjectPaymentPlanEntity>>;
  unitExt?: Maybe<UnitExtensionEntity>;
  saleExt?: Maybe<SaleExtensionEntity>;
};

export type PaymentReminderEntity = {
  __typename?: 'paymentReminderEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  reminderDate: Scalars['DateTime'];
  saleId: Scalars['String'];
  refTable: Scalars['String'];
  refId: Scalars['String'];
  reminderSeq: Scalars['Float'];
  balanceAmount: Scalars['Float'];
  generationBatch?: Maybe<Scalars['String']>;
  share?: Maybe<Array<ShareObject>>;
  commonStatus: Scalars['String'];
};

export type PdpaDetailByProject = {
  __typename?: 'PDPADetailByProject';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  templateBody?: Maybe<Scalars['String']>;
  standardLetterId?: Maybe<Scalars['String']>;
  letterType?: Maybe<Scalars['String']>;
};

export type PermissionDetail = {
  __typename?: 'PermissionDetail';
  id: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  permissionType?: Maybe<PermissionType>;
  permissionId?: Maybe<Scalars['Float']>;
  child?: Maybe<Array<PermissionDetail>>;
};

export type PermissionRoleAssignmentEntity = AuditEntity & {
  __typename?: 'permissionRoleAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  roleId: Scalars['String'];
  permissionId: Scalars['Float'];
};

export enum PermissionType {
  SalesAdmin = 'SALES_ADMIN',
  Report = 'REPORT'
}

export type PictureBlockEntity = {
  __typename?: 'pictureBlockEntity';
  id: Scalars['String'];
  block: Scalars['String'];
  seqNo: Scalars['Float'];
  project: ProjectEntity;
  resource: ResourceEntity;
};

export type PictureFloorEntity = {
  __typename?: 'pictureFloorEntity';
  id: Scalars['String'];
  block: Scalars['String'];
  floor: Scalars['Float'];
  seqNo: Scalars['Float'];
  project: ProjectEntity;
  resource: ResourceEntity;
};

export type PictureGalleryEntity = {
  __typename?: 'pictureGalleryEntity';
  id: Scalars['String'];
  seqNo: Scalars['Float'];
  project: ProjectEntity;
  resource: ResourceEntity;
};

export type PictureUnitlayoutEntity = {
  __typename?: 'pictureUnitlayoutEntity';
  id: Scalars['String'];
  seqNo: Scalars['Float'];
  virtualModelLink: Scalars['String'];
  block: Scalars['String'];
  unitType?: Maybe<Scalars['String']>;
  projectId: Scalars['String'];
  resource: ResourceEntity;
  unitLayout: UnitLayoutEntity;
  noOfBedroom: Scalars['Float'];
  noOfBathroom: Scalars['Float'];
  project: ProjectEntity;
};

export type PlatformUserAssignedAccountEntity = {
  __typename?: 'PlatformUserAssignedAccountEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  platformUserId: Scalars['String'];
  commonStatus: CommonStatus;
};

export type PlatformUserEntity = {
  __typename?: 'PlatformUserEntity';
  ID: Scalars['String'];
  username: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  name: Scalars['String'];
  commonStatus: CommonStatus;
  role: PlatformUserRole;
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  isActivated: Scalars['Boolean'];
};

export enum PlatformUserRole {
  Admin = 'ADMIN',
  Superadmin = 'SUPERADMIN',
  Staff = 'STAFF'
}

export type PostCodeMasterDataOutput = {
  __typename?: 'PostCodeMasterDataOutput';
  ID: Scalars['String'];
  postcode: Scalars['String'];
  cityID: Scalars['String'];
  commonStatus: Scalars['String'];
};

export type PostLogEntity = {
  __typename?: 'postLogEntity';
  id: Scalars['String'];
  body: Scalars['JSONObject'];
  postDate: Scalars['DateTime'];
  response?: Maybe<Scalars['String']>;
  unitId?: Maybe<Scalars['Float']>;
  modifiedDate: Scalars['DateTime'];
  isSuccess: Scalars['Boolean'];
  retryCount?: Maybe<Scalars['Float']>;
  endpoint?: Maybe<Scalars['String']>;
};

export type PriceAdjustmentEntity = {
  __typename?: 'priceAdjustmentEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  name: Scalars['String'];
  roundingMode: Scalars['String'];
  roundingPrecision: Scalars['String'];
  reason: Scalars['String'];
  computationMode: Scalars['String'];
  computationValue: Scalars['Float'];
  remark?: Maybe<Scalars['String']>;
  submittedBy?: Maybe<Scalars['String']>;
  submittedDate?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  approvedDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  priceAdjustmentUnit?: Maybe<Array<PriceAdjustmentUnitEntity>>;
};

export type PriceAdjustmentUnitEntity = {
  __typename?: 'priceAdjustmentUnitEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  commonStatus: Scalars['String'];
  priceAdjustmentId: Scalars['String'];
  unitId: Scalars['String'];
  unitListPrice: Scalars['Float'];
  newUnitListPrice: Scalars['Float'];
  priceAdjustments?: Maybe<PriceAdjustmentEntity>;
  units?: Maybe<UnitEntity>;
};

export type ProgressBillingEntity = {
  __typename?: 'progressBillingEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  billItemId: Scalars['String'];
  projectId: Scalars['String'];
  saleId: Scalars['String'];
  paymentPlanDetailId: Scalars['String'];
  isStakeholder: Scalars['Boolean'];
  documentNo?: Maybe<Scalars['String']>;
  documentAmount?: Maybe<Scalars['Float']>;
  documentRef?: Maybe<Scalars['String']>;
  principalAmt?: Maybe<Scalars['Float']>;
  description: Scalars['String'];
  documentDate?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  creditTerm: Scalars['Float'];
  isIncludeHoliday: Scalars['Boolean'];
  isDownpayment: Scalars['Boolean'];
  isInstallment: Scalars['Boolean'];
  isVpStage: Scalars['Boolean'];
  interestRate?: Maybe<Scalars['Float']>;
  chargePercent: Scalars['Float'];
  stageSeqNo: Scalars['Float'];
  installmentSeqNo?: Maybe<Scalars['Float']>;
  generationBatchCreditTerm?: Maybe<Scalars['Float']>;
  generationBatch?: Maybe<Scalars['String']>;
  glGenerationBatch?: Maybe<Scalars['String']>;
  glGenerated: Scalars['String'];
  addOnRefTable?: Maybe<Scalars['String']>;
  addOnRefId?: Maybe<Scalars['String']>;
  voidDate?: Maybe<Scalars['DateTime']>;
  voidBy?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  paymentPlanDetail?: Maybe<PaymentPlanDetailEntity>;
  sales?: Maybe<SaleEntity>;
  subproduct?: Maybe<SaleSubproductEntity>;
  stakeholderProcess?: Maybe<StakeholderProcessEntity>;
  taxLedger?: Maybe<Array<TaxLedgerEntity>>;
  financialBilling?: Maybe<Array<FinancialBillingEntity>>;
  allowReversal: Scalars['Boolean'];
};

export type ProgressLogDetails = {
  __typename?: 'ProgressLogDetails';
  spastatus?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sale_id?: Maybe<Scalars['String']>;
  progresstype?: Maybe<Scalars['String']>;
  progressdesc?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  progressdate?: Maybe<Scalars['DateTime']>;
};

export type ProjectAgencyDiscountEntity = {
  __typename?: 'projectAgencyDiscountEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  agencyId: Scalars['String'];
  discountId: Scalars['String'];
  projectId: Scalars['String'];
  discount: DiscountEntity;
  project: ProjectEntity;
  agency: AgencyAppointedEntity;
  commonStatus: Scalars['String'];
};

export type ProjectAgencyPackageEntity = {
  __typename?: 'projectAgencyPackageEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  agencyId: Scalars['String'];
  packageId: Scalars['String'];
  projectPackage: PackageEntity;
  agency: AgencyAppointedEntity;
  commonStatus: Scalars['String'];
};

export type ProjectAgencyRebateEntity = {
  __typename?: 'projectAgencyRebateEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  agencyId: Scalars['String'];
  discountId: Scalars['String'];
  projectId: Scalars['String'];
  discount: DiscountEntity;
  project: ProjectEntity;
  agency: AgencyAppointedEntity;
  commonStatus: Scalars['String'];
};

export type ProjectAreaVarianceEntity = {
  __typename?: 'projectAreaVarianceEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  name: Scalars['String'];
  effectiveDate: Scalars['DateTime'];
  formula?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
};

export type ProjectBlockMediaOuput = {
  __typename?: 'ProjectBlockMediaOuput';
  resourceid?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  block: Scalars['String'];
  pictureid: Scalars['String'];
};

export type ProjectBookingDetailOutput = {
  __typename?: 'ProjectBookingDetailOutput';
  sale: Scalars['Float'];
  buyer: Scalars['Float'];
  unit: Scalars['Float'];
  joinBuyer: Scalars['Float'];
  bookingFeeAttachments: Scalars['Float'];
  otherAttachments: Scalars['Float'];
};

export type ProjectConstructionEntity = {
  __typename?: 'projectConstructionEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  architectureStructure?: Maybe<Scalars['String']>;
  numberBlockUnit?: Maybe<Scalars['Float']>;
  landProprietor?: Maybe<Scalars['String']>;
  planner?: Maybe<Scalars['String']>;
  buildingDesigner?: Maybe<Scalars['String']>;
  superStructureType?: Maybe<Scalars['String']>;
  propertyAgent?: Maybe<Scalars['String']>;
  architect?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  completionDate?: Maybe<Scalars['DateTime']>;
  landPermitDate?: Maybe<Scalars['DateTime']>;
  taxRegNo?: Maybe<Scalars['String']>;
  spaDuration?: Maybe<Scalars['Float']>;
  basePrice?: Maybe<Scalars['Float']>;
  commonStatus: Scalars['String'];
};

export type ProjectDiscountEntity = {
  __typename?: 'projectDiscountEntity';
  id: Scalars['String'];
  effectiveDate: Scalars['DateTime'];
  expired_date: Scalars['DateTime'];
  projectId: Scalars['String'];
  discountId: Scalars['String'];
  project: ProjectEntity;
  defaulted: Scalars['Boolean'];
  discount: DiscountEntity;
  commonStatus: Scalars['String'];
};

export type ProjectEmailLetterEntity = AuditEntity & {
  __typename?: 'projectEmailLetterEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  commonStatus: Scalars['String'];
  emailLetterTemplateId: Scalars['String'];
  projectId: Scalars['String'];
  subject: Scalars['String'];
  templateBody: Scalars['String'];
  project: ProjectEntity;
  emailLetterTemplate: EmailLetterTemplateEntity;
};

export type ProjectEntity = {
  __typename?: 'projectEntity';
  id: Scalars['String'];
  name: Scalars['String'];
  townshipName?: Maybe<Scalars['String']>;
  desc?: Maybe<Scalars['String']>;
  completionDate?: Maybe<Scalars['DateTime']>;
  launchDate?: Maybe<Scalars['DateTime']>;
  projectAddress?: Maybe<Scalars['String']>;
  saleGalleryAddress?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  projectGps?: Maybe<Scalars['String']>;
  saleGalleryGps?: Maybe<Scalars['String']>;
  minPrice?: Maybe<Scalars['Float']>;
  maxPrice?: Maybe<Scalars['Float']>;
  amenities?: Maybe<Array<Amenities>>;
  tenure: Scalars['String'];
  landType: Scalars['String'];
  uom: Scalars['String'];
  isIntegrated: Scalars['Boolean'];
  commonStatus: Scalars['String'];
  projectCode: Scalars['String'];
  country: Scalars['String'];
  currency: Scalars['String'];
  vpDate?: Maybe<Scalars['DateTime']>;
  videoLink?: Maybe<Array<MediaSource>>;
  virtualModelLink?: Maybe<Array<MediaSource>>;
  allocations: Array<AllocationEntity>;
  bookingWaitlists: Array<BookingWaitlistEntity>;
  endfinProjectFinanciers: Array<EndfinProjectFinancierEntity>;
  interests: Array<InterestEntity>;
  leads: Array<LeadEntity>;
  leadActivitiesFromProject: Array<LeadActivityEntity>;
  saleTeamAllocation: Array<SaleTeamAllocationEntity>;
  leadActivitiesProject: Array<LeadActivityEntity>;
  ledgers: Array<LedgerEntity>;
  pictureBlocks: Array<PictureBlockEntity>;
  pictureFloors: Array<PictureFloorEntity>;
  pictureGalleries: Array<PictureGalleryEntity>;
  accountId: Scalars['String'];
  developmentCompanyId: Scalars['String'];
  holdingCompanyId: Scalars['String'];
  developmentCompany: CompanyEntity;
  holdingCompany: CompanyEntity;
  projectType: ProjectTypeEntity;
  projectSpecs: ProjectSpecEntity;
  reminders: Array<ReminderEntity>;
  sales: Array<SaleEntity>;
  salesCharts: Array<SalesChartEntity>;
  schedules: Array<ScheduleEntity>;
  units: Array<UnitEntity>;
  unitLayouts: Array<UnitLayoutEntity>;
  packages: Array<PackageEntity>;
  integrationDiscount: Array<IntegrationDiscountEntity>;
  discounts: Array<ProjectDiscountEntity>;
  agencyContract: Array<AgencyContractEntity>;
  salesDoc: Array<SalesDocEntity>;
  loanScreens: Array<LoanScreenEntity>;
  projectIntegration: ProjectIntegrationEntity;
  projectLetter: Array<ProjectLetterEntity>;
  projectExt?: Maybe<ProjectExtEntity>;
  pictureUnitLayouts: Array<PictureUnitlayoutEntity>;
  projectEmailLetter: Array<ProjectEmailLetterEntity>;
  projectAgencyDiscount: ProjectAgencyDiscountEntity;
  projectAgencyRebate: ProjectAgencyRebateEntity;
  firstGalleryImage?: Maybe<ProjectResourceOuput>;
  projectSpec: Array<ProjectSpecEntity>;
  landTypeDesc: Scalars['String'];
  tenureDesc: Scalars['String'];
  company?: Maybe<CompanyEntity>;
  existingPurchaser?: Maybe<Scalars['Float']>;
  embededYoutubeLink?: Maybe<Array<ProjectYoutubeLinkOutput>>;
  embededVimeoLink?: Maybe<Array<ProjectYoutubeLinkOutput>>;
  embededGoogleDriveLink?: Maybe<Array<ProjectYoutubeLinkOutput>>;
  virtualModel?: Maybe<Array<ProjectResourceOuput>>;
  galleryImage?: Maybe<Array<ProjectGalleryMediaOuput>>;
  blockGalleryImage?: Maybe<Array<ProjectBlockMediaOuput>>;
  pictureBlockUrl?: Maybe<Scalars['String']>;
  floorGalleryImage?: Maybe<Array<ProjectFloorMediaOuput>>;
  unitLayoutGalleryImage?: Maybe<Array<ProjectUnitLayoutMediaOuput>>;
  unitTourVirtual?: Maybe<Array<UnitTourVirtualOutput>>;
  attachment?: Maybe<Array<AttachmentEntity>>;
  projectDetailsUnit?: Maybe<Array<UnitEntity>>;
  resourcefloor?: Maybe<Array<ResourceEntity>>;
  resourceunitlayout?: Maybe<Array<ResourceEntity>>;
  builtup?: Maybe<Array<Scalars['Float']>>;
  totalLoanCount?: Maybe<Scalars['Float']>;
  activeLoans?: Maybe<ActiveLoanStatusOutput>;
  withdrawnLoans?: Maybe<WithdrawnLoanStatusOutput>;
  loanStatusList: Array<EndfinLoanAppStatusEntity>;
  distinctBlock: Array<Scalars['String']>;
  saleschartBlock: Array<Scalars['String']>;
  distinctBlockFloor?: Maybe<Array<ProjectUnitBlockFloorOuput>>;
  unitMinPrice?: Maybe<Scalars['String']>;
  unitMaxPrice?: Maybe<Scalars['String']>;
  unitMinBuiltUp?: Maybe<Scalars['String']>;
  unitMaxBuiltUp?: Maybe<Scalars['String']>;
  unitTypes?: Maybe<Array<UnitTypeOutput>>;
  agencySalesChartUnitDisplay?: Maybe<Array<AgencySalesChartOutput>>;
  agencyAppointedCount?: Maybe<AgencyAppointedCount>;
  solicitorCount?: Maybe<SolicitorStatusCount>;
  countUnitTotal: Scalars['Float'];
  countUnitAvailable: Scalars['Float'];
  countUnitReserved: Scalars['Float'];
  countUnitSigned: Scalars['Float'];
  countUnassignedLeads: Scalars['Float'];
  leadStatusCount?: Maybe<LeadStatusCountOutput>;
  countWaitlistTotal: CountWaitingUnitOutput;
  countBookConfirmation?: Maybe<BookingConfirmationStatusOutput>;
  countBook?: Maybe<BookStatusOutput>;
  isSaleMade: Scalars['Boolean'];
  solicitorLayoutList?: Maybe<Array<SolicitorLayoutList>>;
  countLoan?: Maybe<LoanStatusOutput>;
  uomDesc: Scalars['String'];
  IsSubscribed: Scalars['JSONObject'];
  isManager: Scalars['Boolean'];
  priceRange?: Maybe<Scalars['String']>;
};


export type ProjectEntityPictureBlockUrlArgs = {
  block: Scalars['String'];
};


export type ProjectEntityDistinctBlockArgs = {
  phase?: Maybe<Scalars['String']>;
};


export type ProjectEntitySaleschartBlockArgs = {
  phase?: Maybe<Scalars['String']>;
};


export type ProjectEntityUnitMinPriceArgs = {
  phase?: Maybe<Scalars['String']>;
};


export type ProjectEntityUnitMaxPriceArgs = {
  phase?: Maybe<Scalars['String']>;
};


export type ProjectEntityAgencySalesChartUnitDisplayArgs = {
  agencyId: Scalars['String'];
  block: Scalars['String'];
};


export type ProjectEntitySolicitorLayoutListArgs = {
  solicitorAppointedId?: Maybe<Scalars['String']>;
};

export type ProjectExtEntity = {
  __typename?: 'projectExtEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  bankProfileId: Scalars['String'];
  bankAccountId?: Maybe<Scalars['String']>;
  plotNo?: Maybe<Scalars['String']>;
  townshipId?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  project: ProjectEntity;
  companyBankProfile?: Maybe<CompanyBankProfileEntity>;
  companyBankAccount?: Maybe<CompanyBankAccountEntity>;
  township?: Maybe<TownshipEntity>;
};

export type ProjectFireInsuranceEntity = {
  __typename?: 'projectFireInsuranceEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  companyName?: Maybe<Scalars['String']>;
  companyAddress?: Maybe<AddressJson>;
  certificateNo?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
};

export type ProjectFloorMediaOuput = {
  __typename?: 'ProjectFloorMediaOuput';
  resourceid?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  block: Scalars['String'];
  floor: Scalars['Float'];
  pictureid: Scalars['String'];
  floorlabel?: Maybe<Scalars['String']>;
};

export type ProjectGalleryMediaOuput = {
  __typename?: 'ProjectGalleryMediaOuput';
  resourceid?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  pictureid: Scalars['String'];
  seqno?: Maybe<Scalars['Float']>;
};

export type ProjectIntegrationEntity = {
  __typename?: 'projectIntegrationEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  integrationUrl?: Maybe<Scalars['String']>;
  ifcaCompanyId?: Maybe<Scalars['String']>;
  ifcaProjectId?: Maybe<Scalars['String']>;
  ifcaPhaseId?: Maybe<Scalars['String']>;
  ifcaCompanyName?: Maybe<Scalars['String']>;
  ifcaProjectName?: Maybe<Scalars['String']>;
  ifcaPhaseName?: Maybe<Scalars['String']>;
  isMultiplePhaseIntegrated: Scalars['Boolean'];
  integrationType?: Maybe<Scalars['String']>;
  paymentPlanId?: Maybe<Scalars['String']>;
  paymentPlanCode?: Maybe<Scalars['String']>;
  paymentPlanDesc?: Maybe<Scalars['String']>;
  gracePeriod?: Maybe<Scalars['String']>;
  isDiscountIntegrated: Scalars['Boolean'];
  isPackagesIntegrated: Scalars['Boolean'];
  isSolicitorIntegrated: Scalars['Boolean'];
  isRebateIntegrated: Scalars['Boolean'];
  commonStatus: Scalars['String'];
};

export type ProjectIntegrationLogEntity = AuditEntity & {
  __typename?: 'projectIntegrationLogEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  activityType: Scalars['String'];
  phase: Array<Scalars['Float']>;
};

export type ProjectKeyCollectionContractorEntity = {
  __typename?: 'projectKeyCollectionContractorEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  name: Scalars['String'];
  contactNo: Scalars['String'];
  email: Scalars['String'];
  contractorType: Scalars['String'];
  commonStatus: Scalars['String'];
};

export type ProjectKeyCollectionScheduleEntity = {
  __typename?: 'projectKeyCollectionScheduleEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  interval: Scalars['Float'];
  teamMember: TeamMemberObject;
  commonStatus: Scalars['String'];
  timeslot: Array<ProjectKeyCollectionTimeSlotEntity>;
};

export type ProjectKeyCollectionTimeSlotEntity = {
  __typename?: 'projectKeyCollectionTimeSlotEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  collectionScheduleId: Scalars['String'];
  userId: Scalars['String'];
  scheduleDate: Scalars['DateTime'];
  scheduleTime: Scalars['String'];
  commonStatus: Scalars['String'];
  keyCollectionSchedule?: Maybe<ProjectKeyCollectionScheduleEntity>;
  appointment?: Maybe<KeyCollectionAppointmentEntity>;
};

export type ProjectLadEntity = {
  __typename?: 'projectLadEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  startWith: Scalars['String'];
  unitLadPercent: Scalars['Float'];
  unitDuration: Scalars['Float'];
  payoutPercent: Scalars['Float'];
  spaPercent?: Maybe<Scalars['Float']>;
  commonFacility: Scalars['Boolean'];
  facilityLadPercent?: Maybe<Scalars['Float']>;
  facilityLadDuration?: Maybe<Scalars['Float']>;
  taxSchemeId: Scalars['String'];
  commonStatus: Scalars['String'];
};

export type ProjectLastActiveTransactionsEntity = {
  __typename?: 'projectLastActiveTransactionsEntity';
  project_id: Scalars['String'];
  transaction_date: Scalars['DateTime'];
  cancelled: Scalars['Float'];
  booked: Scalars['Float'];
  signed: Scalars['Float'];
  total: Scalars['Float'];
};

export type ProjectLateInterestEntity = {
  __typename?: 'projectLateInterestEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  loanGracePeriod: Scalars['Float'];
  minimumInterestAmount: Scalars['Float'];
  excludePaymentDate: Scalars['Boolean'];
  excludeHoliday: Scalars['Boolean'];
  enableFloatingInterest: Scalars['Boolean'];
  loanGracePeriodStartWith: Scalars['String'];
  taxSchemeId?: Maybe<Scalars['String']>;
};

export type ProjectLateInterestExceptionEntity = {
  __typename?: 'projectLateInterestExceptionEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  saleId: Scalars['String'];
  remark: Scalars['String'];
  sales?: Maybe<SaleEntity>;
  commonStatus: Scalars['String'];
};

export type ProjectLetterEntity = {
  __typename?: 'projectLetterEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  defaulted: Scalars['Boolean'];
  commonStatus: Scalars['String'];
  project: ProjectEntity;
  letterTemplate: LetterTemplateEntity;
  isViewInESigning: Scalars['Boolean'];
  isLetterVisibleInESigning: Scalars['Boolean'];
};

export type ProjectLetterTemplateEntity = {
  __typename?: 'projectLetterTemplateEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  standardLetterId: Scalars['String'];
  name: Scalars['String'];
  templateBody?: Maybe<Scalars['String']>;
  jointBuyerSignRequired: Scalars['Boolean'];
  commonStatus: Scalars['String'];
  standardLetter: StandardLetterEntity;
  defaulted: Scalars['Boolean'];
  standardLetterDataSourceVersion?: Maybe<Scalars['Float']>;
  isReportDesignEditor: Scalars['Boolean'];
};

export type ProjectLetterTemplateSelectorEntity = {
  __typename?: 'projectLetterTemplateSelectorEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  standardLetterId: Scalars['String'];
  selectionBookingEntry: Scalars['Boolean'];
  commonStatus: Scalars['String'];
};

export type ProjectMiscChargesEntity = {
  __typename?: 'projectMiscChargesEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  billItemId: Scalars['String'];
  interestRate: Scalars['Float'];
  remark?: Maybe<Scalars['String']>;
  formula: Scalars['String'];
  commonStatus: Scalars['String'];
  taxSchemeId: Scalars['String'];
};

export type ProjectPaymentPlanEntity = {
  __typename?: 'projectPaymentPlanEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  paymentPlanId: Scalars['String'];
  defaulted: Scalars['Boolean'];
  commonStatus: Scalars['String'];
  paymentPlan: PaymentPlanEntity;
};

export type ProjectPaymentReminderExceptionEntity = AuditEntity & {
  __typename?: 'projectPaymentReminderExceptionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  saleId: Scalars['String'];
  maxReminder?: Maybe<Scalars['Float']>;
  commonStatus: Scalars['String'];
};

export type ProjectPermitEntity = {
  __typename?: 'projectPermitEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  permitNo: Scalars['String'];
  permitType: Scalars['String'];
  issueDate: Scalars['DateTime'];
  expiryDate: Scalars['DateTime'];
  commonStatus: Scalars['String'];
};

export type ProjectPolicyEntity = {
  __typename?: 'projectPolicyEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  interestRate: Scalars['Float'];
  creditTerms: Scalars['Float'];
  roundingPrecision: Scalars['String'];
  roundingMode: Scalars['String'];
  commonStatus: Scalars['String'];
  toleranceAmount: Scalars['Float'];
};

export type ProjectReminderEntity = AuditEntity & {
  __typename?: 'projectReminderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  spaReminder1: Scalars['Float'];
  spaReminder2: Scalars['Float'];
  spaReminder3: Scalars['Float'];
  spaReminder4: Scalars['Float'];
  downpaymentReminder1: Scalars['Float'];
  downpaymentReminder2: Scalars['Float'];
  downpaymentReminder3: Scalars['Float'];
  downpaymentReminder4: Scalars['Float'];
  paymentReminder1: Scalars['Float'];
  paymentReminder2: Scalars['Float'];
  paymentReminder3: Scalars['Float'];
  paymentReminder4: Scalars['Float'];
  minimumAmount: Scalars['Float'];
  reminderFor?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
};

export type ProjectResourceOuput = {
  __typename?: 'ProjectResourceOuput';
  resourceid?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
};

export type ProjectSaleListOuput = {
  __typename?: 'ProjectSaleListOuput';
  unit_id: Scalars['String'];
  unit_no: Scalars['String'];
  unit_price: Scalars['String'];
  sale_id: Scalars['String'];
  sales_status: Scalars['String'];
  project_id: Scalars['String'];
  booking_amount?: Maybe<Scalars['Float']>;
  contact_id: Scalars['String'];
  full_name: Scalars['String'];
  salesPerson?: Maybe<UserEntity>;
  date: Scalars['DateTime'];
};

export type ProjectSalesOfficeEntity = {
  __typename?: 'ProjectSalesOfficeEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  salesOfficeTel?: Maybe<Scalars['String']>;
  salesOfficeFax?: Maybe<Scalars['String']>;
  salesOfficeAddress?: Maybe<Scalars['String']>;
  propertyManager?: Maybe<Scalars['String']>;
  projectManager?: Maybe<Scalars['String']>;
  generalManager?: Maybe<Scalars['String']>;
  salesMarketingHOD?: Maybe<Scalars['String']>;
  creditControlHOD?: Maybe<Scalars['String']>;
  creditControlOfficeTel?: Maybe<Scalars['String']>;
  creditControlEmail?: Maybe<Scalars['String']>;
  customerCareHOD?: Maybe<Scalars['String']>;
  customerCareContactNo?: Maybe<Scalars['String']>;
  customerCareEmail?: Maybe<Scalars['String']>;
  areaUOM1?: Maybe<Scalars['String']>;
  areaUOM2?: Maybe<Scalars['String']>;
  areaUOM3?: Maybe<Scalars['String']>;
  areaUOMConversion1?: Maybe<Scalars['String']>;
  areaUOMConversion2?: Maybe<Scalars['String']>;
  salesLocalAuthorityName?: Maybe<Scalars['String']>;
  salesLocalAuthorityContactNo?: Maybe<Scalars['String']>;
  salesLocalAuthorityAddress?: Maybe<AddressJson>;
  customerCareLocalAuthorityName?: Maybe<Scalars['String']>;
  customerCareLocalAuthorityContactNo?: Maybe<Scalars['String']>;
  customerCareLocalAuthorityAddress?: Maybe<AddressJson>;
  buildingContractorAwarded?: Maybe<Scalars['String']>;
  buildingContractorName?: Maybe<Scalars['String']>;
  warrantyPeriod?: Maybe<Scalars['Float']>;
  DLPTerm1?: Maybe<Scalars['String']>;
  DLPTerm2?: Maybe<Scalars['String']>;
  salesPermit?: Maybe<Scalars['String']>;
  architecturePermit?: Maybe<Scalars['String']>;
  constructionPermit?: Maybe<Scalars['String']>;
  builtUpArea?: Maybe<Scalars['Float']>;
  densityPercentage?: Maybe<Scalars['Float']>;
  floorArea?: Maybe<Scalars['Float']>;
  floorAreaPercent?: Maybe<Scalars['Float']>;
  commonArea?: Maybe<Scalars['Float']>;
  commonAreaPercent?: Maybe<Scalars['Float']>;
  greenspaceArea?: Maybe<Scalars['Float']>;
  greenspaceAreaPercent?: Maybe<Scalars['Float']>;
  landArea?: Maybe<Scalars['Float']>;
  usageRatioPercent?: Maybe<Scalars['Float']>;
  usagePurpose?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
};

export type ProjectSpecEntity = {
  __typename?: 'projectSpecEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  bookingSignRequired: Scalars['Boolean'];
  bookingFormLang: Scalars['String'];
  lateInterestRate?: Maybe<Scalars['Float']>;
  creditTerm?: Maybe<Scalars['Float']>;
  gracePeriod?: Maybe<Scalars['Float']>;
  reminder_1Duration?: Maybe<Scalars['Float']>;
  reminder_2Duration?: Maybe<Scalars['Float']>;
  reminder_3Duration?: Maybe<Scalars['Float']>;
  reminder_4Duration?: Maybe<Scalars['Float']>;
  minBookingfeeAmount: Scalars['Float'];
  bookingfeeAmountRequired: Scalars['Boolean'];
  bookingFeeMandatory: Scalars['Boolean'];
  nricAttachmentRequired: Scalars['Boolean'];
  ccrAttachmentRequired: Scalars['Boolean'];
  minAgeRequired: Scalars['Boolean'];
  minAge?: Maybe<Scalars['Float']>;
  templateCompanyShow?: Maybe<Scalars['String']>;
  templatePackagesShow: Scalars['Boolean'];
  templateDiscountsShow: Scalars['Boolean'];
  templateAddressShow: Scalars['Boolean'];
  templateRemarksShow: Scalars['Boolean'];
  templateMaritalStatusShow: Scalars['Boolean'];
  bookingPDPAconsentRequired: Scalars['Boolean'];
  bookingRefundPolicyconsentRequired: Scalars['Boolean'];
  statementRefundPolicy?: Maybe<Scalars['String']>;
  loanconsentRequired: Scalars['Boolean'];
  loanScreening: Scalars['Boolean'];
  trGeneration: Scalars['Boolean'];
  ELetteringSMSRequired: Scalars['Boolean'];
  expectedSnpRequired: Scalars['Boolean'];
  expectedSnpDuration?: Maybe<Scalars['Float']>;
  autoAssignLeadActivated: Scalars['Boolean'];
  assignStartTime?: Maybe<Scalars['String']>;
  assignEndTime?: Maybe<Scalars['String']>;
  autoReassignLeadActivated: Scalars['Boolean'];
  autoReassignLeadDuration?: Maybe<Scalars['Float']>;
  reassignStartTime?: Maybe<Scalars['String']>;
  reassignEndTime?: Maybe<Scalars['String']>;
  notifyUnattendedLeadActivated: Scalars['Boolean'];
  notifyUnattendedLeadDuration?: Maybe<Scalars['Float']>;
  unattendedLeadNotificationMethod?: Maybe<Array<Scalars['String']>>;
  unattendedLeadManagerHandled?: Maybe<Array<Scalars['String']>>;
  leadAgingActivated: Scalars['Boolean'];
  leadAgingNotificationMethod?: Maybe<Array<Scalars['String']>>;
  leadIdleOne?: Maybe<Scalars['Float']>;
  leadIdleTwo?: Maybe<Scalars['Float']>;
  leadIdleThree?: Maybe<Scalars['Float']>;
  leadAgingManagerHandled?: Maybe<Array<Scalars['String']>>;
  alertAgingActivated: Scalars['Boolean'];
  salesAlertAging?: Maybe<SalesAlertAgingOutput>;
  loanAlertAging?: Maybe<LoanAlertAgingOutput>;
  solicitorAlertAging?: Maybe<SolicitorAlertAgingOutput>;
  paymentGatewayId?: Maybe<Scalars['String']>;
  paymentGatewayRefundPolicy?: Maybe<Scalars['String']>;
  paymentGateway?: Maybe<PaymentGatewayEntity>;
  project: ProjectEntity;
  purchaserAppSetting: Scalars['String'];
  unitSort: Scalars['String'];
  leadAssignNotificationActivated: Scalars['Boolean'];
  reservationSales: Scalars['Boolean'];
  reservationAgency: Scalars['Boolean'];
  autoReleaseReservationDay: Scalars['Float'];
  leadAssignNotificationMethod?: Maybe<Array<Scalars['String']>>;
  saleLeadNotification?: Maybe<SaleLeadNotificationOutput>;
  agencyLeadNotification?: Maybe<AgencyLeadNotificationOutput>;
  purchaserLeadNotification?: Maybe<PurchaserLeadNotificationOutput>;
  autoReleaseNotificationReminder?: Maybe<AutoReleaseNotificationReminderOutput>;
  display2DChart: Scalars['Boolean'];
  isIfcaReceiptDefaultDesc: Scalars['Boolean'];
  ifcaReceiptDesc: Scalars['String'];
  unitLockSales: Scalars['Boolean'];
  unitLockAgency: Scalars['Boolean'];
  unitLockDuration: Scalars['Float'];
  unitLockBufferDuration: Scalars['Float'];
  purchaserAppSubmenu?: Maybe<PurchaserAppSubmenuOutput>;
  leadEmailRequired: Scalars['Boolean'];
  getAccountSpec?: Maybe<AccountSpecEntity>;
  isReservationEnable?: Maybe<Scalars['Boolean']>;
};

export type ProjectSpecOutput = {
  __typename?: 'ProjectSpecOutput';
  minAgeRequired: Scalars['Boolean'];
  minAge?: Maybe<Scalars['Float']>;
  minBookingfeeAmount: Scalars['Float'];
  bookingSignRequired: Scalars['Boolean'];
  bookingfeeAmountRequired: Scalars['Boolean'];
  bookingFeeMandatory: Scalars['Boolean'];
  bookingPDPAconsentRequired: Scalars['Boolean'];
  bookingRefundPolicyconsentRequired: Scalars['Boolean'];
  statementRefundPolicy?: Maybe<Scalars['String']>;
  paymentGatewayId?: Maybe<Scalars['String']>;
};

export type ProjectTitleEntity = AuditEntity & {
  __typename?: 'projectTitleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  titleType: Scalars['String'];
  titleNo: Scalars['String'];
  issueDate: Scalars['DateTime'];
  receiveDate: Scalars['DateTime'];
  expiryDate: Scalars['DateTime'];
  remark?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  projectTitleUnits: Array<ProjectTitleUnitEntity>;
};

export type ProjectTitleUnitEntity = {
  __typename?: 'projectTitleUnitEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  projectTitleId: Scalars['String'];
  unitId: Scalars['String'];
  commonStatus: Scalars['String'];
  projectTitle: ProjectTitleEntity;
  units: UnitEntity;
};

export type ProjectTypeEntity = {
  __typename?: 'projectTypeEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  name: Scalars['String'];
  commonStatus: Scalars['String'];
  defaulted: Scalars['Boolean'];
  projects: Array<ProjectEntity>;
  seq_no?: Maybe<Scalars['Float']>;
};

export type ProjectUnitBlockFloorOuput = {
  __typename?: 'ProjectUnitBlockFloorOuput';
  block: Scalars['String'];
  floor: Scalars['Float'];
  floorlabel: Scalars['String'];
};

export type ProjectUnitLayoutMediaOuput = {
  __typename?: 'ProjectUnitLayoutMediaOuput';
  resourceid?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  caption?: Maybe<Scalars['String']>;
  unitlayoutid?: Maybe<Scalars['String']>;
  unitLayout?: Maybe<Scalars['String']>;
  unit_type_id?: Maybe<Scalars['String']>;
  pictureid: Scalars['String'];
  seqno?: Maybe<Scalars['Float']>;
  desc?: Maybe<Scalars['String']>;
  virtualModelLink?: Maybe<Scalars['String']>;
  block?: Maybe<Scalars['String']>;
  unit_type_desc?: Maybe<Scalars['String']>;
  unitcount?: Maybe<Scalars['Float']>;
  noOfBedroom?: Maybe<Scalars['Float']>;
  noOfBathroom?: Maybe<Scalars['Float']>;
};

export type ProjectYoutubeLinkOutput = {
  __typename?: 'ProjectYoutubeLinkOutput';
  url: Scalars['String'];
  thumbnail: Scalars['String'];
  videoType?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
};

export type PropertyTypeEntity = {
  __typename?: 'propertyTypeEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  name: Scalars['String'];
  commercialPurpose: Scalars['String'];
  commonStatus: Scalars['String'];
  defaulted: Scalars['Boolean'];
  seq_no?: Maybe<Scalars['Float']>;
  units: Array<UnitEntity>;
  propertyTypeExt?: Maybe<PropertyTypeExtEntity>;
  commercialPurposeDesc: Scalars['String'];
};

export type PropertyTypeExtEntity = {
  __typename?: 'propertyTypeExtEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  propertyTypeId: Scalars['String'];
  taxSchemeId: Scalars['String'];
  commonStatus: Scalars['String'];
  propertyType: PropertyTypeEntity;
};

export type PublicELaunchOutput = {
  __typename?: 'publicELaunchOutput';
  name: Scalars['String'];
  publishDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};

export type PublicProjectOutput = {
  __typename?: 'publicProjectOutput';
  name: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  desc?: Maybe<Scalars['String']>;
  builtup?: Maybe<Array<Scalars['Float']>>;
  completionDate?: Maybe<Scalars['DateTime']>;
  launchDate?: Maybe<Scalars['DateTime']>;
  maxPrice?: Maybe<Scalars['Float']>;
  minPrice?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  tenureDesc?: Maybe<Scalars['String']>;
  unitMaxBuiltUp?: Maybe<Scalars['String']>;
  unitMinBuiltUp?: Maybe<Scalars['String']>;
  uomDesc?: Maybe<Scalars['String']>;
  galleryImage?: Maybe<Array<ProjectGalleryMediaOuput>>;
  embededGoogleDriveLink?: Maybe<Array<ProjectYoutubeLinkOutput>>;
  embededVimeoLink?: Maybe<Array<ProjectYoutubeLinkOutput>>;
  embededYoutubeLink?: Maybe<Array<ProjectYoutubeLinkOutput>>;
  firstGalleryImage?: Maybe<ProjectResourceOuput>;
  developmentCompany?: Maybe<CompanyEntity>;
  holdingCompany?: Maybe<CompanyEntity>;
  townshipName?: Maybe<Scalars['String']>;
  landTypeDesc?: Maybe<Scalars['String']>;
  amenities?: Maybe<Array<Amenities>>;
  projectGps?: Maybe<Scalars['String']>;
  projectAddress?: Maybe<Scalars['String']>;
  saleGalleryAddress?: Maybe<Scalars['String']>;
  saleGalleryGps?: Maybe<Scalars['String']>;
  projectType?: Maybe<ProjectTypeEntity>;
  virtualModelLink?: Maybe<Array<MediaSource>>;
  priceRange?: Maybe<Scalars['String']>;
};

export type PurchaseLeadNotificationInput = {
  keyCollectionAppointment?: Maybe<PurchaserKeyCollectionAppointmentInput>;
  keyCollectionAppointmentReschedule?: Maybe<KeyCollectionAppointmentRescheduleInput>;
};

export type PurchaserAccountReferenceEntity = AuditEntity & {
  __typename?: 'purchaserAccountReferenceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  userId: Scalars['String'];
  refId?: Maybe<Array<PurchaserReferenceOutput>>;
  commonStatus: Scalars['String'];
};

export type PurchaserAppBookedSubmenuInput = {
  booking_info?: Maybe<Scalars['Boolean']>;
  overall_site_progress?: Maybe<Scalars['Boolean']>;
  loan?: Maybe<Scalars['Boolean']>;
};

export type PurchaserAppBookedSubmenuOutput = {
  __typename?: 'PurchaserAppBookedSubmenuOutput';
  booking_info?: Maybe<Scalars['Boolean']>;
  overall_site_progress?: Maybe<Scalars['Boolean']>;
  loan?: Maybe<Scalars['Boolean']>;
};

export type PurchaserAppCssInput = {
  logoUrl?: Maybe<Scalars['String']>;
  cardColor?: Maybe<Scalars['String']>;
  headerColor?: Maybe<Scalars['String']>;
  backgroundSize?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['String']>;
  backgroundRepeat?: Maybe<Scalars['String']>;
  backgroundPosition?: Maybe<Scalars['String']>;
  backgroundAttachment?: Maybe<Scalars['String']>;
  showPurchaserAppWord?: Maybe<Scalars['Boolean']>;
  boxShadow?: Maybe<Scalars['String']>;
  showLoginIcon?: Maybe<Scalars['Boolean']>;
};

export type PurchaserAppCssOutput = {
  __typename?: 'purchaserAppCssOutput';
  logoUrl?: Maybe<Scalars['String']>;
  cardColor?: Maybe<Scalars['String']>;
  headerColor?: Maybe<Scalars['String']>;
  backgroundSize?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['String']>;
  backgroundRepeat?: Maybe<Scalars['String']>;
  backgroundPosition?: Maybe<Scalars['String']>;
  backgroundAttachment?: Maybe<Scalars['String']>;
  showPurchaserAppWord?: Maybe<Scalars['Boolean']>;
  boxShadow?: Maybe<Scalars['String']>;
  showLoginIcon?: Maybe<Scalars['Boolean']>;
};

export type PurchaserAppSignedSubmenuInput = {
  s_and_p?: Maybe<Scalars['Boolean']>;
  overall_site_progress?: Maybe<Scalars['Boolean']>;
  accounts?: Maybe<Scalars['Boolean']>;
  key_collection?: Maybe<Scalars['Boolean']>;
};

export type PurchaserAppSignedSubmenuOutput = {
  __typename?: 'PurchaserAppSignedSubmenuOutput';
  s_and_p?: Maybe<Scalars['Boolean']>;
  overall_site_progress?: Maybe<Scalars['Boolean']>;
  accounts?: Maybe<Scalars['Boolean']>;
  key_collection?: Maybe<Scalars['Boolean']>;
};

export type PurchaserAppSubmenuInput = {
  booked?: Maybe<PurchaserAppBookedSubmenuInput>;
  signed?: Maybe<PurchaserAppSignedSubmenuInput>;
};

export type PurchaserAppSubmenuOutput = {
  __typename?: 'PurchaserAppSubmenuOutput';
  booked?: Maybe<PurchaserAppBookedSubmenuOutput>;
  signed?: Maybe<PurchaserAppSignedSubmenuOutput>;
};

export type PurchaserContactEntity = AuditEntity & {
  __typename?: 'purchaserContactEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  userId: Scalars['String'];
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phoneNo?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  lead?: Maybe<Array<PurchaserLeadContactOutput>>;
  user: PurchaserUserEntity;
};

export type PurchaserKeyCollectionAppointmentInput = {
  salesUserReceive?: Maybe<Scalars['Boolean']>;
  agencyUserReceive?: Maybe<Scalars['Boolean']>;
  keyCollectionSalesPersonReceive?: Maybe<Scalars['Boolean']>;
  purchaserUserReceive?: Maybe<Scalars['Boolean']>;
};

export type PurchaserKeyCollectionAppointmentOutput = {
  __typename?: 'purchaserKeyCollectionAppointmentOutput';
  salesUserReceive?: Maybe<Scalars['Boolean']>;
  agencyUserReceive?: Maybe<Scalars['Boolean']>;
  keyCollectionSalesPersonReceive?: Maybe<Scalars['Boolean']>;
  purchaserUserReceive?: Maybe<Scalars['Boolean']>;
};

export type PurchaserKeyCollectionAppointmentRescheduleOutput = {
  __typename?: 'purchaserKeyCollectionAppointmentRescheduleOutput';
  salesUserReceive?: Maybe<Scalars['Boolean']>;
  agencyUserReceive?: Maybe<Scalars['Boolean']>;
  keyCollectionSalesPersonReceive?: Maybe<Scalars['Boolean']>;
  purchaserUserReceive?: Maybe<Scalars['Boolean']>;
};

export type PurchaserLeadContactInput = {
  LeadId: Scalars['String'];
  ProjectId: Scalars['String'];
};

export type PurchaserLeadContactOutput = {
  __typename?: 'PurchaserLeadContactOutput';
  LeadId: Scalars['String'];
  ProjectId: Scalars['String'];
};

export type PurchaserLeadNotificationOutput = {
  __typename?: 'PurchaserLeadNotificationOutput';
  keyCollectionAppointment?: Maybe<PurchaserKeyCollectionAppointmentOutput>;
  keyCollectionAppointmentReschedule?: Maybe<PurchaserKeyCollectionAppointmentRescheduleOutput>;
};

export type PurchaserNotificationEntity = {
  __typename?: 'purchaserNotificationEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  subject: Scalars['String'];
  body: Scalars['String'];
  purchaserNotificationRecipient: Array<PurchaserNotificationRecipientEntity>;
};

export type PurchaserNotificationRecipientEntity = {
  __typename?: 'purchaserNotificationRecipientEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  notificationId: Scalars['String'];
  recipientId: Scalars['String'];
  isRead: Scalars['Boolean'];
  purchaserNotification: PurchaserNotificationEntity;
};

export type PurchaserPaymentEntity = AuditEntity & {
  __typename?: 'purchaserPaymentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  unitId: Scalars['String'];
  ifcaSaleId?: Maybe<Scalars['Float']>;
  saleId?: Maybe<Scalars['String']>;
  receiptNo?: Maybe<Scalars['String']>;
  paymentGatewayId?: Maybe<Scalars['String']>;
  paymentStatus: Scalars['String'];
  paymentMethod: Scalars['String'];
  amount: Scalars['Float'];
  refNo?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['DateTime']>;
  transactionMethod?: Maybe<Scalars['String']>;
  response: Scalars['JSONObject'];
};

export type PurchaserReferenceInput = {
  SaleId: Scalars['Float'];
  ProjectId: Scalars['Float'];
  ProjectID: Scalars['Float'];
  UnitId: Scalars['Float'];
  AccountID: Scalars['Float'];
  SaleStatus: Scalars['String'];
  IsIntegrate?: Maybe<Scalars['Boolean']>;
  IntegrationId?: Maybe<Scalars['String']>;
};

export type PurchaserReferenceOutput = {
  __typename?: 'PurchaserReferenceOutput';
  SaleId: Scalars['Float'];
  ProjectId: Scalars['Float'];
  ProjectID: Scalars['Float'];
  UnitId: Scalars['Float'];
  AccountID: Scalars['Float'];
  SaleStatus: Scalars['String'];
  IsIntegrate?: Maybe<Scalars['Boolean']>;
  IntegrationId?: Maybe<Scalars['String']>;
};

export type PurchaserUserEntity = AuditEntity & {
  __typename?: 'purchaserUserEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  loginId: Scalars['String'];
  passwordHash?: Maybe<Scalars['String']>;
  activated: Scalars['Boolean'];
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  forgotPasswordLink?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  purchaserContact: PurchaserContactEntity;
  purchaserAccountReference?: Maybe<PurchaserAccountReferenceEntity>;
};

export type Query = {
  __typename?: 'Query';
  verifyOTP: Scalars['Boolean'];
  sendForgetPasswordOTP: Scalars['Boolean'];
  sendRegisterOTP: Scalars['Boolean'];
  checkURLValidity: VerifyUrlValidity;
  login: ELaunchUserEntity;
  logout: Scalars['Boolean'];
  profile: ELaunchContactEntity;
  getEditProfile: ELaunchContactEntity;
  passswordPolicy: PassswordPolicyOuput;
  refreshToken?: Maybe<Scalars['String']>;
  getLoginDetail: ELaunchUserEntity;
  getUserTranslation: LanguageOutput;
  getDefaultTranslation: LanguageOutput;
  GetWaterMarkText: Scalars['String'];
  getSaleCancelReason: Array<ReasonCodeEntity>;
  GetBookingDetail?: Maybe<SaleEntity>;
  GetBookingDetailData: BookingDetailOutput;
  getBookingFormData: BookingFormDataOutput;
  getPDPADocument?: Maybe<PdpaDetailByProject>;
  GetJointBuyer: ContactEntity;
  CancelBooking: Scalars['Boolean'];
  eLaunchDownloadAttachment: ELaunchDownloadAttachmentOuput;
  GetSalesChartByBlock: SalesChartFloorUnitOutput;
  GetUnitDetail?: Maybe<UnitEntity>;
  GetBlockUnits: GetBlockUnitsOutput;
  getCustomerOrigin: Array<CustomerOriginSelection>;
  getGeneralTinByParty: EInvoiceGeneralTinEntity;
  getIdentityType: Array<IdentityTypeSelection>;
  getELaunch?: Maybe<ELaunchEntity>;
  getPublicELaunch?: Maybe<PublicELaunchOutput>;
  getShareableLink: Scalars['String'];
  getProjectLetterForDocListing: Array<ESignProjectLetterListingOutput>;
  getMultiELetterBySaleId: Array<MultipleEsignProjectLetterListingOutput>;
  getELetterPDF: ELetterOutput;
  GetAllMasterInfo: MasterDataOutput;
  GetStatesByCountry?: Maybe<Array<SStateEntity>>;
  GetCitiesByState?: Maybe<Array<SCityEntity>>;
  GetPostcodeByCity?: Maybe<Array<SPostcodeEntity>>;
  getSalePaymentDataBySaleId: SalePaymentConfirmResultOutput;
  getIpay88PaymentDataSale: Ipay88PaymentOutput;
  getIpay88PaymentMethodEnum: Scalars['JSONObject'];
  revenueMonsterPaymentUrlSale: Scalars['String'];
  getSalePaymentDataByRefNo: SalePaymentConfirmResultOutput;
  getProjectChartMapped?: Maybe<Scalars['Boolean']>;
  getProject?: Maybe<ProjectEntity>;
  getPublicProject: PublicProjectOutput;
  getFavouritedBookedUnitList?: Maybe<Array<FavouritedBookedUnitOutput>>;
  getRefundPolicy?: Maybe<Scalars['String']>;
  getBrochureList: Array<SalesDocEntity>;
  getPublicBrochureList: Array<GetPublicBrochureOutput>;
};


export type QueryVerifyOtpArgs = {
  otpType: Scalars['String'];
  otpCode: Scalars['String'];
  contactNo: Scalars['String'];
  accountCode: Scalars['String'];
};


export type QuerySendForgetPasswordOtpArgs = {
  contactNo: Scalars['String'];
  accountCode: Scalars['String'];
};


export type QuerySendRegisterOtpArgs = {
  contactNo: Scalars['String'];
  eLaunchCode: Scalars['String'];
  accountCode: Scalars['String'];
};


export type QueryCheckUrlValidityArgs = {
  eLaunchCode: Scalars['String'];
  accountCode: Scalars['String'];
};


export type QueryLoginArgs = {
  eLaunchCode: Scalars['String'];
  accountCode: Scalars['String'];
  password: Scalars['String'];
  loginId: Scalars['String'];
};


export type QueryGetBookingDetailArgs = {
  sale_id: Scalars['String'];
};


export type QueryGetBookingDetailDataArgs = {
  saleId: Scalars['String'];
};


export type QueryGetJointBuyerArgs = {
  contactId: Scalars['String'];
};


export type QueryCancelBookingArgs = {
  reason_id: Scalars['String'];
  reason: Scalars['String'];
  sale_id: Scalars['String'];
};


export type QueryELaunchDownloadAttachmentArgs = {
  attachmentId: Scalars['String'];
};


export type QueryGetSalesChartByBlockArgs = {
  keySearch?: Maybe<Scalars['String']>;
  block: Scalars['String'];
};


export type QueryGetUnitDetailArgs = {
  unitId: Scalars['String'];
};


export type QueryGetBlockUnitsArgs = {
  keySearch?: Maybe<Scalars['String']>;
  block: Scalars['String'];
};


export type QueryGetGeneralTinByPartyArgs = {
  party?: Maybe<Scalars['String']>;
};


export type QueryGetPublicELaunchArgs = {
  eLaunchCode: Scalars['String'];
  accountCode: Scalars['String'];
};


export type QueryGetProjectLetterForDocListingArgs = {
  saleId: Scalars['String'];
};


export type QueryGetMultiELetterBySaleIdArgs = {
  saleId: Scalars['String'];
};


export type QueryGetELetterPdfArgs = {
  saleId: Scalars['String'];
  letterTemplateId: Scalars['String'];
  projectId: Scalars['String'];
};


export type QueryGetStatesByCountryArgs = {
  countryId: Scalars['String'];
};


export type QueryGetCitiesByStateArgs = {
  stateId: Scalars['String'];
};


export type QueryGetPostcodeByCityArgs = {
  cityId: Scalars['String'];
};


export type QueryGetSalePaymentDataBySaleIdArgs = {
  sale_id: Scalars['String'];
};


export type QueryGetIpay88PaymentDataSaleArgs = {
  payment_id: Scalars['String'];
  sale_id: Scalars['String'];
};


export type QueryRevenueMonsterPaymentUrlSaleArgs = {
  sale_id: Scalars['String'];
};


export type QueryGetSalePaymentDataByRefNoArgs = {
  ref_no: Scalars['String'];
};


export type QueryGetPublicProjectArgs = {
  eLaunchCode: Scalars['String'];
  accountCode: Scalars['String'];
};


export type QueryGetPublicBrochureListArgs = {
  eLaunchCode: Scalars['String'];
  accountCode: Scalars['String'];
};

export type QueryFilterString = {
  __typename?: 'QueryFilterString';
  Column: Scalars['String'];
  Expression: Scalars['String'];
  Value: Scalars['String'];
  ParameterName?: Maybe<Scalars['String']>;
};

export type QueryFilterStringInput = {
  Column: Scalars['String'];
  Expression: Scalars['String'];
  Value: Scalars['String'];
  ParameterName?: Maybe<Scalars['String']>;
};

export type QueryParameter = {
  __typename?: 'QueryParameter';
  Name: Scalars['String'];
  Type?: Maybe<Scalars['String']>;
  ValueType: Scalars['String'];
  Value: Scalars['String'];
};

export type QueryParameterInput = {
  Name: Scalars['String'];
  Type?: Maybe<Scalars['String']>;
  ValueType: Scalars['String'];
  Value: Scalars['String'];
};

export type RaceEntity = {
  __typename?: 'raceEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  name: Scalars['String'];
  commonStatus: Scalars['String'];
  defaulted: Scalars['Boolean'];
  seqNo?: Maybe<Scalars['Float']>;
  contacts: Array<ContactEntity>;
};

export type ReasonCodeEntity = {
  __typename?: 'reasonCodeEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  name: Scalars['String'];
  commonStatus: Scalars['String'];
  defaulted: Scalars['Boolean'];
  seqNo?: Maybe<Scalars['Float']>;
  type: Scalars['String'];
  systemUse: Scalars['Boolean'];
};

export type RebateEntity = {
  __typename?: 'rebateEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  saleId: Scalars['String'];
  documentNo?: Maybe<Scalars['String']>;
  documentRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  documentDate: Scalars['DateTime'];
  documentAmount: Scalars['Float'];
  glGenerationBatch?: Maybe<Scalars['String']>;
  glGenerated: Scalars['String'];
  status: Scalars['String'];
  requestedBy?: Maybe<Scalars['String']>;
  submittedDate?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedDate?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  saleRebatePackage: SaleRebatePackageEntity;
  allocation?: Maybe<Array<AllocationEntity>>;
  billItemId: Scalars['String'];
  allowReversal: Scalars['Boolean'];
  voidBy?: Maybe<Scalars['String']>;
  voidDate?: Maybe<Scalars['DateTime']>;
};

export type ReceiptEntity = {
  __typename?: 'receiptEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  saleId: Scalars['String'];
  financierId?: Maybe<Scalars['String']>;
  paymentMethod: Scalars['String'];
  documentNo: Scalars['String'];
  documentRef?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  documentDate: Scalars['DateTime'];
  currency?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  documentAmount: Scalars['Float'];
  receiptAmount: Scalars['Float'];
  chequeDate?: Maybe<Scalars['DateTime']>;
  chequeExpireDate?: Maybe<Scalars['DateTime']>;
  creditCardFacilityId?: Maybe<Scalars['String']>;
  creditCardType?: Maybe<Scalars['String']>;
  bankAccountId?: Maybe<Scalars['String']>;
  glGenerationBatch?: Maybe<Scalars['String']>;
  glGenerated: Scalars['String'];
  cbGenerationBatch?: Maybe<Scalars['String']>;
  cbGenerated: Scalars['Boolean'];
  submittedBy?: Maybe<Scalars['String']>;
  submittedDate?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  approvedDate?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
  commonStatus: Scalars['String'];
  allocation?: Maybe<Array<AllocationEntity>>;
  sales?: Maybe<SaleEntity>;
  financiers?: Maybe<Array<FinancierEntity>>;
  accounts?: Maybe<Array<CompanyBankAccountEntity>>;
  allowReversal: Scalars['Boolean'];
  voidBy?: Maybe<Scalars['String']>;
  voidDate?: Maybe<Scalars['DateTime']>;
  generationBatch: Scalars['String'];
  generationBatchDate: Scalars['DateTime'];
};

export type ReceiptReversalEntity = {
  __typename?: 'receiptReversalEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  saleId: Scalars['String'];
  financierId?: Maybe<Scalars['String']>;
  documentNo: Scalars['String'];
  description: Scalars['String'];
  documentDate: Scalars['DateTime'];
  currency?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  documentAmount: Scalars['Float'];
  receiptAmount: Scalars['Float'];
  bankAccountId?: Maybe<Scalars['String']>;
  glGenerationBatch?: Maybe<Scalars['String']>;
  glGenerated: Scalars['String'];
  cbGenerationBatch?: Maybe<Scalars['String']>;
  cbGenerated: Scalars['Boolean'];
  submittedBy?: Maybe<Scalars['String']>;
  submittedDate?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  approvedDate?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
  voidBy?: Maybe<Scalars['String']>;
  voidDate?: Maybe<Scalars['DateTime']>;
  commonStatus: Scalars['String'];
  sales?: Maybe<SaleEntity>;
  financiers?: Maybe<Array<FinancierEntity>>;
  bankAccount?: Maybe<Array<CompanyBankAccountEntity>>;
};

export type RedemptionDetailEntity = {
  __typename?: 'redemptionDetailEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  redemptionId: Scalars['String'];
  unitId: Scalars['String'];
  documentNo?: Maybe<Scalars['String']>;
  redemptionAmount: Scalars['Float'];
  contraId?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  redemption: RedemptionEntity;
};

export type RedemptionEntity = {
  __typename?: 'redemptionEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  paymentParty: Scalars['String'];
  paidById: Scalars['String'];
  documentDate: Scalars['DateTime'];
  referenceNo?: Maybe<Scalars['String']>;
  redemptionSum: Scalars['Float'];
  submittedBy?: Maybe<Scalars['DateTime']>;
  submittedDate?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  approvedDate?: Maybe<Scalars['DateTime']>;
  voidBy?: Maybe<Scalars['String']>;
  voidDate?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
  isPosted: Scalars['Boolean'];
  commonStatus: Scalars['String'];
  redemptionDetails: Array<RedemptionDetailEntity>;
};

export type RefinancingEntity = {
  __typename?: 'refinancingEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  refTable?: Maybe<Scalars['String']>;
  refId: Scalars['String'];
  financierId: Scalars['String'];
  financierReferenceNo: Scalars['String'];
  rrDate: Scalars['DateTime'];
  rrSolicitorId: Scalars['String'];
  rrSolicitorReferenceNo: Scalars['String'];
  doaDate: Scalars['DateTime'];
  doaSolicitorId: Scalars['String'];
  doaSolicitorReferenceNo: Scalars['String'];
  loanAgreementDate: Scalars['DateTime'];
  loanAmount: Scalars['Float'];
  loanSolicitorId: Scalars['String'];
  loanSolicitorReferenceNo?: Maybe<Scalars['String']>;
  loanSeqNo?: Maybe<Scalars['Float']>;
  generationBatch: Scalars['String'];
  status: Scalars['String'];
  completedDate?: Maybe<Scalars['DateTime']>;
  commonStatus: Scalars['String'];
};

export type RefundEntity = {
  __typename?: 'refundEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  saleId: Scalars['String'];
  refundMethod: Scalars['String'];
  documentNo?: Maybe<Scalars['String']>;
  documentRef?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  documentDate: Scalars['DateTime'];
  documentAmount: Scalars['Float'];
  chequeDate?: Maybe<Scalars['DateTime']>;
  chequeExpireDate?: Maybe<Scalars['DateTime']>;
  bankAccountId?: Maybe<Scalars['String']>;
  glGenerationBatch?: Maybe<Scalars['String']>;
  glGenerated: Scalars['String'];
  submittedDate?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedDate?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  commonStatus: Scalars['String'];
  sales?: Maybe<SaleEntity>;
  creditCardType?: Maybe<Scalars['String']>;
};

export type RegionEntity = {
  __typename?: 'regionEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  name: Scalars['String'];
  systemUse: Scalars['Boolean'];
  commonStatus: Scalars['String'];
  defaulted: Scalars['Boolean'];
  seqNo?: Maybe<Scalars['Float']>;
};

export type RegisterUserInput = {
  accountCode: Scalars['String'];
  eLaunchCode: Scalars['String'];
  name: Scalars['String'];
  contactNo: Scalars['String'];
  otpCode: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  confirmPassword: Scalars['String'];
  invitedById?: Maybe<Scalars['String']>;
  invitedBy?: Maybe<Scalars['String']>;
};

export type ReminderEntity = {
  __typename?: 'reminderEntity';
  id: Scalars['String'];
  reminderNo?: Maybe<Scalars['String']>;
  balanceAmount: Scalars['String'];
  reminderDate: Scalars['DateTime'];
  reminderSeq: Scalars['Float'];
  commonStatus: Scalars['String'];
  batchId: Scalars['String'];
  trxStatus: Scalars['String'];
  ledger: LedgerEntity;
  project: ProjectEntity;
  sale: SaleEntity;
};

export type ReportParameter = {
  __typename?: 'ReportParameter';
  Name: Scalars['String'];
  Description: Scalars['String'];
  Type: Scalars['String'];
  Visible?: Maybe<Scalars['Boolean']>;
  AllowNull?: Maybe<Scalars['Boolean']>;
  DropDownMember?: Maybe<DropDownMember>;
  MultiValue?: Maybe<Scalars['Boolean']>;
  IsDateRange?: Maybe<Scalars['Boolean']>;
  GlobalType?: Maybe<Scalars['String']>;
  Tag?: Maybe<Scalars['String']>;
};

export type ReportParameterInput = {
  Name: Scalars['String'];
  Description: Scalars['String'];
  Type: Scalars['String'];
  Visible?: Maybe<Scalars['Boolean']>;
  AllowNull?: Maybe<Scalars['Boolean']>;
  DropDownMember?: Maybe<DropDownMemberInput>;
  MultiValue?: Maybe<Scalars['Boolean']>;
  IsDateRange?: Maybe<Scalars['Boolean']>;
  GlobalType?: Maybe<Scalars['String']>;
  Tag?: Maybe<Scalars['String']>;
};

export type ReportPermissionEntity = AuditEntity & {
  __typename?: 'reportPermissionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  roleId: Scalars['String'];
  permissionId: Scalars['Float'];
};

export type ResourceEntity = AuditEntity & {
  __typename?: 'resourceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  mediaType: Scalars['String'];
  resourceUrl: Scalars['String'];
  fileName: Scalars['String'];
  bucketFileName: Scalars['String'];
  isPrivate: Scalars['Boolean'];
  caption?: Maybe<Scalars['String']>;
  pictureBlocks: Array<PictureBlockEntity>;
  pictureFloors: Array<PictureFloorEntity>;
  pictureGalleries: Array<PictureGalleryEntity>;
  pictureUnitlayouts: Array<PictureUnitlayoutEntity>;
  account_id: Scalars['String'];
  salesCharts: Array<SalesChartEntity>;
};

export type ResourceUrl = {
  __typename?: 'resourceUrl';
  resourceUrl: Scalars['String'];
};

export type RoleEntity = AuditEntity & {
  __typename?: 'roleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  softwareCode: Scalars['String'];
  name: Scalars['String'];
  permissions: Array<Scalars['Float']>;
  returnSalePermissionOutput: Array<SalesPermission>;
  permissionIds: Array<Scalars['Float']>;
  users?: Maybe<Array<UserEntity>>;
  userCountByRole: Scalars['Float'];
  salesAndReportPermission: Array<Scalars['Float']>;
  salesAndReportPermissionIds: Array<Scalars['Float']>;
};


export type RoleEntityUsersArgs = {
  projectId?: Maybe<Scalars['String']>;
};

export type SaleCancellationEntity = AuditEntity & {
  __typename?: 'saleCancellationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  saleId: Scalars['String'];
  cancellationDate: Scalars['DateTime'];
  forfeitureSum: Scalars['Float'];
  taxSchemeId: Scalars['String'];
  cancelReason: Scalars['String'];
  remark: Scalars['String'];
  status: Scalars['String'];
  commonStatus: Scalars['String'];
  sales: SaleEntity;
};

export type SaleContactEntity = AuditEntity & {
  __typename?: 'saleContactEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  saleId: Scalars['String'];
  name: Scalars['String'];
  contactNo: Scalars['String'];
  officeTel?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  salutationId?: Maybe<Scalars['String']>;
  relationshipId?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  isPrimary: Scalars['Boolean'];
  salutation?: Maybe<SalutationEntity>;
  parameter?: Maybe<ParameterEntity>;
};

export type SaleDiscountEntity = {
  __typename?: 'saleDiscountEntity';
  id: Scalars['String'];
  saleId: Scalars['String'];
  discountId: Scalars['String'];
  discount: DiscountEntity;
  sale: SaleEntity;
  amount: Scalars['Float'];
  seqNo: Scalars['Float'];
  saleDiscountExtension?: Maybe<SalesDiscountExtEntity>;
};

export type SaleEntity = {
  __typename?: 'saleEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  unitId: Scalars['String'];
  salesPersonId?: Maybe<Scalars['String']>;
  developerSolicitorId?: Maybe<Scalars['String']>;
  leadId?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
  salesTeamId?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['String']>;
  developerReference?: Maybe<Scalars['String']>;
  buyerReference?: Maybe<Scalars['String']>;
  salesNo: Scalars['String'];
  trNo: Scalars['String'];
  bookingRemark?: Maybe<Scalars['String']>;
  bookDate: Scalars['DateTime'];
  reserveDate?: Maybe<Scalars['DateTime']>;
  approvedDate?: Maybe<Scalars['DateTime']>;
  cancelledDate?: Maybe<Scalars['DateTime']>;
  cancelledBy?: Maybe<Scalars['String']>;
  cancelledReason?: Maybe<Scalars['String']>;
  cancelReasonId?: Maybe<Scalars['String']>;
  salesStatus: Scalars['String'];
  isBumi: Scalars['Boolean'];
  paymentMethod?: Maybe<Scalars['String']>;
  paymentReference?: Maybe<Scalars['String']>;
  bookingAmount?: Maybe<Scalars['Float']>;
  paymentRemark?: Maybe<Scalars['String']>;
  listPrice?: Maybe<Scalars['Float']>;
  billMethod?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  sellingPrice?: Maybe<Scalars['Float']>;
  spaDate?: Maybe<Scalars['DateTime']>;
  stampDate?: Maybe<Scalars['DateTime']>;
  spaNo?: Maybe<Scalars['String']>;
  developerSpaStatus?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  vpDate?: Maybe<Scalars['DateTime']>;
  address?: Maybe<AddressJson>;
  package?: Maybe<Array<Scalars['String']>>;
  agentPersonId?: Maybe<Scalars['String']>;
  agencyAcctId?: Maybe<Scalars['String']>;
  agencyContractId?: Maybe<Scalars['String']>;
  saleOrigin: Scalars['String'];
  retryCount: Scalars['Float'];
  pendingIntegrate: Scalars['Boolean'];
  ifcaContactId: Scalars['String'];
  ifcaSaleId: Scalars['Float'];
  ifcaSaleNo: Scalars['String'];
  expectedSnpDate?: Maybe<Scalars['DateTime']>;
  isCompany: Scalars['Boolean'];
  collectedDate?: Maybe<Scalars['DateTime']>;
  elaunchId?: Maybe<Scalars['String']>;
  bookingTimeTaken?: Maybe<Scalars['Float']>;
  buyerName?: Maybe<Scalars['String']>;
  buyerIdentityNo?: Maybe<Scalars['String']>;
  buyerIdentityType?: Maybe<Scalars['String']>;
  snpReminderBeforeDaysNotify: Scalars['Boolean'];
  snpReminderAfterDaysNotify: Scalars['Boolean'];
  endfinLoanApps: Array<EndfinLoanAppEntity>;
  interests: Array<InterestEntity>;
  jointBuyers: Array<JointBuyerEntity>;
  ledgers: Array<LedgerEntity>;
  reminders: Array<ReminderEntity>;
  letterSign: Array<LetterSignEntity>;
  buyerSolicitor?: Maybe<SolicitorAppointedEntity>;
  agencyAccId?: Maybe<AgencyEntity>;
  contact: ContactEntity;
  developerSolicitor?: Maybe<SolicitorAppointedEntity>;
  lead: LeadEntity;
  project: ProjectEntity;
  salesTeam?: Maybe<SaleTeamEntity>;
  stakeholder: SolicitorAppointedEntity;
  unit: UnitEntity;
  unitIntegration?: Maybe<UnitIntegrationEntity>;
  schedules: Array<ScheduleEntity>;
  leadSource?: Maybe<LeadSourceEntity>;
  incomeRange?: Maybe<IncomeRangeEntity>;
  salesDiscount: Array<SaleDiscountEntity>;
  solicitorProgressLog?: Maybe<Array<SolicitorProgressLogEntity>>;
  salePayment?: Maybe<SalePaymentEntity>;
  accountSpec?: Maybe<AccountSpecEntity>;
  integrationDiscount?: Maybe<Array<IntegrationDiscountEntity>>;
  projectIntegration?: Maybe<Array<ProjectIntegrationEntity>>;
  saleLoan?: Maybe<Array<SaleLoanEntity>>;
  motTracking?: Maybe<Array<MotTrackingEntity>>;
  referralName?: Maybe<Scalars['String']>;
  referralContactNo?: Maybe<Scalars['String']>;
  referralReferenceNo?: Maybe<Scalars['String']>;
  convertedToBooking: Scalars['Boolean'];
  saleExtension?: Maybe<SaleExtensionEntity>;
  spaReminder?: Maybe<Array<SpaReminderEntity>>;
  saleCancellation?: Maybe<SaleCancellationEntity>;
  attachment?: Maybe<AttachmentEntity>;
  agencyUser?: Maybe<AgencyContactEntity>;
  progressBilling?: Maybe<Array<ProgressBillingEntity>>;
  saleRebate?: Maybe<Array<SaleRebatePackageEntity>>;
  nonIntegratedRebate?: Maybe<Array<SaleRebateEntity>>;
  receipt?: Maybe<Array<ReceiptEntity>>;
  receiptReversal?: Maybe<Array<ReceiptReversalEntity>>;
  miscBilling?: Maybe<Array<MiscBillingEntity>>;
  subProduct?: Maybe<Array<SaleSubproductEntity>>;
  debitNote?: Maybe<Array<DebitNoteEntity>>;
  refund?: Maybe<Array<RefundEntity>>;
  lateInterest?: Maybe<Array<LateInterestEntity>>;
  areaVariance?: Maybe<Array<AreaVarianceEntity>>;
  keyCollectionAppointment?: Maybe<Array<KeyCollectionAppointmentEntity>>;
  lateInterestException?: Maybe<ProjectLateInterestExceptionEntity>;
  employerName?: Maybe<Scalars['String']>;
  employerAddress?: Maybe<Scalars['String']>;
  spouseContactId?: Maybe<Scalars['String']>;
  spouse?: Maybe<ContactEntity>;
  markUpBy?: Maybe<Scalars['String']>;
  markUpValue?: Maybe<Scalars['Float']>;
  markUpReason?: Maybe<Scalars['String']>;
  markUpAmount?: Maybe<Scalars['Float']>;
  addressList?: Maybe<Array<ContactAddressEntity>>;
  isAutoReleaseNotified: Scalars['Boolean'];
  attendedBy?: Maybe<Scalars['String']>;
  followUpBy?: Maybe<Scalars['String']>;
  isCancelNotified: Scalars['Boolean'];
  designation?: Maybe<ParameterEntity>;
  salesPerson?: Maybe<Scalars['String']>;
  salesTeamName?: Maybe<Scalars['String']>;
  cancelledByUser?: Maybe<UserEntity>;
  packageList: Array<PackageEntity>;
  discount: Array<SaleDiscountEntity>;
  events?: Maybe<EventsEntity>;
  projectCompany: CompanyEntity;
  joinBuyer: Array<JointBuyerEntity>;
  joinBuyerInfo?: Maybe<JointBuyerInfoForBookingListing>;
  bookingFeeAttachments: Array<AttachmentEntity>;
  bookingFeeInfo?: Maybe<SalePaymentEntity>;
  bookingAttachments: BookingAttachmentsOutput;
  bookingAttachmentsCount: Scalars['Float'];
  bookingBadgeCount: Scalars['Float'];
  otherAttachments: Array<AttachmentEntity>;
  taxDetail?: Maybe<Array<SaleTaxOutput>>;
  progressLogSolicitor?: Maybe<Array<ProgressLogDetails>>;
  endfinLoanAppsCount: Scalars['Float'];
  SaleStatusList: Scalars['JSONObject'];
  buyerSignatureUrl?: Maybe<Scalars['String']>;
  PdpaSignatureUrl?: Maybe<Scalars['String']>;
  paymentMethodDesc?: Maybe<Scalars['String']>;
  loanScreening?: Maybe<LoanScreenStatusOutput>;
  loanScreeningReports?: Maybe<Array<LoanScreenReportOutput>>;
  isCustomisedBookingForm: Scalars['Boolean'];
  repeatPurchaserSales?: Maybe<Scalars['Float']>;
  repeatPurchaserAgency?: Maybe<Scalars['Float']>;
  contactId?: Maybe<Scalars['String']>;
  contactAddress?: Maybe<Array<AddressJson>>;
  contactExt?: Maybe<ContactExtensionEntity>;
  saleExt?: Maybe<SaleExtensionEntity>;
  contactExtention?: Maybe<ContactExtensionEntity>;
};

export type SaleExtensionEntity = AuditEntity & {
  __typename?: 'saleExtensionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  saleId: Scalars['String'];
  paymentPlanId?: Maybe<Scalars['String']>;
  totalSalesValue?: Maybe<Scalars['Float']>;
  salesType?: Maybe<Scalars['String']>;
  discardBumiDiscount?: Maybe<Scalars['Boolean']>;
  isCashBuyer?: Maybe<Scalars['Boolean']>;
  loanGracePeriod?: Maybe<Scalars['Float']>;
  creditTerm?: Maybe<Scalars['Float']>;
  campaignId?: Maybe<Scalars['String']>;
  isGenerateReminder?: Maybe<Scalars['Boolean']>;
  isGenerateInterest?: Maybe<Scalars['Boolean']>;
  sales: SaleEntity;
  paymentPlan?: Maybe<PaymentPlanEntity>;
  isEInvoiceRequired: Scalars['Boolean'];
  isGovAuth: Scalars['Boolean'];
};

export type SaleLeadNotificationInput = {
  leadAssignment?: Maybe<Scalars['Boolean']>;
  loanAssignment?: Maybe<LoanAssignmentInput>;
  solicitorCaseAssignment?: Maybe<SolicitorCaseAssignmentInput>;
  bookingCancellation?: Maybe<BookingCancellationNotificationInput>;
  booking?: Maybe<BookingNotificationInput>;
  reservation?: Maybe<BookingNotificationInput>;
  snpSigningReminder?: Maybe<SnpSigningReminderInput>;
  bookingReassignment?: Maybe<Scalars['Boolean']>;
};

export type SaleLeadNotificationOutput = {
  __typename?: 'SaleLeadNotificationOutput';
  leadAssignment?: Maybe<Scalars['Boolean']>;
  loanAssignment?: Maybe<LoanAssignmentOutput>;
  solicitorCaseAssignment?: Maybe<SolicitorCaseAssignmentOutput>;
  bookingCancellation?: Maybe<BookingCancellationNotificationOutput>;
  booking?: Maybe<BookingNotificationOutput>;
  reservation?: Maybe<BookingNotificationOutput>;
  snpSigningReminder?: Maybe<SnpSigningReminderOutput>;
  bookingReassignment?: Maybe<Scalars['Boolean']>;
};

export type SaleLoanCancellationDetailEntity = AuditEntity & {
  __typename?: 'saleLoanCancellationDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  saleLoanCancellationId: Scalars['String'];
  saleLoanId: Scalars['String'];
  commonStatus: Scalars['String'];
};

export type SaleLoanCancellationEntity = AuditEntity & {
  __typename?: 'saleLoanCancellationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  saleId: Scalars['String'];
  cancelDate: Scalars['DateTime'];
  cancelReason: Scalars['String'];
  cancelRemark?: Maybe<Scalars['String']>;
  approvalStatus: Scalars['String'];
  commonStatus: Scalars['String'];
};

export type SaleLoanEntity = AuditEntity & {
  __typename?: 'saleLoanEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  saleId: Scalars['String'];
  financierId: Scalars['String'];
  financierReference: Scalars['String'];
  solicitorId: Scalars['String'];
  solicitorReference: Scalars['String'];
  borrowerName: Scalars['String'];
  borrowerAddress: AddressJson;
  loanLooDate?: Maybe<Scalars['DateTime']>;
  loanPeriod?: Maybe<Scalars['Float']>;
  loanAmount: Scalars['Float'];
  loanPercentage: Scalars['Float'];
  gstFinanceByBank: Scalars['Boolean'];
  taxSchemeId: Scalars['String'];
  totalLoanAmount: Scalars['Float'];
  loanSeqno: Scalars['Float'];
  loanLimit: Scalars['Float'];
  remark?: Maybe<Scalars['String']>;
  renoticeDate?: Maybe<Scalars['DateTime']>;
  cancelReason?: Maybe<Scalars['String']>;
  cancelDate?: Maybe<Scalars['DateTime']>;
  rrDate?: Maybe<Scalars['DateTime']>;
  rrSolicitorId?: Maybe<Scalars['String']>;
  rrSolicitorReferenceNo?: Maybe<Scalars['String']>;
  loanApprovalDate?: Maybe<Scalars['DateTime']>;
  loanSignDate?: Maybe<Scalars['DateTime']>;
  commonStatus: Scalars['String'];
  sale?: Maybe<SaleEntity>;
  financiers?: Maybe<Array<FinancierEntity>>;
  solicitorAppointed?: Maybe<Array<SolicitorAppointedEntity>>;
  financialBilling?: Maybe<Array<FinancialBillingEntity>>;
  generationBatchDate?: Maybe<Scalars['DateTime']>;
  generationBatch?: Maybe<Scalars['String']>;
};

export type SalePackageEntity = AuditEntity & {
  __typename?: 'salePackageEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  saleId: Scalars['String'];
  packageId: Scalars['String'];
  openMarketValue: Scalars['Float'];
  sellingPrice: Scalars['Float'];
  taxSchemeId: Scalars['String'];
  workProgressId: Scalars['String'];
  discountId?: Maybe<Scalars['String']>;
  discountAmount?: Maybe<Scalars['Float']>;
  isIncludeContract: Scalars['Boolean'];
  sellingMethod?: Maybe<Scalars['String']>;
  paymentPlanId?: Maybe<Scalars['String']>;
  package: PackageEntity;
  workProgress: WorkProgressEntity;
  commonStatus: Scalars['String'];
};

export type SalePaymentConfirmResultOutput = {
  __typename?: 'SalePaymentConfirmResultOutput';
  amount: Scalars['String'];
  refNo?: Maybe<Scalars['String']>;
  companyName: Scalars['String'];
  address: AddressJson;
  officeTel?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  unitNo: Scalars['String'];
  unitId?: Maybe<Scalars['String']>;
  devName: Scalars['String'];
  resultUrl: Scalars['String'];
  paymentId: Scalars['String'];
  refundPolicyConsentRequired?: Maybe<Scalars['String']>;
  paymentGatewayRefundPolicy?: Maybe<Scalars['String']>;
};

export type SalePaymentEntity = {
  __typename?: 'salePaymentEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  saleId: Scalars['String'];
  paymentGatewayId?: Maybe<Scalars['String']>;
  receiptNo?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  status: Scalars['String'];
  transactionDate?: Maybe<Scalars['DateTime']>;
  transactionMethod?: Maybe<Scalars['String']>;
  refNo?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  response: Scalars['JSONObject'];
  sale: SaleEntity;
  attachment?: Maybe<AttachmentEntity>;
  bookingFeeAttachments: Array<AttachmentEntity>;
  paymentMethodDesc?: Maybe<Scalars['String']>;
};

export type SalePaymentValueOutput = {
  __typename?: 'SalePaymentValueOutput';
  amount: Scalars['Float'];
  receiptNo: Scalars['String'];
};

export type SaleRebateEntity = {
  __typename?: 'saleRebateEntity';
  id: Scalars['String'];
  saleId: Scalars['String'];
  discountId: Scalars['String'];
  discount: DiscountEntity;
  sale: SaleEntity;
  amount: Scalars['Float'];
  seqNo: Scalars['Float'];
  saleDiscountExtension?: Maybe<SalesDiscountExtEntity>;
};

export type SaleRebatePackageEntity = AuditEntity & {
  __typename?: 'saleRebatePackageEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  saleId: Scalars['String'];
  discountId: Scalars['String'];
  discountMethod: Scalars['String'];
  roundingMode: Scalars['String'];
  roundingPrecision: Scalars['String'];
  campaignId: Scalars['String'];
  workProgressId: Scalars['String'];
  discountAmount: Scalars['Float'];
  rebateId?: Maybe<Scalars['String']>;
  seqNo?: Maybe<Scalars['Float']>;
  workProgress: WorkProgressEntity;
  discount: DiscountEntity;
  campaign: CampaignEntity;
  rebate?: Maybe<RebateEntity>;
  sales?: Maybe<SaleEntity>;
};

export type SalesActivityEntity = {
  __typename?: 'salesActivityEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  saleId: Scalars['String'];
  projectId: Scalars['String'];
  fromTeam?: Maybe<Scalars['String']>;
  team?: Maybe<Scalars['String']>;
  fromPerson?: Maybe<Scalars['String']>;
  person?: Maybe<Scalars['String']>;
  activityDate: Scalars['DateTime'];
  activityType: Scalars['String'];
  salesPersonType: Scalars['String'];
  actionBy?: Maybe<Scalars['String']>;
  activityDesc?: Maybe<Scalars['String']>;
};

export type SalesAgingDetails = {
  __typename?: 'SalesAgingDetails';
  alertAgingActivated?: Maybe<Scalars['Boolean']>;
  salesAlertAging?: Maybe<SalesAlertAginggOutput>;
};

export type SalesAlertAginggOutput = {
  __typename?: 'SalesAlertAginggOutput';
  isActive: Scalars['Boolean'];
  maxMinorSalesAlertAging: Scalars['Float'];
  maxMajorSalesAlertAging: Scalars['Float'];
};

export type SalesAlertAgingInput = {
  isActive: Scalars['Boolean'];
  maxMinorSalesAlertAging: Scalars['Float'];
  maxMajorSalesAlertAging: Scalars['Float'];
};

export type SalesAlertAgingOutput = {
  __typename?: 'SalesAlertAgingOutput';
  isActive: Scalars['Boolean'];
  maxMinorSalesAlertAging: Scalars['Float'];
  maxMajorSalesAlertAging: Scalars['Float'];
};

export type SalesCancellationEntity = AuditEntity & {
  __typename?: 'salesCancellationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  saleId: Scalars['String'];
  notifiedDate: Scalars['DateTime'];
  approvalLevel?: Maybe<Scalars['Float']>;
  status: Scalars['String'];
};

export type SalesChart3DUnitOutput = {
  __typename?: 'SalesChart3DUnitOutput';
  unit_id: Scalars['String'];
  unit_status: Scalars['String'];
  unit_type_id: Scalars['String'];
  coordinates: Scalars['String'];
  price: Scalars['Float'];
  time_lock?: Maybe<Scalars['DateTime']>;
  user_id?: Maybe<Scalars['String']>;
  common_status: Scalars['String'];
};

export type SalesChartEntity = {
  __typename?: 'salesChartEntity';
  id: Scalars['String'];
  block: Scalars['String'];
  floor: Scalars['Float'];
  coordinates: Scalars['String'];
  project: ProjectEntity;
  resource: ResourceEntity;
  unit: UnitEntity;
};

export type SalesChartFloorOutput = {
  __typename?: 'SalesChartFloorOutput';
  floor: Scalars['Float'];
  floor_label?: Maybe<Scalars['String']>;
};

export type SalesChartFloorUnitOutput = {
  __typename?: 'SalesChartFloorUnitOutput';
  floors: Array<SalesChartFloorOutput>;
  units: Array<SalesChartUnitOutput>;
};

export type SalesChartOutput = {
  __typename?: 'SalesChartOutput';
  unit_id: Scalars['String'];
  common_status?: Maybe<Scalars['String']>;
  unit_status?: Maybe<Scalars['String']>;
  coordinates?: Maybe<Scalars['String']>;
  unit_type?: Maybe<UnitTypeEntity>;
  price: Scalars['Float'];
  time_lock?: Maybe<Scalars['DateTime']>;
  user_id?: Maybe<Scalars['String']>;
};

export type SalesChartUnitOutput = {
  __typename?: 'SalesChartUnitOutput';
  unit_id: Scalars['String'];
  unit_no: Scalars['String'];
  unit_status: Scalars['String'];
  unit_type?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  floor: Scalars['Float'];
  floor_label: Scalars['String'];
  cell: Scalars['Float'];
  status?: Maybe<Scalars['String']>;
  common_status?: Maybe<Scalars['String']>;
};

export type SalesDiscountExtEntity = AuditEntity & {
  __typename?: 'salesDiscountExtEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  saleDiscountId: Scalars['String'];
  formula: Scalars['String'];
  discountMethod: Scalars['String'];
  saleDiscount: SaleDiscountEntity;
};

export type SalesDocEntity = AuditEntity & {
  __typename?: 'salesDocEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  docName: Scalars['String'];
  type: Scalars['String'];
  allowSalesTeam: Scalars['Boolean'];
  allowAgency: Scalars['Boolean'];
  attachment: AttachmentEntity;
  typeDescription: Scalars['String'];
};

export type SalesGalleryCloseDateEntity = {
  __typename?: 'salesGalleryCloseDateEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  appointmentSchedulingId: Scalars['String'];
  appointmentScheduling: AppointmentSchedulingEntity;
};

export type SalesLeadOutput = {
  __typename?: 'SalesLeadOutput';
  total?: Maybe<Scalars['Float']>;
  openTotal?: Maybe<Scalars['Float']>;
  hotTotal?: Maybe<Scalars['Float']>;
  warmTotal?: Maybe<Scalars['Float']>;
  coldTotal?: Maybe<Scalars['Float']>;
  dropTotal?: Maybe<Scalars['Float']>;
  waitingListTotal?: Maybe<Scalars['Float']>;
  soldTotal?: Maybe<Scalars['Float']>;
  signTotal?: Maybe<Scalars['Float']>;
  data?: Maybe<Array<LeadDataOutput>>;
  showSaleStaff?: Maybe<Scalars['Boolean']>;
  reservedTotal?: Maybe<Scalars['Float']>;
  salesAgingDetails?: Maybe<SalesAgingDetails>;
};

export enum SalesPermission {
  ProjectSalesLeadRead = 'PROJECT_SALES_LEAD____READ',
  ProjectSalesLeadAssign = 'PROJECT_SALES_LEAD____ASSIGN',
  ProjectWaitingListRead = 'PROJECT_WAITING_LIST____READ',
  ProjectWaitingListMoveToTop = 'PROJECT_WAITING_LIST____MOVE_TO_TOP',
  ProjectWaitingListLeaveQueue = 'PROJECT_WAITING_LIST____LEAVE_QUEUE',
  ProjectBookingListRead = 'PROJECT_BOOKING_LIST____READ',
  ProjectBookingListAllowBook = 'PROJECT_BOOKING_LIST____ALLOW_BOOK',
  ProjectBookingListCancel = 'PROJECT_BOOKING_LIST____CANCEL',
  ProjectBookingListOnline = 'PROJECT_BOOKING_LIST____ONLINE',
  ProjectBookingListEditDiscount = 'PROJECT_BOOKING_LIST____EDIT_DISCOUNT',
  ProjectBookingListEditSalesPackages = 'PROJECT_BOOKING_LIST____EDIT_SALES_PACKAGES',
  ProjectBookingListEditSalesRebates = 'PROJECT_BOOKING_LIST____EDIT_SALES_REBATES',
  ProjectLoanRead = 'PROJECT_LOAN____READ',
  ProjectReadAll = 'PROJECT_______READ_ALL',
  ProjectReadTeam = 'PROJECT_______READ_TEAM',
  ProjectReadSelf = 'PROJECT_______READ_SELF',
  ProjectReadInternalAndAgencyTeam = 'PROJECT_______READ_INTERNAL_AND_AGENCY_TEAM',
  ProjectAppointedAgencyRead = 'PROJECT_APPOINTED_AGENCY____READ',
  ProjectBusinessInsightsRead = 'PROJECT_BUSINESS_INSIGHTS____READ',
  ProjectBusinessInsightsSalesPerformanceRead = 'PROJECT_BUSINESS_INSIGHTS_SALES_PERFORMANCE_READ',
  ProjectBusinessInsightsSalesTeamPerformanceRead = 'PROJECT_BUSINESS_INSIGHTS_SALES_TEAM_PERFORMANCE_READ',
  ProjectBusinessInsightsPurchaserDemographicRead = 'PROJECT_BUSINESS_INSIGHTS_PURCHASER_DEMOGRAPHIC_READ',
  ProjectBusinessInsightsCampaignPerformanceRead = 'PROJECT_BUSINESS_INSIGHTS_CAMPAIGN_PERFORMANCE_READ',
  ProjectBusinessInsightsLeadDemographicRead = 'PROJECT_BUSINESS_INSIGHTS_LEAD_DEMOGRAPHIC_READ',
  ProjectBusinessInsightsSalesLeadPerformanceRead = 'PROJECT_BUSINESS_INSIGHTS_SALES_LEAD_PERFORMANCE_READ',
  ProjectBusinessInsightsSalesLeadTeamPerformanceRead = 'PROJECT_BUSINESS_INSIGHTS_SALES_LEAD_TEAM_PERFORMANCE_READ',
  ProjectBusinessInsightsLeadSourceRead = 'PROJECT_BUSINESS_INSIGHTS_LEAD_SOURCE_READ',
  ProjectBusinessInsightsBookingListRead = 'PROJECT_BUSINESS_INSIGHTS_BOOKING_LIST_READ',
  ProjectBusinessInsightsWaitingListRead = 'PROJECT_BUSINESS_INSIGHTS_WAITING_LIST_READ',
  ProjectBusinessInsightsLoanRead = 'PROJECT_BUSINESS_INSIGHTS_LOAN_READ',
  ProjectBusinessInsightsSalesMovementRead = 'PROJECT_BUSINESS_INSIGHTS_SALES_MOVEMENT_READ',
  ProjectBusinessInsightsPaymentSourceRead = 'PROJECT_BUSINESS_INSIGHTS_PAYMENT_SOURCE_READ',
  ProjectBusinessInsightsBookingListAnalysisRead = 'PROJECT_BUSINESS_INSIGHTS_BOOKING_LIST_ANALYSIS_READ',
  ProjectBusinessInsightsELaunchPerformanceRead = 'PROJECT_BUSINESS_INSIGHTS_E_LAUNCH_PERFORMANCE_READ',
  ProjectBusinessInsightsELaunchPurchaserDemographicRead = 'PROJECT_BUSINESS_INSIGHTS_E_LAUNCH_PURCHASER_DEMOGRAPHIC_READ',
  ProjectBusinessInsightsELaunchBookingTimeTakenRead = 'PROJECT_BUSINESS_INSIGHTS_E_LAUNCH_BOOKING_TIME_TAKEN_READ',
  ProjectBusinessInsightsELaunchMostFavouriteRead = 'PROJECT_BUSINESS_INSIGHTS_E_LAUNCH_MOST_FAVOURITE_READ',
  ProjectBusinessActiveSalesPerformanceRead = 'PROJECT_BUSINESS_ACTIVE_SALES_PERFORMANCE_READ',
  ProjectDigitalDocumentsPurchaserBooking = 'PROJECT_DIGITAL_DOCUMENTS_PURCHASER_BOOKING',
  ProjectDigitalDocumentsPurchaserLoan = 'PROJECT_DIGITAL_DOCUMENTS_PURCHASER_LOAN',
  ProjectDigitalDocumentsPurchaserSales = 'PROJECT_DIGITAL_DOCUMENTS_PURCHASER_SALES',
  ProjectNotificationBookingListAllowBook = 'PROJECT_NOTIFICATION_BOOKING_LIST_ALLOW_BOOK',
  ProjectNotificationBookingListBookingFee = 'PROJECT_NOTIFICATION_BOOKING_LIST_BOOKING_FEE',
  ProjectNotificationBookingListSpaReminder = 'PROJECT_NOTIFICATION_BOOKING_LIST_SPA_REMINDER',
  ProjectNotificationBookingListCancel = 'PROJECT_NOTIFICATION_BOOKING_LIST_CANCEL',
  ProjectNotificationBookingListSpaSign = 'PROJECT_NOTIFICATION_BOOKING_LIST_SPA_SIGN',
  ProjectNotificationBookingListSpaStamped = 'PROJECT_NOTIFICATION_BOOKING_LIST_SPA_STAMPED',
  ProjectNotificationBookingListSpaCollected = 'PROJECT_NOTIFICATION_BOOKING_LIST_SPA_COLLECTED',
  ProjectNotificationSalesLeadAssignProejct = 'PROJECT_NOTIFICATION_SALES_LEAD_ASSIGN_PROEJCT',
  ProjectNotificationSalesLeadAssign = 'PROJECT_NOTIFICATION_SALES_LEAD_ASSIGN',
  ProjectNotificationSalesLeadReassign = 'PROJECT_NOTIFICATION_SALES_LEAD_REASSIGN',
  ProjectNotificationSalesLeadDrop = 'PROJECT_NOTIFICATION_SALES_LEAD_DROP',
  ProjectNotificationSalesLeadUnattended = 'PROJECT_NOTIFICATION_SALES_LEAD_UNATTENDED',
  ProjectNotificationSalesLeadAging = 'PROJECT_NOTIFICATION_SALES_LEAD_AGING',
  ProjectNotificationWaitingListLeadJoint = 'PROJECT_NOTIFICATION_WAITING_LIST_LEAD_JOINT',
  ProjectNotificationWaitingListAvailable = 'PROJECT_NOTIFICATION_WAITING_LIST_AVAILABLE',
  ProjectNotificationWaitingListUnavailable = 'PROJECT_NOTIFICATION_WAITING_LIST_UNAVAILABLE',
  ProjectNotificationWaitingListLeadLeaveQueue = 'PROJECT_NOTIFICATION_WAITING_LIST_LEAD_LEAVE_QUEUE',
  ProjectNotificationWaitingListMoveToTop = 'PROJECT_NOTIFICATION_WAITING_LIST_MOVE_TO_TOP',
  ProjectNotificationCollaborativeAgecny = 'PROJECT_NOTIFICATION_COLLABORATIVE_AGECNY',
  ProjectNotificationCollaborativeEndFinancier = 'PROJECT_NOTIFICATION_COLLABORATIVE_END_FINANCIER',
  ProjectNotificationCollaborativeSolicitor = 'PROJECT_NOTIFICATION_COLLABORATIVE_SOLICITOR',
  ProjectNotificationLoanAssign = 'PROJECT_NOTIFICATION_LOAN_ASSIGN',
  ProjectNotificationLoanIssued = 'PROJECT_NOTIFICATION_LOAN_ISSUED',
  ProjectNotificationLoanAccepted = 'PROJECT_NOTIFICATION_LOAN_ACCEPTED',
  ProjectNotificationLoanWithdrawal = 'PROJECT_NOTIFICATION_LOAN_WITHDRAWAL',
  ProjectDigitalDocumentsPurchaserBookingCreate = 'PROJECT_DIGITAL_DOCUMENTS_PURCHASER_BOOKING_CREATE',
  ProjectDigitalDocumentsPurchaserBookingDelete = 'PROJECT_DIGITAL_DOCUMENTS_PURCHASER_BOOKING_DELETE',
  ProjectDigitalDocumentsPurchaserLoanCreate = 'PROJECT_DIGITAL_DOCUMENTS_PURCHASER_LOAN_CREATE',
  ProjectDigitalDocumentsPurchaserLoanDelete = 'PROJECT_DIGITAL_DOCUMENTS_PURCHASER_LOAN_DELETE',
  ProjectDigitalDocumentsPurchaserSalesCreate = 'PROJECT_DIGITAL_DOCUMENTS_PURCHASER_SALES_CREATE',
  ProjectDigitalDocumentsPurchaserSalesDelete = 'PROJECT_DIGITAL_DOCUMENTS_PURCHASER_SALES_DELETE',
  ProjectSolicitorAssignmentRead = 'PROJECT_SOLICITOR_ASSIGNMENT____READ',
  ProjectSolicitorAssignmentReassignment = 'PROJECT_SOLICITOR_ASSIGNMENT_REASSIGNMENT',
  ProjectSolicitorSpaSign = 'PROJECT_SOLICITOR_SPA_SIGN',
  AdminProjectProjectDetailsRead = 'ADMIN_PROJECT_PROJECT_DETAILS_READ',
  AdminProjectGalleryRead = 'ADMIN_PROJECT_GALLERY_READ',
  AdminProjectUnitSetupRead = 'ADMIN_PROJECT_UNIT_SETUP_READ',
  AdminProjectCarParkSetupRead = 'ADMIN_PROJECT_CAR_PARK_SETUP_READ',
  AdminProjectLayoutImagesRead = 'ADMIN_PROJECT_LAYOUT_IMAGES_READ',
  AdminProjectSalesChartSetupRead = 'ADMIN_PROJECT_SALES_CHART_SETUP_READ',
  AdminProjectSalesDocumentsRead = 'ADMIN_PROJECT_SALES_DOCUMENTS_READ',
  AdminProjectUnitAllocationRead = 'ADMIN_PROJECT_UNIT_ALLOCATION_READ',
  AdminProjectSiteProgressRead = 'ADMIN_PROJECT_SITE_PROGRESS_READ',
  AdminProjectRoleAuthorizationRead = 'ADMIN_PROJECT_ROLE_AUTHORIZATION_READ',
  AdminProjectStandardLetterRead = 'ADMIN_PROJECT_STANDARD_LETTER_READ',
  AdminProjectTeamAssignmentRead = 'ADMIN_PROJECT_TEAM_ASSIGNMENT_READ',
  AdminProjectSalesPackagesRead = 'ADMIN_PROJECT_SALES_PACKAGES_READ',
  AdminProjectDiscountRead = 'ADMIN_PROJECT_DISCOUNT_READ',
  AdminProjectPanelEndFinancierRead = 'ADMIN_PROJECT_PANEL_END_FINANCIER_READ',
  AdminProjectPanelSolicitorRead = 'ADMIN_PROJECT_PANEL_SOLICITOR_READ',
  AdminProjectPolicySettingRead = 'ADMIN_PROJECT_POLICY_SETTING_READ',
  AdminRead = 'ADMIN__READ',
  WorkdeskProjectWorkdeskAppointmentAppointmentAdd = 'WORKDESK_PROJECT_WORKDESK_APPOINTMENT_APPOINTMENT_ADD',
  ProjectNotificationLoanAmountUpdate = 'PROJECT_NOTIFICATION_LOAN_AMOUNT_UPDATE',
  AdminProjectEmailLetterRead = 'ADMIN_PROJECT_EMAIL_LETTER_READ',
  ProjectBookingListEditPurchaserAndJointBuyer = 'PROJECT_BOOKING_LIST____EDIT_PURCHASER_AND_JOINT_BUYER',
  ProjectBookingListEditPurchaserAndJointBuyerSequence = 'PROJECT_BOOKING_LIST____EDIT_PURCHASER_AND_JOINT_BUYER_SEQUENCE',
  ProjectBookingListEditSalesTeamAndSalesPersonnel = 'PROJECT_BOOKING_LIST____EDIT_SALES_TEAM_AND_SALES_PERSONNEL',
  ProjectBookingListEditPurchaserAndJointBuyerNameAndIc = 'PROJECT_BOOKING_LIST____EDIT_PURCHASER_AND_JOINT_BUYER_NAME_AND_IC',
  ProjectBookingListEditPurchaserAndJointBuyerCompanyRegNo = 'PROJECT_BOOKING_LIST____EDIT_PURCHASER_AND_JOINT_BUYER_COMPANY_REG_NO',
  ProjectBookingListEditPurchaserAndJointBuyerIdType = 'PROJECT_BOOKING_LIST____EDIT_PURCHASER_AND_JOINT_BUYER_ID_TYPE',
  ProjectBookingListEditPurchaserAndJointBuyerTinNoAndSstRegNo = 'PROJECT_BOOKING_LIST____EDIT_PURCHASER_AND_JOINT_BUYER_TIN_NO_AND_SST_REG_NO',
  ProjectBookingListEditPurchaserAndJointBuyerNationality = 'PROJECT_BOOKING_LIST____EDIT_PURCHASER_AND_JOINT_BUYER_NATIONALITY'
}

export type SalesStatisticEntity = AuditEntity & {
  __typename?: 'salesStatisticEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  saleId: Scalars['String'];
  contactId: Scalars['String'];
  unitId: Scalars['String'];
  listPrice: Scalars['Float'];
  spaPrice?: Maybe<Scalars['Float']>;
  transactionDate: Scalars['DateTime'];
  saleStatus: Scalars['String'];
};

export type SalesTeamOutput = {
  __typename?: 'SalesTeamOutput';
  id: Scalars['String'];
  name: Scalars['String'];
  users: Array<SalesTeamUserOuput>;
  commonStatus?: Maybe<Scalars['String']>;
};

export type SalesTeamUserInfoOuput = {
  __typename?: 'SalesTeamUserInfoOuput';
  ID: Scalars['String'];
  name: Scalars['String'];
  contactNo: Scalars['String'];
  email: Scalars['String'];
};

export type SalesTeamUserOuput = {
  __typename?: 'SalesTeamUserOuput';
  id: Scalars['String'];
  name: Scalars['String'];
  teamId: Scalars['String'];
  commonStatus: Scalars['String'];
  leadCount?: Maybe<Scalars['Float']>;
};

export type SaleSubproductEntity = AuditEntity & {
  __typename?: 'saleSubproductEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  saleId: Scalars['String'];
  unitId: Scalars['String'];
  discountId?: Maybe<Scalars['String']>;
  discountAmount?: Maybe<Scalars['Float']>;
  sellingPrice: Scalars['Float'];
  sellingMethod: Scalars['String'];
  paymentPlanId?: Maybe<Scalars['String']>;
  unit?: Maybe<UnitEntity>;
  sales?: Maybe<SaleEntity>;
  discount?: Maybe<DiscountEntity>;
  progressBilling?: Maybe<Array<ProgressBillingEntity>>;
  commonStatus: Scalars['String'];
};

export type SaleTaxOutput = {
  __typename?: 'SaleTaxOutput';
  sale_id: Scalars['String'];
  tax_id: Scalars['String'];
  tax_code?: Maybe<Scalars['String']>;
  tax_desc?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  tax_rate: Scalars['Float'];
};

export type SaleTeamAllocationEntity = {
  __typename?: 'saleTeamAllocationEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  teamId: Scalars['String'];
  unitId: Scalars['String'];
  unit: UnitEntity;
  saleTeam: SaleTeamEntity;
  project: ProjectEntity;
};

export type SaleTeamEntity = {
  __typename?: 'saleTeamEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  name: Scalars['String'];
  enableAllocation: Scalars['Boolean'];
  commonStatus: Scalars['String'];
  leads: Array<LeadEntity>;
  leadActivities: Array<LeadActivityEntity>;
  leadActivitiesTeam: Array<LeadActivityEntity>;
  sales: Array<SaleEntity>;
  saleTeamUsers?: Maybe<Array<SaleTeamUserEntity>>;
  saleTeamUnitAllocation?: Maybe<Array<SaleTeamAllocationEntity>>;
  waitlistTeam?: Maybe<Array<BookingWaitlistEntity>>;
  saleTeamMembers?: Maybe<Array<SaleTeamUserEntity>>;
  memberCount?: Maybe<Scalars['Float']>;
};

export type SaleTeamMemberLeadAssignmentOutput = {
  __typename?: 'SaleTeamMemberLeadAssignmentOutput';
  teamId: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  leadCount?: Maybe<Scalars['Float']>;
};

export type SaleTeamUserEntity = {
  __typename?: 'saleTeamUserEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  teamId: Scalars['String'];
  userId: Scalars['String'];
  acceptLead: Scalars['Boolean'];
  saleTeam: SaleTeamEntity;
  user?: Maybe<UserEntity>;
};

export type SalutationEntity = {
  __typename?: 'salutationEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  name: Scalars['String'];
  seqNo?: Maybe<Scalars['Float']>;
  commonStatus: Scalars['String'];
  defaulted: Scalars['Boolean'];
  contacts: Array<ContactEntity>;
  saleContact: SaleContactEntity;
};

export type SaveBookingOutput = {
  __typename?: 'SaveBookingOutput';
  saleId: Scalars['String'];
  response?: Maybe<Scalars['String']>;
};

export type ScheduleEntity = {
  __typename?: 'scheduleEntity';
  id: Scalars['String'];
  description: Scalars['String'];
  docAmount: Scalars['String'];
  scheduleDate: Scalars['DateTime'];
  billedDate?: Maybe<Scalars['DateTime']>;
  batchId?: Maybe<Scalars['String']>;
  ledgers: Array<LedgerEntity>;
  project: ProjectEntity;
  sale: SaleEntity;
};

export type SCityEntity = {
  __typename?: 'sCityEntity';
  id: Scalars['String'];
  name: Scalars['String'];
  commonStatus: Scalars['String'];
  sPostcode?: Maybe<Array<SPostcodeEntity>>;
  state: SStateEntity;
};

export type SCountryEntity = {
  __typename?: 'sCountryEntity';
  id: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  einvCode: Scalars['String'];
  commonStatus: Scalars['String'];
  sStates: Array<SStateEntity>;
};

export type SGatewayUpdates_A = AuditEntity & {
  __typename?: 'SGatewayUpdates_A';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  IntegrationType?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  md5?: Maybe<Scalars['String']>;
  hasSqlUpdate: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
  verifySql?: Maybe<Scalars['String']>;
  sql?: Maybe<Scalars['String']>;
  releaseNote?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
};

export type ShareInput = {
  sharedBy: Scalars['String'];
  sharedDateTime: Scalars['String'];
  sharedMethod?: Maybe<Scalars['String']>;
};

/** JsonType */
export type ShareObject = {
  __typename?: 'shareObject';
  sharedBy: Scalars['String'];
  sharedDateTime: Scalars['String'];
  sharedMethod?: Maybe<Scalars['String']>;
};

export type SiteProgressEntity = {
  __typename?: 'siteProgressEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  title: Scalars['String'];
  publishDate: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  attachment: Array<AttachmentEntity>;
};

export type SmtpInfoInput = {
  hostName: Scalars['String'];
  userName: Scalars['String'];
  password: Scalars['String'];
  maxRetry: Scalars['Float'];
  portNumber: Scalars['Float'];
  enableSSL?: Maybe<Scalars['Boolean']>;
  lastUpdatedDate?: Maybe<Scalars['DateTime']>;
  lastUpdateBy?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdTs?: Maybe<Scalars['DateTime']>;
  modBy?: Maybe<Scalars['String']>;
  modTs?: Maybe<Scalars['DateTime']>;
};

export type SmtpInfoOutput = {
  __typename?: 'smtpInfoOutput';
  hostName: Scalars['String'];
  userName: Scalars['String'];
  password: Scalars['String'];
  maxRetry: Scalars['Float'];
  portNumber: Scalars['Float'];
  enableSSL?: Maybe<Scalars['Boolean']>;
  lastUpdatedDate?: Maybe<Scalars['DateTime']>;
  lastUpdateBy?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdTs?: Maybe<Scalars['DateTime']>;
  modBy?: Maybe<Scalars['String']>;
  modTs?: Maybe<Scalars['DateTime']>;
};

export type SNationalityEntity = {
  __typename?: 'sNationalityEntity';
  id: Scalars['String'];
  name: Scalars['String'];
  commonStatus: Scalars['String'];
  contacts: Array<ContactEntity>;
  leadContacts: Array<LeadContactEntity>;
};

export type SnpSigningReminderInput = {
  salesUserReceive?: Maybe<Scalars['Boolean']>;
  agencyUserReceive?: Maybe<Scalars['Boolean']>;
  purchaserReceive?: Maybe<Scalars['Boolean']>;
  beforeDay?: Maybe<Scalars['Float']>;
  afterDay?: Maybe<Scalars['Float']>;
};

export type SnpSigningReminderOutput = {
  __typename?: 'SNPSigningReminderOutput';
  salesUserReceive?: Maybe<Scalars['Boolean']>;
  agencyUserReceive?: Maybe<Scalars['Boolean']>;
  purchaserReceive?: Maybe<Scalars['Boolean']>;
  beforeDay?: Maybe<Scalars['Float']>;
  afterDay?: Maybe<Scalars['Float']>;
};

export type SolicitorAlertAgingInput = {
  isActive: Scalars['Boolean'];
  maxMinorSolicitorAlertAging: Scalars['Float'];
  maxMajorSolicitorAlertAging: Scalars['Float'];
};

export type SolicitorAlertAgingOutput = {
  __typename?: 'SolicitorAlertAgingOutput';
  isActive: Scalars['Boolean'];
  maxMinorSolicitorAlertAging: Scalars['Float'];
  maxMajorSolicitorAlertAging: Scalars['Float'];
};

export type SolicitorAppointedContactEntity = AuditEntity & {
  __typename?: 'solicitorAppointedContactEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phoneNo?: Maybe<Scalars['String']>;
  solicitorAppointedId: Scalars['String'];
  solicitor: SolicitorAppointedEntity;
};

export type SolicitorAppointedCount = {
  __typename?: 'solicitorAppointedCount';
  totalAppointed?: Maybe<Scalars['Float']>;
  lastAppointedDate?: Maybe<Scalars['DateTime']>;
};

export type SolicitorAppointedEntity = AuditEntity & {
  __typename?: 'solicitorAppointedEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  solicitorId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  officeTel?: Maybe<Scalars['String']>;
  solicitorCode?: Maybe<Scalars['String']>;
  address?: Maybe<AddressJson>;
  contactName?: Maybe<Scalars['String']>;
  contactPhoneNo?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  invitationLink: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  isIntegrated: Scalars['Boolean'];
  commonStatus: Scalars['String'];
  saleBuyerSolicitor: Array<SaleEntity>;
  subsaleBuyerSolicitor: Array<SubsaleEntity>;
  saleDeveloperSolicitor: Array<SaleEntity>;
  saleStakeholder: Array<SaleEntity>;
  saleLoan: Array<SaleLoanEntity>;
  unitExt: Array<UnitExtensionEntity>;
  unitExtLawyer: Array<UnitExtensionEntity>;
  contact: Array<SolicitorAppointedContactEntity>;
  assignedProjectCount: Scalars['Float'];
  projectCount?: Maybe<Scalars['Float']>;
  unitCount?: Maybe<Scalars['Float']>;
  solicitorUnitCount?: Maybe<SolicitorUnitCount>;
  default?: Maybe<Scalars['Boolean']>;
};


export type SolicitorAppointedEntitySolicitorUnitCountArgs = {
  project_id: Scalars['String'];
};


export type SolicitorAppointedEntityDefaultArgs = {
  projectId: Scalars['String'];
};

export type SolicitorCaseAssignmentInput = {
  salesUserReceive?: Maybe<Scalars['Boolean']>;
  agencyUserReceive?: Maybe<Scalars['Boolean']>;
  solicitorUserReceive?: Maybe<Scalars['Boolean']>;
};

export type SolicitorCaseAssignmentOutput = {
  __typename?: 'solicitorCaseAssignmentOutput';
  salesUserReceive?: Maybe<Scalars['Boolean']>;
  agencyUserReceive?: Maybe<Scalars['Boolean']>;
  solicitorUserReceive?: Maybe<Scalars['Boolean']>;
};

export type SolicitorContactEntity = AuditEntity & {
  __typename?: 'solicitorContactEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  userId: Scalars['String'];
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phoneNo?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  user: SolicitorUserEntity;
  commonStatus?: Maybe<Scalars['String']>;
  isSuspendible: Scalars['Boolean'];
};

export type SolicitorEntity = AuditEntity & {
  __typename?: 'solicitorEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  address?: Maybe<AddressJson>;
  officeTel?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
};

export type SolicitorLayoutList = {
  __typename?: 'solicitorLayoutList';
  project_id: Scalars['String'];
  solicitor_project_id: Scalars['String'];
  layout_id: Scalars['String'];
  desc: Scalars['String'];
  allotted: Scalars['Float'];
  sold: Scalars['Float'];
  total_selling: Scalars['Float'];
};

export type SolicitorLeadNotificationInput = {
  leadAssignment?: Maybe<Scalars['Boolean']>;
};

export type SolicitorLeadNotificationOutput = {
  __typename?: 'SolicitorLeadNotificationOutput';
  leadAssignment?: Maybe<Scalars['Boolean']>;
};

export type SolicitorNotificationEntity = AuditEntity & {
  __typename?: 'solicitorNotificationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  permissionId: Scalars['Float'];
  subject: Scalars['String'];
  body: Scalars['String'];
  solicitorNotificationRecipient: Array<SolicitorNotificationRecipientEntity>;
};

export type SolicitorNotificationPolicyEntity = {
  __typename?: 'solicitorNotificationPolicyEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  permissionId: Scalars['Float'];
  userId: Scalars['String'];
  notificationDuration: Scalars['Float'];
};

export type SolicitorNotificationRecipientEntity = AuditEntity & {
  __typename?: 'solicitorNotificationRecipientEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  notificationId: Scalars['String'];
  recipientId: Scalars['String'];
  isRead: Scalars['Boolean'];
  solicitorNotification: SolicitorNotificationEntity;
};

export type SolicitorProgressLogEntity = AuditEntity & {
  __typename?: 'solicitorProgressLogEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  solicitorId?: Maybe<Scalars['String']>;
  saleId: Scalars['String'];
  spaStatus?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  progressDesc?: Maybe<Scalars['String']>;
  progressType: Scalars['String'];
  progressDate: Scalars['DateTime'];
  actionBy: Scalars['String'];
  sale: SaleEntity;
};

export type SolicitorProjectEntity = AuditEntity & {
  __typename?: 'solicitorProjectEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  solicitorId: Scalars['String'];
  projectId: Scalars['String'];
  defaulted: Scalars['Boolean'];
  project?: Maybe<ProjectEntity>;
};

export type SolicitorStatusCount = {
  __typename?: 'solicitorStatusCount';
  totalCount?: Maybe<Scalars['Float']>;
  toBeAssignedCount?: Maybe<Scalars['Float']>;
  assignedCount?: Maybe<Scalars['Float']>;
  bookedCount?: Maybe<Scalars['Float']>;
  snpSignedCount?: Maybe<Scalars['Float']>;
  reservationCount?: Maybe<Scalars['Float']>;
};

export type SolicitorUnitAllocationEntity = AuditEntity & {
  __typename?: 'solicitorUnitAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  solicitorId: Scalars['String'];
  projectId: Scalars['String'];
  unitId: Scalars['String'];
  unit: UnitEntity;
};

export type SolicitorUnitCount = {
  __typename?: 'solicitorUnitCount';
  sold?: Maybe<Scalars['Float']>;
  allocated?: Maybe<Scalars['Float']>;
};

export type SolicitorUserEntity = AuditEntity & {
  __typename?: 'solicitorUserEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  solicitorId: Scalars['String'];
  loginId: Scalars['String'];
  passwordHash?: Maybe<Scalars['String']>;
  activated: Scalars['Boolean'];
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  forgotPasswordLink?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
};

export type SpaReminderEntity = AuditEntity & {
  __typename?: 'spaReminderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  saleId: Scalars['String'];
  reminderDate: Scalars['DateTime'];
  reminderSeq: Scalars['Float'];
  generationBatch?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  share?: Maybe<Array<ShareObject>>;
  sales?: Maybe<SaleEntity>;
};

export type SPostcodeEntity = {
  __typename?: 'sPostcodeEntity';
  id: Scalars['String'];
  postcode: Scalars['String'];
  commonStatus: Scalars['String'];
  citys: SCityEntity;
};

export type SStateEntity = {
  __typename?: 'sStateEntity';
  id: Scalars['String'];
  name: Scalars['String'];
  einvCode: Scalars['String'];
  sCities?: Maybe<Array<SCityEntity>>;
  commonStatus: Scalars['String'];
  country: SCountryEntity;
};

export type StakeholderProcessEntity = {
  __typename?: 'stakeholderProcessEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  saleId: Scalars['String'];
  solicitorId: Scalars['String'];
  progressBillingId: Scalars['String'];
  financialBillingId?: Maybe<Scalars['String']>;
  collectionDate?: Maybe<Scalars['DateTime']>;
  collectionReferenceNo?: Maybe<Scalars['String']>;
  collectionBatch?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  durationFlag?: Maybe<Scalars['String']>;
  duration: Scalars['Float'];
  dueDate?: Maybe<Scalars['DateTime']>;
  releaseDate: Scalars['DateTime'];
  commonStatus: Scalars['String'];
  progressBilling?: Maybe<ProgressBillingEntity>;
  status: Scalars['String'];
  contraId?: Maybe<Scalars['String']>;
  isReversal: Scalars['Boolean'];
  voidBy?: Maybe<Scalars['String']>;
  voidDate?: Maybe<Scalars['DateTime']>;
};

export type StakeholderProcessReleaseDetailEntity = {
  __typename?: 'stakeholderProcessReleaseDetailEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  stakeholderProcessReleaseId: Scalars['String'];
  stakeholderProcessId: Scalars['String'];
  releaseAmount: Scalars['Float'];
  commonStatus: Scalars['String'];
};

export type StakeholderProcessReleaseEntity = {
  __typename?: 'stakeholderProcessReleaseEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  solicitorId: Scalars['String'];
  releaseDate: Scalars['DateTime'];
  releaseMethod: Scalars['String'];
  releaseReferenceNo?: Maybe<Scalars['String']>;
  releaseRemark?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  bankAccountId: Scalars['String'];
  status: Scalars['String'];
  glGenerationBatch?: Maybe<Scalars['String']>;
  glGenerated: Scalars['String'];
  glGenerationReversedBatch?: Maybe<Scalars['String']>;
  gl_generated_reversed: Scalars['String'];
  isReversal: Scalars['Boolean'];
  voidBy?: Maybe<Scalars['String']>;
  voidDate?: Maybe<Scalars['DateTime']>;
};

export type StandardAuditEntity = {
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
};

export type StandardCoreBaseEntity = AuditEntity & {
  __typename?: 'StandardCoreBaseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};

export type StandardLetterDataSourceEntity = {
  __typename?: 'standardLetterDataSourceEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  standardLetterId: Scalars['String'];
  name: Scalars['String'];
  dataSource: Array<DataSource>;
  queryFilterString: Array<QueryFilterString>;
  queryParameter: Array<QueryParameter>;
  reportParameter: Array<ReportParameter>;
  version: Scalars['Float'];
};

export type StandardLetterEntity = {
  __typename?: 'standardLetterEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  name: Scalars['String'];
  letterCategory: Scalars['String'];
  letterType: Scalars['String'];
  commonStatus: Scalars['String'];
  standardLetterParameter: Array<StandardLetterParameterEntity>;
  letterTemplate: Array<LetterTemplateEntity>;
  projectTemplate: Array<ProjectLetterTemplateEntity>;
  isAllowHtmlEditor: Scalars['Boolean'];
  isAllowReportDesignEditor: Scalars['Boolean'];
};

export type StandardLetterParameterEntity = {
  __typename?: 'standardLetterParameterEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  standardLetterId: Scalars['String'];
  displayName: Scalars['String'];
  columnName: Scalars['String'];
  commonStatus: Scalars['String'];
  standardLetter: StandardLetterEntity;
};

export type StateMasterDataOutput = {
  __typename?: 'StateMasterDataOutput';
  ID: Scalars['String'];
  name: Scalars['String'];
  countryID: Scalars['String'];
  commonStatus: Scalars['String'];
};

export type StaticData = {
  __typename?: 'StaticData';
  Value: Scalars['String'];
  Desc: Scalars['String'];
};

export type StaticDataInput = {
  Value: Scalars['String'];
  Desc: Scalars['String'];
};

export type SubsaleEntity = {
  __typename?: 'subsaleEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  unitId: Scalars['String'];
  subsaleCategory: Scalars['String'];
  rrDate: Scalars['DateTime'];
  spaDate: Scalars['DateTime'];
  sellingPrice: Scalars['Float'];
  doaDate: Scalars['DateTime'];
  buyerSolicitorId: Scalars['String'];
  buyerSolicitorReferenceNo: Scalars['String'];
  vendorSolicitorId: Scalars['String'];
  vendorSolicitorReferenceNo: Scalars['String'];
  administrationCharges?: Maybe<Scalars['Float']>;
  printingCharges?: Maybe<Scalars['Float']>;
  serviceChargeDeposit?: Maybe<Scalars['Float']>;
  dmcDate?: Maybe<Scalars['DateTime']>;
  contactId: Scalars['String'];
  completedDate?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
  buyerName: Scalars['String'];
  buyerIdentityNo: Scalars['String'];
  isCompany: Scalars['Boolean'];
  commonStatus: Scalars['String'];
  units?: Maybe<UnitEntity>;
  buyerSolicitor?: Maybe<SolicitorAppointedEntity>;
  subsaleJointBuyers?: Maybe<Array<SubsaleJointBuyerEntity>>;
  subsaleLoans?: Maybe<Array<SubsaleLoanEntity>>;
};

export type SubsaleJointBuyerEntity = {
  __typename?: 'subsaleJointBuyerEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  subsaleId: Scalars['String'];
  contactId: Scalars['String'];
  ranking: Scalars['Float'];
  commonStatus: Scalars['String'];
  subsales: SubsaleEntity;
};

export type SubsaleLoanEntity = {
  __typename?: 'subsaleLoanEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  subsaleId: Scalars['String'];
  financierId: Scalars['String'];
  financierReferenceNo: Scalars['String'];
  RRDate: Scalars['DateTime'];
  RRSolicitorId: Scalars['String'];
  RRSolicitorReferenceNo: Scalars['String'];
  DOADate: Scalars['DateTime'];
  DOASolicitorId: Scalars['String'];
  DOASolicitorReferenceNo: Scalars['String'];
  LoanSolicitorId: Scalars['String'];
  loanSolicitorReferenceNo: Scalars['String'];
  loanSeqNo: Scalars['Float'];
  commonStatus: Scalars['String'];
  subsales: SubsaleEntity;
};

export type SUserEntity = AuditEntity & {
  __typename?: 'sUserEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  userName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  contactNo: Scalars['String'];
  is2FA?: Maybe<Scalars['Boolean']>;
  commonStatus: CommonStatus;
  confirmed: Scalars['Boolean'];
  avatar?: Maybe<Scalars['String']>;
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  softwareCode: Scalars['String'];
  loginAttempt: Scalars['Float'];
  blockDuration: Scalars['Float'];
  blockCount: Scalars['Float'];
  loginTs?: Maybe<Scalars['DateTime']>;
  deviceIP?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
  userFeaturePermission: UserFeaturePermissionEntity;
  isActivated: Scalars['Boolean'];
  isSuspendible: Scalars['Boolean'];
  deviceToken?: Maybe<Scalars['String']>;
  lastPwdChangedDate?: Maybe<Scalars['DateTime']>;
  languageSet?: Maybe<Scalars['String']>;
  forgotPasswordLink?: Maybe<Scalars['String']>;
};

export type TaxLedgerEntity = {
  __typename?: 'taxLedgerEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  refTable: Scalars['String'];
  refId: Scalars['String'];
  taxSchemeId: Scalars['String'];
  taxId: Scalars['String'];
  taxSchemeDetailId?: Maybe<Scalars['String']>;
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  commonStatus: Scalars['String'];
  progressBilling?: Maybe<ProgressBillingEntity>;
};

export type TeamMember = {
  person_in_charge: Array<Scalars['String']>;
};

/** JsonType */
export type TeamMemberObject = {
  __typename?: 'teamMemberObject';
  person_in_charge: Array<Scalars['String']>;
};

export type TotpSecretTyes = {
  __typename?: 'TotpSecretTyes';
  is2FA?: Maybe<Scalars['Boolean']>;
  totpSecret?: Maybe<Scalars['String']>;
  dataURL?: Maybe<Scalars['String']>;
  otpURL?: Maybe<Scalars['String']>;
};

export type TownshipEntity = {
  __typename?: 'townshipEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  companyId: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  location: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  seqNo: Scalars['Float'];
  commonStatus: Scalars['String'];
  company: CompanyEntity;
  projectExt?: Maybe<Array<ProjectExtEntity>>;
};

export type TryToFixAConstantValueHere = {
  __typename?: 'TryToFixAConstantValueHere';
  amount: Scalars['Float'];
};

export type UnitBufferEntity = {
  __typename?: 'unitBufferEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  unitId: Scalars['String'];
  unit: UnitEntity;
  refId: Scalars['String'];
  refTable: Scalars['String'];
  commonStatus: Scalars['String'];
  timeBuffer: Scalars['DateTime'];
};

export type UnitEntity = {
  __typename?: 'unitEntity';
  id: Scalars['String'];
  unitNo: Scalars['String'];
  phase?: Maybe<Scalars['String']>;
  unitTypeId?: Maybe<Scalars['String']>;
  unitLayoutId?: Maybe<Scalars['String']>;
  unitLayoutDes?: Maybe<Scalars['String']>;
  facingDirectionId?: Maybe<Scalars['String']>;
  block?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['Float']>;
  floorLabel?: Maybe<Scalars['String']>;
  builtUp: Scalars['Float'];
  landArea?: Maybe<Scalars['String']>;
  extraLandArea?: Maybe<Scalars['Float']>;
  facingDirection?: Maybe<DirectionEntity>;
  facingView?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  timeLock?: Maybe<Scalars['DateTime']>;
  cell: Scalars['Float'];
  hsdNo?: Maybe<Scalars['String']>;
  ptdNo?: Maybe<Scalars['String']>;
  cccDate?: Maybe<Scalars['DateTime']>;
  isCccReady: Scalars['Boolean'];
  isBumi: Scalars['Boolean'];
  commonStatus: Scalars['String'];
  isSubProduct: Scalars['Boolean'];
  parentId?: Maybe<Scalars['String']>;
  unitStatus: Scalars['String'];
  unitLockBy?: Maybe<Scalars['String']>;
  bookingWaitlists?: Maybe<Array<BookingWaitlistEntity>>;
  leads: Array<LeadEntity>;
  sales: Array<SaleEntity>;
  salesCharts?: Maybe<SalesChartEntity>;
  projectId: Scalars['String'];
  project: ProjectEntity;
  unitLayout?: Maybe<UnitLayoutEntity>;
  unitType?: Maybe<UnitTypeEntity>;
  propertyType: PropertyTypeEntity;
  unitIntegration?: Maybe<UnitIntegrationEntity>;
  architectCertUnits?: Maybe<Array<ArchitectCertUnitEntity>>;
  subsaleUnit?: Maybe<SubsaleEntity>;
  unitExt?: Maybe<UnitExtensionEntity>;
  projectTitleUnit?: Maybe<ProjectTitleUnitEntity>;
  motTracking?: Maybe<Array<MotTrackingEntity>>;
  saleSubProduct?: Maybe<SaleSubproductEntity>;
  carParks?: Maybe<Array<UnitEntity>>;
  units?: Maybe<UnitEntity>;
  priceAdjustmentUnit?: Maybe<PriceAdjustmentUnitEntity>;
  cellLabel?: Maybe<Scalars['String']>;
  unitBuffer: Array<UnitBufferEntity>;
  unitLockType?: Maybe<Scalars['String']>;
  projectSpec: Array<ProjectSpecEntity>;
  waitingListCount?: Maybe<Scalars['Float']>;
  projectDetailsUnitLayout?: Maybe<UnitLayoutEntity>;
  floorLayoutUrl?: Maybe<Scalars['String']>;
  unitLayoutUrl?: Maybe<Scalars['String']>;
  unitLayoutVitualLink?: Maybe<Array<Scalars['String']>>;
  events: Array<EventsEntity>;
  leadSelection: Array<LeadEntity>;
  getIntegratedProjectCarParkNo?: Maybe<Scalars['String']>;
};

export type UnitExcelOutput = {
  __typename?: 'UnitExcelOutput';
  base64: Scalars['String'];
  projectname: Scalars['String'];
  isSubProduct: Scalars['Boolean'];
};

export type UnitExcelReturnOutput = {
  __typename?: 'UnitExcelReturnOutput';
  message: Scalars['String'];
  file_name: Scalars['String'];
  file?: Maybe<Scalars['String']>;
  isSubProduct?: Maybe<Scalars['Boolean']>;
};

export type UnitExtensionEntity = {
  __typename?: 'unitExtensionEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  unitId: Scalars['String'];
  stakeholderId?: Maybe<Scalars['String']>;
  lawyerId?: Maybe<Scalars['String']>;
  paymentPlanId?: Maybe<Scalars['String']>;
  vpLetterDate?: Maybe<Scalars['DateTime']>;
  vpBillingDate?: Maybe<Scalars['DateTime']>;
  dlpExpiryDate?: Maybe<Scalars['DateTime']>;
  builtUpAreaRate?: Maybe<Scalars['Float']>;
  landAreaRate?: Maybe<Scalars['Float']>;
  extraLandAreaRate?: Maybe<Scalars['Float']>;
  actualBuiltUpArea?: Maybe<Scalars['Float']>;
  actualLandArea?: Maybe<Scalars['Float']>;
  scheduleLaunchDate?: Maybe<Scalars['DateTime']>;
  unitAddress?: Maybe<AddressJson>;
  shareValue?: Maybe<Scalars['String']>;
  usableArea?: Maybe<Scalars['Float']>;
  lotPtNo?: Maybe<Scalars['String']>;
  hsdHsmNo?: Maybe<Scalars['String']>;
  masterGrantNo?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  subdivisionMukim?: Maybe<Scalars['String']>;
  leasePejakanNegeri?: Maybe<Scalars['String']>;
  noOfKey?: Maybe<Scalars['Float']>;
  noOfStorey?: Maybe<Scalars['Float']>;
  remark?: Maybe<Scalars['String']>;
  stampDutyWaiver?: Maybe<Scalars['Boolean']>;
  bumiReleaseDate?: Maybe<Scalars['DateTime']>;
  bumiReleaseReferenceNo?: Maybe<Scalars['String']>;
  dlp1Date?: Maybe<Scalars['DateTime']>;
  dlp2Date?: Maybe<Scalars['DateTime']>;
  premium?: Maybe<Scalars['String']>;
  commonStatus: Scalars['String'];
  units: UnitEntity;
  paymentPlan?: Maybe<PaymentPlanEntity>;
  stakeholder?: Maybe<SolicitorAppointedEntity>;
  lawyer?: Maybe<SolicitorAppointedEntity>;
};

export type UnitImportCountOutput = {
  __typename?: 'UnitImportCountOutput';
  unitIds: Array<Scalars['String']>;
  leadCount: Scalars['Float'];
  agencyUnitCount: Scalars['Float'];
  salesCount: Scalars['Float'];
  bookingCount: Scalars['Float'];
  assignCarparkCount: Scalars['Float'];
  saleSubproductCount: Scalars['Float'];
  architectCertCount: Scalars['Float'];
  elaunchCount: Scalars['Float'];
  priceAdjustmentCount: Scalars['Float'];
  projectTitleCount: Scalars['Float'];
  bridgingFinancierCount: Scalars['Float'];
  unitIntegrationCount: Scalars['Float'];
  elaunchFavouriteCount: Scalars['Float'];
  saleChartCount: Scalars['Float'];
  saleTeamAllocationCount: Scalars['Float'];
};

export type UnitImportOptionOutput = {
  __typename?: 'UnitImportOptionOutput';
  displayImportMethods: Scalars['Boolean'];
  overwrite: Scalars['Boolean'];
  append: Scalars['Boolean'];
  integrated: Scalars['Boolean'];
  modify: Scalars['Boolean'];
  emptyProject: Scalars['Boolean'];
  unitIds: Array<Scalars['String']>;
};

export type UnitIntegrationEntity = {
  __typename?: 'unitIntegrationEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  unitId: Scalars['String'];
  projectId: Scalars['String'];
  ifcaBlockId: Scalars['Float'];
  ifcaUnitId: Scalars['Float'];
  ifcaProjectId: Scalars['Float'];
  ifcaPhaseId: Scalars['Float'];
  ifcaCompanyId: Scalars['Float'];
  commonStatus: Scalars['String'];
  unit: UnitEntity;
  accountSpec?: Maybe<AccountSpecEntity>;
  projectIntegration?: Maybe<ProjectIntegrationEntity>;
  sale?: Maybe<SaleEntity>;
  waitingList?: Maybe<BookingWaitlistEntity>;
};

export type UnitLayoutEntity = {
  __typename?: 'unitLayoutEntity';
  id: Scalars['String'];
  projectId: Scalars['String'];
  desc: Scalars['String'];
  pictureUnitlayouts: Array<PictureUnitlayoutEntity>;
  units: Array<UnitEntity>;
  project: ProjectEntity;
  unitTourVirtualLinks: Array<UnitTourVirtualLinkEntity>;
};

export type UnitMortgageOutput = {
  __typename?: 'UnitMortgageOutput';
  unitId: Scalars['String'];
  unitPrice: Scalars['Float'];
  unitNo: Scalars['String'];
  financierList?: Maybe<Array<FinancierUnitMortgage>>;
};

export type UnitTourVirtualLinkEntity = {
  __typename?: 'unitTourVirtualLinkEntity';
  id: Scalars['String'];
  projectId: Scalars['String'];
  block: Scalars['String'];
  unitTypeId?: Maybe<Scalars['String']>;
  unitLayoutId?: Maybe<Scalars['String']>;
  virtualModelLink: Scalars['String'];
  seqNo: Scalars['Float'];
  unitLayout: UnitLayoutEntity;
  unitType: UnitTypeEntity;
};

export type UnitTourVirtualOutput = {
  __typename?: 'unitTourVirtualOutput';
  tourId: Scalars['String'];
  block: Scalars['String'];
  unitLayoutId?: Maybe<Scalars['String']>;
  unitTypeId?: Maybe<Scalars['String']>;
  unitLayoutName?: Maybe<Scalars['String']>;
  unitTypeName?: Maybe<Scalars['String']>;
  virtualModelLink: Scalars['String'];
};

export type UnitTransferEntity = AuditEntity & {
  __typename?: 'unitTransferEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  saleId: Scalars['String'];
  oldUnitId: Scalars['String'];
  newUnitId: Scalars['String'];
  newListPrice: Scalars['Float'];
  newSellingPrice: Scalars['Float'];
  transferDate: Scalars['DateTime'];
  paymentPlanId: Scalars['String'];
  paymentMethod: Scalars['String'];
  administrationCharges: Scalars['Float'];
  reason: Scalars['String'];
  remark: Scalars['String'];
  discount?: Maybe<DiscountObject>;
  status: Scalars['String'];
  oldSaleDetail?: Maybe<OldSaleDetail>;
  oldSellingPrice?: Maybe<Scalars['Float']>;
  commonStatus: Scalars['String'];
  taxSchemeId?: Maybe<Scalars['String']>;
};

export type UnitTypeEntity = {
  __typename?: 'unitTypeEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  name: Scalars['String'];
  commonStatus: Scalars['String'];
  defaulted: Scalars['Boolean'];
  seq_no?: Maybe<Scalars['Float']>;
  units: Array<UnitEntity>;
  unitTourVirtualLinks: Array<UnitTourVirtualLinkEntity>;
};

export type UnitTypeOutput = {
  __typename?: 'unitTypeOutput';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  builtUp?: Maybe<Scalars['Float']>;
};

export type UnitViewLogEntity = {
  __typename?: 'unitViewLogEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  unitId: Scalars['String'];
  userId: Scalars['String'];
  projectId: Scalars['String'];
  block: Scalars['String'];
  softwareCode: Scalars['String'];
  visitTime: Scalars['DateTime'];
  leftTime?: Maybe<Scalars['DateTime']>;
  socketId?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
};

export type UpdateAuditInput = {
  modBy?: Maybe<Scalars['String']>;
};


export type UploadFileInput = {
  file_name: Scalars['String'];
  content_type: Scalars['String'];
  identity_type: Scalars['String'];
  base64: Scalars['String'];
};

export type UploadNricPassportInput = {
  sale_id: Scalars['String'];
  files: Array<UploadFileInput>;
};

export type UserChangePasswordInput = {
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
  confirmPassword: Scalars['String'];
};

export type UserContactInput = {
  name: Scalars['String'];
  email: Scalars['String'];
  phoneNo: Scalars['String'];
  avatar?: Maybe<Scalars['Upload']>;
  languageSet: Scalars['String'];
};

export type UserEntity = AuditEntity & {
  __typename?: 'UserEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  userName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  contactNo: Scalars['String'];
  is2FA?: Maybe<Scalars['Boolean']>;
  commonStatus: CommonStatus;
  confirmed: Scalars['Boolean'];
  avatar?: Maybe<Scalars['String']>;
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  softwareCode: Scalars['String'];
  loginAttempt: Scalars['Float'];
  blockDuration: Scalars['Float'];
  blockCount: Scalars['Float'];
  loginTs?: Maybe<Scalars['DateTime']>;
  deviceIP?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
  userFeaturePermission: UserFeaturePermissionEntity;
  isActivated: Scalars['Boolean'];
  isSuspendible: Scalars['Boolean'];
};

export type UserFeaturePermissionEntity = AuditEntity & {
  __typename?: 'userFeaturePermissionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  userId: Scalars['String'];
  allowOpenlead: Scalars['Boolean'];
  allowPropertyAgency: Scalars['Boolean'];
  allowGlobalBI: Scalars['Boolean'];
  notificationHistory: Scalars['Float'];
  allowRoundRobin: Scalars['Boolean'];
  allowOpenLeadRoundRobin: Scalars['Boolean'];
  allowReporting: Scalars['Boolean'];
  isIfcaUse: Scalars['Boolean'];
  notificationEnabled: Scalars['Boolean'];
  access_security: Scalars['Boolean'];
  company: Scalars['Boolean'];
  campaign: Scalars['Boolean'];
  discount: Scalars['Boolean'];
  end_financier: Scalars['Boolean'];
  solicitor: Scalars['Boolean'];
  bulletin: Scalars['Boolean'];
  general_setting: Scalars['Boolean'];
};

export type UserInput = {
  ID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  contactNo: Scalars['String'];
  email: Scalars['String'];
  hashed?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  superUser?: Maybe<Scalars['Boolean']>;
  department?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  commonStatus?: Maybe<CommonStatus>;
};

export type UserNotificationSettingEntity = {
  __typename?: 'userNotificationSettingEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  userId: Scalars['String'];
  permissionId: Scalars['Float'];
};

export type UserOutput = {
  __typename?: 'UserOutput';
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phoneNo?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
};

export type UserResponse = AuditEntity & {
  __typename?: 'UserResponse';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  userName: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  confirmed: Scalars['Boolean'];
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  commonStatus: CommonStatus;
  password?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
  deviceIP?: Maybe<Scalars['String']>;
  loginTs?: Maybe<Scalars['DateTime']>;
  blockDuration?: Maybe<Scalars['Float']>;
  blockCount?: Maybe<Scalars['Float']>;
  loginAttempt?: Maybe<Scalars['Float']>;
  is2FA?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Address>;
  errors?: Maybe<Array<FieldError>>;
};

export type VerifyUrlValidity = {
  __typename?: 'VerifyURLValidity';
  title: Scalars['String'];
  loginLogoUrl?: Maybe<Scalars['String']>;
};

export type WithdrawnLoanStatusOutput = {
  __typename?: 'WithdrawnLoanStatusOutput';
  count: Scalars['Float'];
  developerCancelledCount: Scalars['Float'];
  customerRejectedCount: Scalars['Float'];
  loanRejectedCount: Scalars['Float'];
  customerCancelledCount: Scalars['Float'];
};

export type WorkdeskNotificationEntity = AuditEntity & {
  __typename?: 'workdeskNotificationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  permissionId: Scalars['Float'];
  subject: Scalars['String'];
  body: Scalars['String'];
  wdNotificationRecipient: Array<WorkdeskNotificationRecipientEntity>;
};

export type WorkdeskNotificationRecipientEntity = AuditEntity & {
  __typename?: 'workdeskNotificationRecipientEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  notificationId: Scalars['String'];
  recipientId: Scalars['String'];
  isRead: Scalars['Boolean'];
  workdeskNotification: WorkdeskNotificationEntity;
};

export type WorkFlowApprovedStepEntity = AuditEntity & {
  __typename?: 'workFlowApprovedStepEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  workFlowStepId: Scalars['String'];
  approvalRoleId: Scalars['String'];
  approverId: Scalars['String'];
  approvalDate: Scalars['DateTime'];
  notifiedDate: Scalars['DateTime'];
  statusRemark?: Maybe<Scalars['String']>;
  approvalStatus: Scalars['String'];
  commonStatus: Scalars['String'];
};

export type WorkFlowEntity = AuditEntity & {
  __typename?: 'workFlowEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  approvalPolicyAssignmentId: Scalars['String'];
  entityId: Scalars['String'];
  refId: Scalars['String'];
  refTable: Scalars['String'];
  submitterId: Scalars['String'];
  submitDate: Scalars['DateTime'];
  workFlowStatus: Scalars['String'];
  commonStatus: Scalars['String'];
};

export type WorkFlowStepEntity = AuditEntity & {
  __typename?: 'workFlowStepEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  accountId: Scalars['String'];
  workFlowId: Scalars['String'];
  approvalRoleId: Scalars['String'];
  notifiedDate: Scalars['DateTime'];
  stepNo: Scalars['Float'];
  statusRemark?: Maybe<Scalars['String']>;
  approvalStatus: Scalars['String'];
  commonStatus: Scalars['String'];
};

export type WorkProgressEntity = {
  __typename?: 'workProgressEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  name: Scalars['String'];
  systemUse: Scalars['Boolean'];
  isVp: Scalars['Boolean'];
  isCarPark: Scalars['Boolean'];
  commonStatus: Scalars['String'];
  paymentPlanDetail: Array<PaymentPlanDetailEntity>;
  saleRebatePackage?: Maybe<Array<SaleRebatePackageEntity>>;
  salePackage?: Maybe<Array<SalePackageEntity>>;
  archCert: Array<ArchitectCertWorkProgressEntity>;
};

export type GetProjectHeaderQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProjectHeaderQuery = (
  { __typename?: 'Query' }
  & { getProject?: Maybe<(
    { __typename?: 'projectEntity' }
    & Pick<ProjectEntity, 'id' | 'city' | 'state' | 'name'>
    & { firstGalleryImage?: Maybe<(
      { __typename?: 'ProjectResourceOuput' }
      & Pick<ProjectResourceOuput, 'url'>
    )>, developmentCompany: (
      { __typename?: 'companyEntity' }
      & Pick<CompanyEntity, 'name' | 'companyRegNo' | 'imageUrl' | 'officeTel' | 'email' | 'isEinvIntegrated'>
      & { address?: Maybe<(
        { __typename?: 'AddressJson' }
        & Pick<AddressJson, 'address'>
      )> }
    ), holdingCompany: (
      { __typename?: 'companyEntity' }
      & Pick<CompanyEntity, 'name' | 'companyRegNo' | 'imageUrl' | 'officeTel' | 'email'>
      & { address?: Maybe<(
        { __typename?: 'AddressJson' }
        & Pick<AddressJson, 'address'>
      )> }
    ) }
  )> }
);

export type GetProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProfileQuery = (
  { __typename?: 'Query' }
  & { profile: (
    { __typename?: 'eLaunchContactEntity' }
    & Pick<ELaunchContactEntity, 'name'>
    & { userLanguageChoose: (
      { __typename?: 'LanguageOutput' }
      & Pick<LanguageOutput, 'fileNames' | 'obj'>
    ) }
  ) }
);

export type GetPublicProjectHeaderQueryVariables = Exact<{
  accountCode: Scalars['String'];
  eLaunchCode: Scalars['String'];
}>;


export type GetPublicProjectHeaderQuery = (
  { __typename?: 'Query' }
  & { getPublicProject: (
    { __typename?: 'publicProjectOutput' }
    & Pick<PublicProjectOutput, 'city' | 'state' | 'name'>
    & { firstGalleryImage?: Maybe<(
      { __typename?: 'ProjectResourceOuput' }
      & Pick<ProjectResourceOuput, 'url'>
    )>, developmentCompany?: Maybe<(
      { __typename?: 'companyEntity' }
      & Pick<CompanyEntity, 'name' | 'companyRegNo' | 'imageUrl' | 'officeTel' | 'email'>
      & { address?: Maybe<(
        { __typename?: 'AddressJson' }
        & Pick<AddressJson, 'address'>
      )> }
    )>, holdingCompany?: Maybe<(
      { __typename?: 'companyEntity' }
      & Pick<CompanyEntity, 'name' | 'companyRegNo' | 'imageUrl' | 'officeTel' | 'email'>
      & { address?: Maybe<(
        { __typename?: 'AddressJson' }
        & Pick<AddressJson, 'address'>
      )> }
    )> }
  ) }
);

export type CheckUrlValidityQueryVariables = Exact<{
  accountCode: Scalars['String'];
  eLaunchCode: Scalars['String'];
}>;


export type CheckUrlValidityQuery = (
  { __typename?: 'Query' }
  & { checkURLValidity: (
    { __typename?: 'VerifyURLValidity' }
    & Pick<VerifyUrlValidity, 'title' | 'loginLogoUrl'>
  ) }
);

export type LoginQueryVariables = Exact<{
  accountCode: Scalars['String'];
  eLaunchCode: Scalars['String'];
  loginId: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginQuery = (
  { __typename?: 'Query' }
  & { login: (
    { __typename?: 'eLaunchUserEntity' }
    & Pick<ELaunchUserEntity, 'accessToken'>
  ) }
);

export type SendRegisterOtpQueryVariables = Exact<{
  accountCode: Scalars['String'];
  eLaunchCode: Scalars['String'];
  contactNo: Scalars['String'];
}>;


export type SendRegisterOtpQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'sendRegisterOTP'>
);

export type RegisterUserMutationVariables = Exact<{
  input: RegisterUserInput;
}>;


export type RegisterUserMutation = (
  { __typename?: 'Mutation' }
  & { registerUser: (
    { __typename?: 'eLaunchUserEntity' }
    & Pick<ELaunchUserEntity, 'accessToken'>
  ) }
);

export type VerifyOtpQueryVariables = Exact<{
  accountCode: Scalars['String'];
  contactNo: Scalars['String'];
  otpCode: Scalars['String'];
  otpType: Scalars['String'];
}>;


export type VerifyOtpQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'verifyOTP'>
);

export type LogoutQueryVariables = Exact<{ [key: string]: never; }>;


export type LogoutQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'logout'>
);

export type RefreshTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type RefreshTokenQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'refreshToken'>
);

export type SendForgetPasswordOtpQueryVariables = Exact<{
  accountCode: Scalars['String'];
  contactNo: Scalars['String'];
}>;


export type SendForgetPasswordOtpQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'sendForgetPasswordOTP'>
);

export type ResetPasswordMutationVariables = Exact<{
  input: ForgetPasswordInput;
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'forgotPassword'>
);

export type GetProjectQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProjectQuery = (
  { __typename?: 'Query' }
  & { getProject?: Maybe<(
    { __typename?: 'projectEntity' }
    & Pick<ProjectEntity, 'id' | 'currency' | 'name' | 'projectCode' | 'townshipName' | 'desc' | 'minPrice' | 'maxPrice' | 'completionDate' | 'launchDate' | 'unitMinPrice' | 'unitMaxPrice' | 'unitMinBuiltUp' | 'unitMaxBuiltUp' | 'uomDesc' | 'projectAddress' | 'state' | 'city' | 'projectGps' | 'saleGalleryAddress' | 'saleGalleryGps' | 'landTypeDesc' | 'tenureDesc' | 'priceRange'>
    & { amenities?: Maybe<Array<(
      { __typename?: 'Amenities' }
      & Pick<Amenities, 'category' | 'items'>
    )>>, embededYoutubeLink?: Maybe<Array<(
      { __typename?: 'ProjectYoutubeLinkOutput' }
      & Pick<ProjectYoutubeLinkOutput, 'videoType' | 'caption' | 'thumbnail' | 'url'>
    )>>, embededVimeoLink?: Maybe<Array<(
      { __typename?: 'ProjectYoutubeLinkOutput' }
      & Pick<ProjectYoutubeLinkOutput, 'videoType' | 'caption' | 'thumbnail' | 'url'>
    )>>, embededGoogleDriveLink?: Maybe<Array<(
      { __typename?: 'ProjectYoutubeLinkOutput' }
      & Pick<ProjectYoutubeLinkOutput, 'videoType' | 'caption' | 'thumbnail' | 'url'>
    )>>, virtualModelLink?: Maybe<Array<(
      { __typename?: 'MediaSource' }
      & Pick<MediaSource, 'url' | 'caption'>
    )>>, attachment?: Maybe<Array<(
      { __typename?: 'attachmentEntity' }
      & Pick<AttachmentEntity, 'fileName'>
    )>>, company?: Maybe<(
      { __typename?: 'companyEntity' }
      & Pick<CompanyEntity, 'id' | 'name' | 'companyRegNo' | 'description' | 'imageUrl' | 'isEinvIntegrated'>
    )>, projectType: (
      { __typename?: 'projectTypeEntity' }
      & Pick<ProjectTypeEntity, 'id' | 'name'>
    ), virtualModel?: Maybe<Array<(
      { __typename?: 'ProjectResourceOuput' }
      & Pick<ProjectResourceOuput, 'url' | 'caption'>
    )>>, floorGalleryImage?: Maybe<Array<(
      { __typename?: 'ProjectFloorMediaOuput' }
      & Pick<ProjectFloorMediaOuput, 'block' | 'floor' | 'url' | 'caption' | 'floorlabel'>
    )>>, units: Array<(
      { __typename?: 'unitEntity' }
      & Pick<UnitEntity, 'block' | 'floor' | 'floorLabel' | 'builtUp'>
      & { unitLayout?: Maybe<(
        { __typename?: 'unitLayoutEntity' }
        & Pick<UnitLayoutEntity, 'desc'>
      )> }
    )>, unitLayoutGalleryImage?: Maybe<Array<(
      { __typename?: 'ProjectUnitLayoutMediaOuput' }
      & Pick<ProjectUnitLayoutMediaOuput, 'block' | 'unitLayout' | 'resourceid' | 'url' | 'caption' | 'pictureid' | 'seqno' | 'unitcount' | 'noOfBedroom' | 'noOfBathroom'>
    )>>, galleryImage?: Maybe<Array<(
      { __typename?: 'ProjectGalleryMediaOuput' }
      & Pick<ProjectGalleryMediaOuput, 'url' | 'caption'>
    )>>, firstGalleryImage?: Maybe<(
      { __typename?: 'ProjectResourceOuput' }
      & Pick<ProjectResourceOuput, 'url'>
    )> }
  )> }
);

export type GetUnitDetailQueryVariables = Exact<{
  unitId: Scalars['String'];
}>;


export type GetUnitDetailQuery = (
  { __typename?: 'Query' }
  & { GetUnitDetail?: Maybe<(
    { __typename?: 'unitEntity' }
    & Pick<UnitEntity, 'id' | 'unitNo' | 'builtUp' | 'floorLayoutUrl' | 'price'>
    & { facingDirection?: Maybe<(
      { __typename?: 'directionEntity' }
      & Pick<DirectionEntity, 'name'>
    )>, unitLayout?: Maybe<(
      { __typename?: 'unitLayoutEntity' }
      & Pick<UnitLayoutEntity, 'desc'>
    )> }
  )> }
);

export type GetFavouritedBookedUnitListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFavouritedBookedUnitListQuery = (
  { __typename?: 'Query' }
  & { getFavouritedBookedUnitList?: Maybe<Array<(
    { __typename?: 'favouritedBookedUnitOutput' }
    & Pick<FavouritedBookedUnitOutput, 'unitId' | 'unitNo' | 'unitLayout' | 'builtUp' | 'price' | 'saleId' | 'isBookedByOther'>
  )>> }
);

export type RemoveFavouriteMutationVariables = Exact<{
  unitId: Scalars['String'];
}>;


export type RemoveFavouriteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeFavourite'>
);

export type GetELaunchQueryVariables = Exact<{ [key: string]: never; }>;


export type GetELaunchQuery = (
  { __typename?: 'Query' }
  & { getELaunch?: Maybe<(
    { __typename?: 'eLaunchEntity' }
    & Pick<ELaunchEntity, 'endDate' | 'startDate' | 'publishDate' | 'terminationDate' | 'name'>
  )> }
);

export type GetBookingDetailQueryVariables = Exact<{
  salesId: Scalars['String'];
}>;


export type GetBookingDetailQuery = (
  { __typename?: 'Query' }
  & { GetBookingDetail?: Maybe<(
    { __typename?: 'saleEntity' }
    & Pick<SaleEntity, 'id' | 'salesNo' | 'package' | 'salesPersonId' | 'salesPerson' | 'salesTeamId' | 'salesTeamName' | 'salesStatus' | 'agentPersonId' | 'agencyAcctId' | 'agencyContractId' | 'listPrice' | 'sellingPrice' | 'bookingRemark'>
    & { unit: (
      { __typename?: 'unitEntity' }
      & Pick<UnitEntity, 'builtUp' | 'unitNo' | 'floorLayoutUrl' | 'price'>
      & { facingDirection?: Maybe<(
        { __typename?: 'directionEntity' }
        & Pick<DirectionEntity, 'name'>
      )>, unitLayout?: Maybe<(
        { __typename?: 'unitLayoutEntity' }
        & Pick<UnitLayoutEntity, 'desc'>
      )> }
    ), discount: Array<(
      { __typename?: 'saleDiscountEntity' }
      & { discount: (
        { __typename?: 'discountEntity' }
        & Pick<DiscountEntity, 'title'>
      ) }
    )>, joinBuyer: Array<(
      { __typename?: 'jointBuyerEntity' }
      & Pick<JointBuyerEntity, 'isEInvoiceRequired' | 'isGovAuth' | 'id' | 'commonStatus'>
      & { contact: (
        { __typename?: 'contactEntity' }
        & Pick<ContactEntity, 'id' | 'fullName' | 'email' | 'phoneNo'>
      ) }
    )>, contact: (
      { __typename?: 'contactEntity' }
      & Pick<ContactEntity, 'id' | 'fullName' | 'email' | 'phoneNo' | 'icNo'>
      & { nationality?: Maybe<(
        { __typename?: 'sNationalityEntity' }
        & Pick<SNationalityEntity, 'name'>
      )> }
    ), bookingAttachments: (
      { __typename?: 'bookingAttachmentsOutput' }
      & { bookingCcrAttachments: Array<(
        { __typename?: 'attachmentEntity' }
        & Pick<AttachmentEntity, 'id' | 'accountId' | 'refTable' | 'refId' | 'refType' | 'mediaType' | 'resourceUrl' | 'fileName' | 'bucketFileName' | 'isPrivate' | 'seqNo' | 'allowDelete'>
      )>, bookingNricAttachments: Array<(
        { __typename?: 'attachmentEntity' }
        & Pick<AttachmentEntity, 'id' | 'accountId' | 'refTable' | 'refId' | 'refType' | 'mediaType' | 'resourceUrl' | 'fileName' | 'bucketFileName' | 'isPrivate' | 'seqNo' | 'allowDelete'>
      )>, bookingFeeAttachments: Array<(
        { __typename?: 'attachmentEntity' }
        & Pick<AttachmentEntity, 'id' | 'accountId' | 'refTable' | 'refId' | 'refType' | 'mediaType' | 'resourceUrl' | 'fileName' | 'bucketFileName' | 'isPrivate' | 'seqNo' | 'allowDelete'>
      )>, bookingPdpaAttachments: Array<(
        { __typename?: 'attachmentEntity' }
        & Pick<AttachmentEntity, 'id' | 'accountId' | 'refTable' | 'refId' | 'refType' | 'mediaType' | 'resourceUrl' | 'fileName' | 'bucketFileName' | 'isPrivate' | 'seqNo' | 'allowDelete'>
      )> }
    ) }
  )> }
);

export type GetBookingDetailDataQueryVariables = Exact<{
  saleId: Scalars['String'];
}>;


export type GetBookingDetailDataQuery = (
  { __typename?: 'Query' }
  & { GetBookingDetailData: (
    { __typename?: 'BookingDetailOutput' }
    & Pick<BookingDetailOutput, 'salesId' | 'salesNo' | 'salesStatus' | 'bookingRemark' | 'salesPersonId' | 'salesTeamId' | 'buyerName' | 'buyerId' | 'buyerNric' | 'buyerNationality' | 'buyerPhoneNo' | 'buyerEmail' | 'isCompany' | 'buyerCompanyName' | 'isjointBuyerComplete' | 'isPurchaserInfoComplete' | 'unitId' | 'unitNo' | 'unitLayout' | 'unitLayoutDes' | 'landArea' | 'extraLandArea' | 'builtUp' | 'sellingPrice' | 'listPrice' | 'personName' | 'teamName' | 'agencyName' | 'agencyUserName' | 'agentPersonId' | 'agencyAcctId' | 'agencyContractId' | 'isBumi' | 'isEInvoiceRequired' | 'isGovAuth' | 'identityType'>
    & { saleDiscount: Array<(
      { __typename?: 'saleDiscountEntity' }
      & { discount: (
        { __typename?: 'discountEntity' }
        & Pick<DiscountEntity, 'id' | 'title'>
      ) }
    )>, packageList: Array<(
      { __typename?: 'packageEntity' }
      & Pick<PackageEntity, 'id' | 'name' | 'desc'>
    )>, joinBuyer: Array<(
      { __typename?: 'jointBuyerEntity' }
      & { contact: (
        { __typename?: 'contactEntity' }
        & Pick<ContactEntity, 'fullName'>
      ) }
    )>, attachmentCount?: Maybe<(
      { __typename?: 'BookingListAttachmentOutput' }
      & Pick<BookingListAttachmentOutput, 'isAttachmentComplete' | 'requiredDocCount' | 'completedDocCount'>
    )> }
  ) }
);

export type DownloadAttachmentMutationVariables = Exact<{
  input: DownloadAttachmentInput;
}>;


export type DownloadAttachmentMutation = (
  { __typename?: 'Mutation' }
  & { downloadAttachment: (
    { __typename?: 'AttachmentOutput' }
    & Pick<AttachmentOutput, 'content' | 'fileName'>
  ) }
);

export type ELaunchDownloadAttachmentQueryVariables = Exact<{
  attachmentId: Scalars['String'];
}>;


export type ELaunchDownloadAttachmentQuery = (
  { __typename?: 'Query' }
  & { eLaunchDownloadAttachment: (
    { __typename?: 'eLaunchDownloadAttachmentOuput' }
    & Pick<ELaunchDownloadAttachmentOuput, 'fileName' | 'base64'>
  ) }
);

export type AddBookingAttachmentsMutationVariables = Exact<{
  input: ELaunchAddAttachmentsInput;
}>;


export type AddBookingAttachmentsMutation = (
  { __typename?: 'Mutation' }
  & { addBookingAttachments: Array<(
    { __typename?: 'attachmentEntity' }
    & Pick<AttachmentEntity, 'id' | 'accountId' | 'bucketFileName' | 'fileName' | 'isPrivate' | 'mediaType' | 'refId' | 'refTable' | 'refType' | 'resourceUrl' | 'seqNo'>
  )> }
);

export type UploadNricPassportMutationVariables = Exact<{
  input: UploadNricPassportInput;
}>;


export type UploadNricPassportMutation = (
  { __typename?: 'Mutation' }
  & { uploadNricPassport: Array<(
    { __typename?: 'attachmentEntity' }
    & Pick<AttachmentEntity, 'id' | 'accountId' | 'bucketFileName' | 'fileName' | 'isPrivate' | 'mediaType' | 'refId' | 'refTable' | 'refType' | 'resourceUrl' | 'seqNo'>
  )> }
);

export type GetWaterMarkTextQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWaterMarkTextQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetWaterMarkText'>
);

export type GetSpecPolicyDetailQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSpecPolicyDetailQuery = (
  { __typename?: 'Query' }
  & { GetAllMasterInfo: (
    { __typename?: 'MasterDataOutput' }
    & Pick<MasterDataOutput, 'PaymentMethods'>
    & { Nationalities: Array<(
      { __typename?: 'sNationalityEntity' }
      & Pick<SNationalityEntity, 'id' | 'name'>
    )> }
  ), getBookingFormData: (
    { __typename?: 'BookingFormDataOutput' }
    & { projectSpec?: Maybe<(
      { __typename?: 'ProjectSpecOutput' }
      & Pick<ProjectSpecOutput, 'bookingFeeMandatory' | 'bookingSignRequired' | 'bookingPDPAconsentRequired' | 'bookingRefundPolicyconsentRequired' | 'statementRefundPolicy' | 'bookingfeeAmountRequired' | 'minAge' | 'minAgeRequired' | 'minBookingfeeAmount' | 'paymentGatewayId'>
    )> }
  ), getPDPADocument?: Maybe<(
    { __typename?: 'PDPADetailByProject' }
    & Pick<PdpaDetailByProject, 'id' | 'name' | 'templateBody' | 'letterType'>
  )> }
);

export type GetEditJointBuyerQueryVariables = Exact<{
  contactId: Scalars['String'];
}>;


export type GetEditJointBuyerQuery = (
  { __typename?: 'Query' }
  & { GetJointBuyer: (
    { __typename?: 'contactEntity' }
    & Pick<ContactEntity, 'id' | 'fullName' | 'icNo' | 'email' | 'phoneNo'>
    & { nationality?: Maybe<(
      { __typename?: 'sNationalityEntity' }
      & Pick<SNationalityEntity, 'id' | 'name'>
    )>, address?: Maybe<(
      { __typename?: 'AddressJson' }
      & Pick<AddressJson, 'address' | 'state' | 'country' | 'city' | 'postCode'>
    )>, contactExt?: Maybe<(
      { __typename?: 'contactExtensionEntity' }
      & Pick<ContactExtensionEntity, 'id' | 'tin' | 'sstNo' | 'isForeignPurchase' | 'customerOrigin' | 'identityType' | 'isBumi'>
    )> }
  ) }
);

export type EditJointBuyerMutationVariables = Exact<{
  input: EditELaunchBookingJointBuyerInput;
}>;


export type EditJointBuyerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EditJointBuyer'>
);

export type DeactivateJointBuyerMutationVariables = Exact<{
  jointBuyerId: Scalars['String'];
}>;


export type DeactivateJointBuyerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deactivateJointBuyer'>
);

export type CancelBookingQueryVariables = Exact<{
  sale_id: Scalars['String'];
  reason: Scalars['String'];
  reason_id: Scalars['String'];
}>;


export type CancelBookingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CancelBooking'>
);

export type AddJointBuyerMutationVariables = Exact<{
  input: AddELaunchBookingJointBuyerInput;
}>;


export type AddJointBuyerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AddJointBuyer'>
);

export type EditPurchaserInfoMutationVariables = Exact<{
  input: EditPurchaserInfoInput;
}>;


export type EditPurchaserInfoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EditPurchaserInfo'>
);

export type GetEditProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEditProfileQuery = (
  { __typename?: 'Query' }
  & { profile: (
    { __typename?: 'eLaunchContactEntity' }
    & Pick<ELaunchContactEntity, 'userId' | 'name' | 'email' | 'phoneNo' | 'pictureUrl'>
    & { elaunchUser: (
      { __typename?: 'eLaunchUserEntity' }
      & Pick<ELaunchUserEntity, 'languageSet'>
    ), getLangMasterData: Array<(
      { __typename?: 'LanguageNamesOutput' }
      & Pick<LanguageNamesOutput, 'langCode' | 'langName'>
    )>, userLanguageChoose: (
      { __typename?: 'LanguageOutput' }
      & Pick<LanguageOutput, 'fileNames' | 'obj'>
    ) }
  ) }
);

export type UpdateProfileMutationVariables = Exact<{
  input: UserContactInput;
}>;


export type UpdateProfileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateProfile'>
);

export type ChangePasswordMutationVariables = Exact<{
  input: UserChangePasswordInput;
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changePassword'>
);

export type GetBrochureListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBrochureListQuery = (
  { __typename?: 'Query' }
  & { getBrochureList: Array<(
    { __typename?: 'salesDocEntity' }
    & Pick<SalesDocEntity, 'docName'>
    & { attachment: (
      { __typename?: 'attachmentEntity' }
      & Pick<AttachmentEntity, 'resourceUrl'>
    ) }
  )> }
);

export type GetSaleCancelReasonQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSaleCancelReasonQuery = (
  { __typename?: 'Query' }
  & { getSaleCancelReason: Array<(
    { __typename?: 'reasonCodeEntity' }
    & Pick<ReasonCodeEntity, 'id' | 'name' | 'defaulted'>
  )> }
);

export type GetProjectChartMappedQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProjectChartMappedQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getProjectChartMapped'>
);

export type GetProjectDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProjectDataQuery = (
  { __typename?: 'Query' }
  & { getProject?: Maybe<(
    { __typename?: 'projectEntity' }
    & Pick<ProjectEntity, 'distinctBlock' | 'saleschartBlock' | 'unitMaxPrice' | 'unitMinPrice'>
    & { unitTypes?: Maybe<Array<(
      { __typename?: 'unitTypeOutput' }
      & Pick<UnitTypeOutput, 'builtUp' | 'id' | 'name'>
    )>> }
  )> }
);

export type JointBuyerFormSourceQueryVariables = Exact<{ [key: string]: never; }>;


export type JointBuyerFormSourceQuery = (
  { __typename?: 'Query' }
  & { GetAllMasterInfo: (
    { __typename?: 'MasterDataOutput' }
    & { Nationalities: Array<(
      { __typename?: 'sNationalityEntity' }
      & Pick<SNationalityEntity, 'id' | 'name'>
    )> }
  ) }
);

export type GetSalesChartByBlockQueryVariables = Exact<{
  keySearch?: Maybe<Scalars['String']>;
  block: Scalars['String'];
}>;


export type GetSalesChartByBlockQuery = (
  { __typename?: 'Query' }
  & { GetSalesChartByBlock: (
    { __typename?: 'SalesChartFloorUnitOutput' }
    & { floors: Array<(
      { __typename?: 'SalesChartFloorOutput' }
      & Pick<SalesChartFloorOutput, 'floor' | 'floor_label'>
    )>, units: Array<(
      { __typename?: 'SalesChartUnitOutput' }
      & Pick<SalesChartUnitOutput, 'cell' | 'common_status' | 'floor' | 'floor_label' | 'price' | 'status' | 'unit_id' | 'unit_no' | 'unit_status' | 'unit_type'>
    )> }
  ) }
);

export type GetPublicProjectQueryVariables = Exact<{
  accountCode: Scalars['String'];
  eLaunchCode: Scalars['String'];
}>;


export type GetPublicProjectQuery = (
  { __typename?: 'Query' }
  & { getPublicProject: (
    { __typename?: 'publicProjectOutput' }
    & Pick<PublicProjectOutput, 'completionDate' | 'launchDate' | 'tenureDesc' | 'desc' | 'builtup' | 'unitMinBuiltUp' | 'unitMaxBuiltUp' | 'uomDesc' | 'minPrice' | 'maxPrice' | 'currency' | 'townshipName' | 'landTypeDesc' | 'projectGps' | 'projectAddress' | 'saleGalleryAddress' | 'saleGalleryGps' | 'priceRange'>
    & { galleryImage?: Maybe<Array<(
      { __typename?: 'ProjectGalleryMediaOuput' }
      & Pick<ProjectGalleryMediaOuput, 'url'>
    )>>, embededVimeoLink?: Maybe<Array<(
      { __typename?: 'ProjectYoutubeLinkOutput' }
      & Pick<ProjectYoutubeLinkOutput, 'url' | 'thumbnail'>
    )>>, embededYoutubeLink?: Maybe<Array<(
      { __typename?: 'ProjectYoutubeLinkOutput' }
      & Pick<ProjectYoutubeLinkOutput, 'url' | 'thumbnail'>
    )>>, embededGoogleDriveLink?: Maybe<Array<(
      { __typename?: 'ProjectYoutubeLinkOutput' }
      & Pick<ProjectYoutubeLinkOutput, 'url' | 'thumbnail'>
    )>>, amenities?: Maybe<Array<(
      { __typename?: 'Amenities' }
      & Pick<Amenities, 'category' | 'items'>
    )>>, developmentCompany?: Maybe<(
      { __typename?: 'companyEntity' }
      & Pick<CompanyEntity, 'name' | 'companyRegNo' | 'imageUrl' | 'officeTel' | 'email' | 'description'>
      & { address?: Maybe<(
        { __typename?: 'AddressJson' }
        & Pick<AddressJson, 'address'>
      )> }
    )>, projectType?: Maybe<(
      { __typename?: 'projectTypeEntity' }
      & Pick<ProjectTypeEntity, 'id' | 'name'>
    )>, virtualModelLink?: Maybe<Array<(
      { __typename?: 'MediaSource' }
      & Pick<MediaSource, 'caption' | 'url'>
    )>> }
  ) }
);

export type GetPublicELaunchQueryVariables = Exact<{
  accountCode: Scalars['String'];
  eLaunchCode: Scalars['String'];
}>;


export type GetPublicELaunchQuery = (
  { __typename?: 'Query' }
  & { getPublicELaunch?: Maybe<(
    { __typename?: 'publicELaunchOutput' }
    & Pick<PublicELaunchOutput, 'endDate' | 'startDate' | 'publishDate' | 'name'>
  )> }
);

export type GetPublicBrochureListQueryVariables = Exact<{
  accountCode: Scalars['String'];
  eLaunchCode: Scalars['String'];
}>;


export type GetPublicBrochureListQuery = (
  { __typename?: 'Query' }
  & { getPublicBrochureList: Array<(
    { __typename?: 'getPublicBrochureOutput' }
    & Pick<GetPublicBrochureOutput, 'docName'>
    & { attachment: (
      { __typename?: 'resourceUrl' }
      & Pick<ResourceUrl, 'resourceUrl'>
    ) }
  )> }
);

export type GetBlockUnitsQueryVariables = Exact<{
  block: Scalars['String'];
}>;


export type GetBlockUnitsQuery = (
  { __typename?: 'Query' }
  & { GetBlockUnits: (
    { __typename?: 'GetBlockUnitsOutput' }
    & Pick<GetBlockUnitsOutput, 'pictureBlockUrl'>
    & { salesChartUnitDisplay: Array<(
      { __typename?: 'SalesChart3DUnitOutput' }
      & Pick<SalesChart3DUnitOutput, 'unit_id' | 'unit_status' | 'unit_type_id' | 'time_lock' | 'user_id' | 'coordinates' | 'price' | 'common_status'>
    )> }
  ) }
);

export type SaveBookingExpressMutationVariables = Exact<{
  input: ELaunchAddBookingExpressInput;
}>;


export type SaveBookingExpressMutation = (
  { __typename?: 'Mutation' }
  & { saveBookingExpress: (
    { __typename?: 'SaveBookingOutput' }
    & Pick<SaveBookingOutput, 'saleId' | 'response'>
  ) }
);

export type AddToFavouriteMutationVariables = Exact<{
  unitId: Scalars['String'];
}>;


export type AddToFavouriteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addToFavourite'>
);

export type DeleteBookingAttachmentMutationVariables = Exact<{
  attachmentId: Scalars['String'];
}>;


export type DeleteBookingAttachmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteBookingAttachment'>
);

export type GetSalePaymentDataBySaleIdQueryVariables = Exact<{
  sale_id: Scalars['String'];
}>;


export type GetSalePaymentDataBySaleIdQuery = (
  { __typename?: 'Query' }
  & { getSalePaymentDataBySaleId: (
    { __typename?: 'SalePaymentConfirmResultOutput' }
    & Pick<SalePaymentConfirmResultOutput, 'amount' | 'refNo' | 'companyName' | 'officeTel' | 'status' | 'unitNo' | 'unitId' | 'devName' | 'resultUrl'>
    & { address: (
      { __typename?: 'AddressJson' }
      & Pick<AddressJson, 'address' | 'address2' | 'country' | 'city' | 'state' | 'postCode' | 'AddressType'>
    ) }
  ) }
);

export type GetRefundPolicyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRefundPolicyQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getRefundPolicy'>
);

export type UpdateStatusToInProgressSaleMutationVariables = Exact<{
  sale_id: Scalars['String'];
}>;


export type UpdateStatusToInProgressSaleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateStatusToInProgressSale'>
);

export type RevenueMonsterPaymentUrlSaleQueryVariables = Exact<{
  sale_id: Scalars['String'];
}>;


export type RevenueMonsterPaymentUrlSaleQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'revenueMonsterPaymentUrlSale'>
);

export type GetIpay88PaymentDataSaleQueryVariables = Exact<{
  payment_id: Scalars['String'];
  sale_id: Scalars['String'];
}>;


export type GetIpay88PaymentDataSaleQuery = (
  { __typename?: 'Query' }
  & { getIpay88PaymentDataSale: (
    { __typename?: 'Ipay88PaymentOutput' }
    & Pick<Ipay88PaymentOutput, 'refNo' | 'amount' | 'currency' | 'prodDesc' | 'name' | 'email' | 'contactNo' | 'remark' | 'lang' | 'method' | 'paymentId' | 'signature' | 'signatureType' | 'paymentUrl' | 'merchantCode' | 'redirectUrl' | 'notifyUrl'>
  ) }
);

export type GetIpay88PaymentMethodEnumQueryVariables = Exact<{ [key: string]: never; }>;


export type GetIpay88PaymentMethodEnumQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getIpay88PaymentMethodEnum'>
);

export type GetSalePaymentDataByRefNoQueryVariables = Exact<{
  ref_no: Scalars['String'];
}>;


export type GetSalePaymentDataByRefNoQuery = (
  { __typename?: 'Query' }
  & { getSalePaymentDataByRefNo: (
    { __typename?: 'SalePaymentConfirmResultOutput' }
    & Pick<SalePaymentConfirmResultOutput, 'amount' | 'refNo' | 'companyName' | 'officeTel' | 'status' | 'unitNo' | 'unitId' | 'devName' | 'resultUrl' | 'paymentId'>
    & { address: (
      { __typename?: 'AddressJson' }
      & Pick<AddressJson, 'address' | 'address2' | 'country' | 'city' | 'state' | 'postCode' | 'AddressType'>
    ) }
  ) }
);

export type GetShareableLinkQueryVariables = Exact<{ [key: string]: never; }>;


export type GetShareableLinkQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getShareableLink'>
);

export type GetLoginDetailQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLoginDetailQuery = (
  { __typename?: 'Query' }
  & { getLoginDetail: (
    { __typename?: 'eLaunchUserEntity' }
    & Pick<ELaunchUserEntity, 'loginId'>
  ) }
);

export type GetProfileDetailQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProfileDetailQuery = (
  { __typename?: 'Query' }
  & { profile: (
    { __typename?: 'eLaunchContactEntity' }
    & Pick<ELaunchContactEntity, 'name' | 'email'>
  ) }
);

export type GetProjectUrlQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProjectUrlQuery = (
  { __typename?: 'Query' }
  & { getProject?: Maybe<(
    { __typename?: 'projectEntity' }
    & Pick<ProjectEntity, 'id' | 'city' | 'state' | 'name'>
    & { virtualModelLink?: Maybe<Array<(
      { __typename?: 'MediaSource' }
      & Pick<MediaSource, 'caption' | 'url'>
    )>> }
  )> }
);

export type GetELetterPdfQueryVariables = Exact<{
  projectId: Scalars['String'];
  letterTemplateId: Scalars['String'];
  saleId: Scalars['String'];
}>;


export type GetELetterPdfQuery = (
  { __typename?: 'Query' }
  & { getELetterPDF: (
    { __typename?: 'ELetterOutput' }
    & Pick<ELetterOutput, 'fileName' | 'content'>
  ) }
);

export type GetProjectLetterForDocListingQueryVariables = Exact<{
  saleId: Scalars['String'];
}>;


export type GetProjectLetterForDocListingQuery = (
  { __typename?: 'Query' }
  & { getProjectLetterForDocListing: Array<(
    { __typename?: 'ESignProjectLetterListingOutput' }
    & Pick<ESignProjectLetterListingOutput, 'letterTemplateId' | 'name' | 'letterType'>
  )> }
);

export type GetMultiELetterBySaleIdQueryVariables = Exact<{
  saleId: Scalars['String'];
}>;


export type GetMultiELetterBySaleIdQuery = (
  { __typename?: 'Query' }
  & { getMultiELetterBySaleId: Array<(
    { __typename?: 'MultipleEsignProjectLetterListingOutput' }
    & Pick<MultipleEsignProjectLetterListingOutput, 'letterTypeName'>
    & { letter?: Maybe<Array<(
      { __typename?: 'ESignProjectLetterListingOutput' }
      & Pick<ESignProjectLetterListingOutput, 'letterTemplateId' | 'name' | 'letterType' | 'defaulted'>
    )>> }
  )> }
);

export type GetUserTranslationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserTranslationQuery = (
  { __typename?: 'Query' }
  & { getUserTranslation: (
    { __typename?: 'LanguageOutput' }
    & Pick<LanguageOutput, 'obj' | 'fileNames'>
  ) }
);

export type GetDefaultTranslationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDefaultTranslationQuery = (
  { __typename?: 'Query' }
  & { getDefaultTranslation: (
    { __typename?: 'LanguageOutput' }
    & Pick<LanguageOutput, 'obj' | 'fileNames'>
  ) }
);

export type GetCustomerOriginQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCustomerOriginQuery = (
  { __typename?: 'Query' }
  & { getCustomerOrigin: Array<(
    { __typename?: 'customerOriginSelection' }
    & Pick<CustomerOriginSelection, 'id' | 'name'>
  )> }
);

export type GetGeneralTinByPartyQueryVariables = Exact<{
  party?: Maybe<Scalars['String']>;
}>;


export type GetGeneralTinByPartyQuery = (
  { __typename?: 'Query' }
  & { getGeneralTinByParty: (
    { __typename?: 'eInvoiceGeneralTinEntity' }
    & Pick<EInvoiceGeneralTinEntity, 'id' | 'tinParty' | 'tin' | 'displaySeq'>
  ) }
);

export type GetIdentityTypeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetIdentityTypeQuery = (
  { __typename?: 'Query' }
  & { getIdentityType: Array<(
    { __typename?: 'identityTypeSelection' }
    & Pick<IdentityTypeSelection, 'id' | 'name'>
  )> }
);


export const GetProjectHeaderDocument = gql`
    query getProjectHeader {
  getProject {
    id
    city
    state
    name
    firstGalleryImage {
      url
    }
    developmentCompany {
      name
      companyRegNo
      imageUrl
      address {
        address
      }
      officeTel
      email
      isEinvIntegrated
    }
    holdingCompany {
      name
      companyRegNo
      imageUrl
      address {
        address
      }
      officeTel
      email
    }
  }
}
    `;

/**
 * __useGetProjectHeaderQuery__
 *
 * To run a query within a React component, call `useGetProjectHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectHeaderQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProjectHeaderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectHeaderQuery, GetProjectHeaderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectHeaderQuery, GetProjectHeaderQueryVariables>(GetProjectHeaderDocument, baseOptions);
      }
export function useGetProjectHeaderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectHeaderQuery, GetProjectHeaderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectHeaderQuery, GetProjectHeaderQueryVariables>(GetProjectHeaderDocument, baseOptions);
        }
export type GetProjectHeaderQueryHookResult = ReturnType<typeof useGetProjectHeaderQuery>;
export type GetProjectHeaderLazyQueryHookResult = ReturnType<typeof useGetProjectHeaderLazyQuery>;
export type GetProjectHeaderQueryResult = ApolloReactCommon.QueryResult<GetProjectHeaderQuery, GetProjectHeaderQueryVariables>;
export const GetProfileDocument = gql`
    query getProfile {
  profile {
    name
    userLanguageChoose {
      fileNames
      obj
    }
  }
}
    `;

/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProfileQuery, GetProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, baseOptions);
      }
export function useGetProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProfileQuery, GetProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, baseOptions);
        }
export type GetProfileQueryHookResult = ReturnType<typeof useGetProfileQuery>;
export type GetProfileLazyQueryHookResult = ReturnType<typeof useGetProfileLazyQuery>;
export type GetProfileQueryResult = ApolloReactCommon.QueryResult<GetProfileQuery, GetProfileQueryVariables>;
export const GetPublicProjectHeaderDocument = gql`
    query getPublicProjectHeader($accountCode: String!, $eLaunchCode: String!) {
  getPublicProject(accountCode: $accountCode, eLaunchCode: $eLaunchCode) {
    city
    state
    name
    firstGalleryImage {
      url
    }
    developmentCompany {
      name
      companyRegNo
      imageUrl
      address {
        address
      }
      officeTel
      email
    }
    holdingCompany {
      name
      companyRegNo
      imageUrl
      address {
        address
      }
      officeTel
      email
    }
  }
}
    `;

/**
 * __useGetPublicProjectHeaderQuery__
 *
 * To run a query within a React component, call `useGetPublicProjectHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicProjectHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicProjectHeaderQuery({
 *   variables: {
 *      accountCode: // value for 'accountCode'
 *      eLaunchCode: // value for 'eLaunchCode'
 *   },
 * });
 */
export function useGetPublicProjectHeaderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPublicProjectHeaderQuery, GetPublicProjectHeaderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPublicProjectHeaderQuery, GetPublicProjectHeaderQueryVariables>(GetPublicProjectHeaderDocument, baseOptions);
      }
export function useGetPublicProjectHeaderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPublicProjectHeaderQuery, GetPublicProjectHeaderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPublicProjectHeaderQuery, GetPublicProjectHeaderQueryVariables>(GetPublicProjectHeaderDocument, baseOptions);
        }
export type GetPublicProjectHeaderQueryHookResult = ReturnType<typeof useGetPublicProjectHeaderQuery>;
export type GetPublicProjectHeaderLazyQueryHookResult = ReturnType<typeof useGetPublicProjectHeaderLazyQuery>;
export type GetPublicProjectHeaderQueryResult = ApolloReactCommon.QueryResult<GetPublicProjectHeaderQuery, GetPublicProjectHeaderQueryVariables>;
export const CheckUrlValidityDocument = gql`
    query checkURLValidity($accountCode: String!, $eLaunchCode: String!) {
  checkURLValidity(accountCode: $accountCode, eLaunchCode: $eLaunchCode) {
    title
    loginLogoUrl
  }
}
    `;

/**
 * __useCheckUrlValidityQuery__
 *
 * To run a query within a React component, call `useCheckUrlValidityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckUrlValidityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckUrlValidityQuery({
 *   variables: {
 *      accountCode: // value for 'accountCode'
 *      eLaunchCode: // value for 'eLaunchCode'
 *   },
 * });
 */
export function useCheckUrlValidityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckUrlValidityQuery, CheckUrlValidityQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckUrlValidityQuery, CheckUrlValidityQueryVariables>(CheckUrlValidityDocument, baseOptions);
      }
export function useCheckUrlValidityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckUrlValidityQuery, CheckUrlValidityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckUrlValidityQuery, CheckUrlValidityQueryVariables>(CheckUrlValidityDocument, baseOptions);
        }
export type CheckUrlValidityQueryHookResult = ReturnType<typeof useCheckUrlValidityQuery>;
export type CheckUrlValidityLazyQueryHookResult = ReturnType<typeof useCheckUrlValidityLazyQuery>;
export type CheckUrlValidityQueryResult = ApolloReactCommon.QueryResult<CheckUrlValidityQuery, CheckUrlValidityQueryVariables>;
export const LoginDocument = gql`
    query login($accountCode: String!, $eLaunchCode: String!, $loginId: String!, $password: String!) {
  login(accountCode: $accountCode, eLaunchCode: $eLaunchCode, loginId: $loginId, password: $password) {
    accessToken
  }
}
    `;

/**
 * __useLoginQuery__
 *
 * To run a query within a React component, call `useLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginQuery({
 *   variables: {
 *      accountCode: // value for 'accountCode'
 *      eLaunchCode: // value for 'eLaunchCode'
 *      loginId: // value for 'loginId'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoginQuery, LoginQueryVariables>) {
        return ApolloReactHooks.useQuery<LoginQuery, LoginQueryVariables>(LoginDocument, baseOptions);
      }
export function useLoginLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoginQuery, LoginQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoginQuery, LoginQueryVariables>(LoginDocument, baseOptions);
        }
export type LoginQueryHookResult = ReturnType<typeof useLoginQuery>;
export type LoginLazyQueryHookResult = ReturnType<typeof useLoginLazyQuery>;
export type LoginQueryResult = ApolloReactCommon.QueryResult<LoginQuery, LoginQueryVariables>;
export const SendRegisterOtpDocument = gql`
    query sendRegisterOTP($accountCode: String!, $eLaunchCode: String!, $contactNo: String!) {
  sendRegisterOTP(accountCode: $accountCode, eLaunchCode: $eLaunchCode, contactNo: $contactNo)
}
    `;

/**
 * __useSendRegisterOtpQuery__
 *
 * To run a query within a React component, call `useSendRegisterOtpQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendRegisterOtpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendRegisterOtpQuery({
 *   variables: {
 *      accountCode: // value for 'accountCode'
 *      eLaunchCode: // value for 'eLaunchCode'
 *      contactNo: // value for 'contactNo'
 *   },
 * });
 */
export function useSendRegisterOtpQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SendRegisterOtpQuery, SendRegisterOtpQueryVariables>) {
        return ApolloReactHooks.useQuery<SendRegisterOtpQuery, SendRegisterOtpQueryVariables>(SendRegisterOtpDocument, baseOptions);
      }
export function useSendRegisterOtpLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SendRegisterOtpQuery, SendRegisterOtpQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SendRegisterOtpQuery, SendRegisterOtpQueryVariables>(SendRegisterOtpDocument, baseOptions);
        }
export type SendRegisterOtpQueryHookResult = ReturnType<typeof useSendRegisterOtpQuery>;
export type SendRegisterOtpLazyQueryHookResult = ReturnType<typeof useSendRegisterOtpLazyQuery>;
export type SendRegisterOtpQueryResult = ApolloReactCommon.QueryResult<SendRegisterOtpQuery, SendRegisterOtpQueryVariables>;
export const RegisterUserDocument = gql`
    mutation registerUser($input: RegisterUserInput!) {
  registerUser(input: $input) {
    accessToken
  }
}
    `;
export type RegisterUserMutationFn = ApolloReactCommon.MutationFunction<RegisterUserMutation, RegisterUserMutationVariables>;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegisterUserMutation, RegisterUserMutationVariables>) {
        return ApolloReactHooks.useMutation<RegisterUserMutation, RegisterUserMutationVariables>(RegisterUserDocument, baseOptions);
      }
export type RegisterUserMutationHookResult = ReturnType<typeof useRegisterUserMutation>;
export type RegisterUserMutationResult = ApolloReactCommon.MutationResult<RegisterUserMutation>;
export type RegisterUserMutationOptions = ApolloReactCommon.BaseMutationOptions<RegisterUserMutation, RegisterUserMutationVariables>;
export const VerifyOtpDocument = gql`
    query verifyOTP($accountCode: String!, $contactNo: String!, $otpCode: String!, $otpType: String!) {
  verifyOTP(accountCode: $accountCode, contactNo: $contactNo, otpCode: $otpCode, otpType: $otpType)
}
    `;

/**
 * __useVerifyOtpQuery__
 *
 * To run a query within a React component, call `useVerifyOtpQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyOtpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyOtpQuery({
 *   variables: {
 *      accountCode: // value for 'accountCode'
 *      contactNo: // value for 'contactNo'
 *      otpCode: // value for 'otpCode'
 *      otpType: // value for 'otpType'
 *   },
 * });
 */
export function useVerifyOtpQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VerifyOtpQuery, VerifyOtpQueryVariables>) {
        return ApolloReactHooks.useQuery<VerifyOtpQuery, VerifyOtpQueryVariables>(VerifyOtpDocument, baseOptions);
      }
export function useVerifyOtpLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VerifyOtpQuery, VerifyOtpQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VerifyOtpQuery, VerifyOtpQueryVariables>(VerifyOtpDocument, baseOptions);
        }
export type VerifyOtpQueryHookResult = ReturnType<typeof useVerifyOtpQuery>;
export type VerifyOtpLazyQueryHookResult = ReturnType<typeof useVerifyOtpLazyQuery>;
export type VerifyOtpQueryResult = ApolloReactCommon.QueryResult<VerifyOtpQuery, VerifyOtpQueryVariables>;
export const LogoutDocument = gql`
    query logout {
  logout
}
    `;

/**
 * __useLogoutQuery__
 *
 * To run a query within a React component, call `useLogoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogoutQuery({
 *   variables: {
 *   },
 * });
 */
export function useLogoutQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LogoutQuery, LogoutQueryVariables>) {
        return ApolloReactHooks.useQuery<LogoutQuery, LogoutQueryVariables>(LogoutDocument, baseOptions);
      }
export function useLogoutLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LogoutQuery, LogoutQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LogoutQuery, LogoutQueryVariables>(LogoutDocument, baseOptions);
        }
export type LogoutQueryHookResult = ReturnType<typeof useLogoutQuery>;
export type LogoutLazyQueryHookResult = ReturnType<typeof useLogoutLazyQuery>;
export type LogoutQueryResult = ApolloReactCommon.QueryResult<LogoutQuery, LogoutQueryVariables>;
export const RefreshTokenDocument = gql`
    query refreshToken {
  refreshToken
}
    `;

/**
 * __useRefreshTokenQuery__
 *
 * To run a query within a React component, call `useRefreshTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefreshTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useRefreshTokenQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RefreshTokenQuery, RefreshTokenQueryVariables>) {
        return ApolloReactHooks.useQuery<RefreshTokenQuery, RefreshTokenQueryVariables>(RefreshTokenDocument, baseOptions);
      }
export function useRefreshTokenLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RefreshTokenQuery, RefreshTokenQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RefreshTokenQuery, RefreshTokenQueryVariables>(RefreshTokenDocument, baseOptions);
        }
export type RefreshTokenQueryHookResult = ReturnType<typeof useRefreshTokenQuery>;
export type RefreshTokenLazyQueryHookResult = ReturnType<typeof useRefreshTokenLazyQuery>;
export type RefreshTokenQueryResult = ApolloReactCommon.QueryResult<RefreshTokenQuery, RefreshTokenQueryVariables>;
export const SendForgetPasswordOtpDocument = gql`
    query sendForgetPasswordOTP($accountCode: String!, $contactNo: String!) {
  sendForgetPasswordOTP(accountCode: $accountCode, contactNo: $contactNo)
}
    `;

/**
 * __useSendForgetPasswordOtpQuery__
 *
 * To run a query within a React component, call `useSendForgetPasswordOtpQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendForgetPasswordOtpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendForgetPasswordOtpQuery({
 *   variables: {
 *      accountCode: // value for 'accountCode'
 *      contactNo: // value for 'contactNo'
 *   },
 * });
 */
export function useSendForgetPasswordOtpQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SendForgetPasswordOtpQuery, SendForgetPasswordOtpQueryVariables>) {
        return ApolloReactHooks.useQuery<SendForgetPasswordOtpQuery, SendForgetPasswordOtpQueryVariables>(SendForgetPasswordOtpDocument, baseOptions);
      }
export function useSendForgetPasswordOtpLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SendForgetPasswordOtpQuery, SendForgetPasswordOtpQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SendForgetPasswordOtpQuery, SendForgetPasswordOtpQueryVariables>(SendForgetPasswordOtpDocument, baseOptions);
        }
export type SendForgetPasswordOtpQueryHookResult = ReturnType<typeof useSendForgetPasswordOtpQuery>;
export type SendForgetPasswordOtpLazyQueryHookResult = ReturnType<typeof useSendForgetPasswordOtpLazyQuery>;
export type SendForgetPasswordOtpQueryResult = ApolloReactCommon.QueryResult<SendForgetPasswordOtpQuery, SendForgetPasswordOtpQueryVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($input: ForgetPasswordInput!) {
  forgotPassword(input: $input)
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const GetProjectDocument = gql`
    query getProject {
  getProject {
    id
    currency
    name
    projectCode
    townshipName
    desc
    minPrice
    maxPrice
    completionDate
    launchDate
    unitMinPrice
    unitMaxPrice
    unitMinBuiltUp
    unitMaxBuiltUp
    uomDesc
    currency
    amenities {
      category
      items
    }
    embededYoutubeLink {
      videoType
      caption
      thumbnail
      url
    }
    embededVimeoLink {
      videoType
      caption
      thumbnail
      url
    }
    embededGoogleDriveLink {
      videoType
      caption
      thumbnail
      url
    }
    virtualModelLink {
      url
      caption
    }
    projectAddress
    state
    city
    projectGps
    saleGalleryAddress
    saleGalleryGps
    attachment {
      fileName
    }
    company {
      id
      name
      companyRegNo
      description
      imageUrl
      isEinvIntegrated
    }
    projectType {
      id
      name
    }
    landTypeDesc
    tenureDesc
    virtualModel {
      url
      caption
    }
    floorGalleryImage {
      block
      floor
      url
      caption
      floorlabel
    }
    units {
      block
      floor
      floorLabel
      builtUp
      unitLayout {
        desc
      }
    }
    unitLayoutGalleryImage {
      block
      unitLayout
      resourceid
      url
      caption
      pictureid
      seqno
      unitcount
      noOfBedroom
      noOfBathroom
    }
    galleryImage {
      url
      caption
    }
    firstGalleryImage {
      url
    }
    priceRange
  }
}
    `;

/**
 * __useGetProjectQuery__
 *
 * To run a query within a React component, call `useGetProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProjectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectQuery, GetProjectQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectQuery, GetProjectQueryVariables>(GetProjectDocument, baseOptions);
      }
export function useGetProjectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectQuery, GetProjectQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectQuery, GetProjectQueryVariables>(GetProjectDocument, baseOptions);
        }
export type GetProjectQueryHookResult = ReturnType<typeof useGetProjectQuery>;
export type GetProjectLazyQueryHookResult = ReturnType<typeof useGetProjectLazyQuery>;
export type GetProjectQueryResult = ApolloReactCommon.QueryResult<GetProjectQuery, GetProjectQueryVariables>;
export const GetUnitDetailDocument = gql`
    query GetUnitDetail($unitId: String!) {
  GetUnitDetail(unitId: $unitId) {
    id
    unitNo
    builtUp
    facingDirection {
      name
    }
    floorLayoutUrl
    unitLayout {
      desc
    }
    price
  }
}
    `;

/**
 * __useGetUnitDetailQuery__
 *
 * To run a query within a React component, call `useGetUnitDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitDetailQuery({
 *   variables: {
 *      unitId: // value for 'unitId'
 *   },
 * });
 */
export function useGetUnitDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUnitDetailQuery, GetUnitDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUnitDetailQuery, GetUnitDetailQueryVariables>(GetUnitDetailDocument, baseOptions);
      }
export function useGetUnitDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUnitDetailQuery, GetUnitDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUnitDetailQuery, GetUnitDetailQueryVariables>(GetUnitDetailDocument, baseOptions);
        }
export type GetUnitDetailQueryHookResult = ReturnType<typeof useGetUnitDetailQuery>;
export type GetUnitDetailLazyQueryHookResult = ReturnType<typeof useGetUnitDetailLazyQuery>;
export type GetUnitDetailQueryResult = ApolloReactCommon.QueryResult<GetUnitDetailQuery, GetUnitDetailQueryVariables>;
export const GetFavouritedBookedUnitListDocument = gql`
    query getFavouritedBookedUnitList {
  getFavouritedBookedUnitList {
    unitId
    unitNo
    unitLayout
    builtUp
    price
    saleId
    isBookedByOther
  }
}
    `;

/**
 * __useGetFavouritedBookedUnitListQuery__
 *
 * To run a query within a React component, call `useGetFavouritedBookedUnitListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFavouritedBookedUnitListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFavouritedBookedUnitListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFavouritedBookedUnitListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFavouritedBookedUnitListQuery, GetFavouritedBookedUnitListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFavouritedBookedUnitListQuery, GetFavouritedBookedUnitListQueryVariables>(GetFavouritedBookedUnitListDocument, baseOptions);
      }
export function useGetFavouritedBookedUnitListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFavouritedBookedUnitListQuery, GetFavouritedBookedUnitListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFavouritedBookedUnitListQuery, GetFavouritedBookedUnitListQueryVariables>(GetFavouritedBookedUnitListDocument, baseOptions);
        }
export type GetFavouritedBookedUnitListQueryHookResult = ReturnType<typeof useGetFavouritedBookedUnitListQuery>;
export type GetFavouritedBookedUnitListLazyQueryHookResult = ReturnType<typeof useGetFavouritedBookedUnitListLazyQuery>;
export type GetFavouritedBookedUnitListQueryResult = ApolloReactCommon.QueryResult<GetFavouritedBookedUnitListQuery, GetFavouritedBookedUnitListQueryVariables>;
export const RemoveFavouriteDocument = gql`
    mutation removeFavourite($unitId: String!) {
  removeFavourite(unitId: $unitId)
}
    `;
export type RemoveFavouriteMutationFn = ApolloReactCommon.MutationFunction<RemoveFavouriteMutation, RemoveFavouriteMutationVariables>;

/**
 * __useRemoveFavouriteMutation__
 *
 * To run a mutation, you first call `useRemoveFavouriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFavouriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFavouriteMutation, { data, loading, error }] = useRemoveFavouriteMutation({
 *   variables: {
 *      unitId: // value for 'unitId'
 *   },
 * });
 */
export function useRemoveFavouriteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveFavouriteMutation, RemoveFavouriteMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveFavouriteMutation, RemoveFavouriteMutationVariables>(RemoveFavouriteDocument, baseOptions);
      }
export type RemoveFavouriteMutationHookResult = ReturnType<typeof useRemoveFavouriteMutation>;
export type RemoveFavouriteMutationResult = ApolloReactCommon.MutationResult<RemoveFavouriteMutation>;
export type RemoveFavouriteMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveFavouriteMutation, RemoveFavouriteMutationVariables>;
export const GetELaunchDocument = gql`
    query getELaunch {
  getELaunch {
    endDate
    startDate
    publishDate
    terminationDate
    name
  }
}
    `;

/**
 * __useGetELaunchQuery__
 *
 * To run a query within a React component, call `useGetELaunchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetELaunchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetELaunchQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetELaunchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetELaunchQuery, GetELaunchQueryVariables>) {
        return ApolloReactHooks.useQuery<GetELaunchQuery, GetELaunchQueryVariables>(GetELaunchDocument, baseOptions);
      }
export function useGetELaunchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetELaunchQuery, GetELaunchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetELaunchQuery, GetELaunchQueryVariables>(GetELaunchDocument, baseOptions);
        }
export type GetELaunchQueryHookResult = ReturnType<typeof useGetELaunchQuery>;
export type GetELaunchLazyQueryHookResult = ReturnType<typeof useGetELaunchLazyQuery>;
export type GetELaunchQueryResult = ApolloReactCommon.QueryResult<GetELaunchQuery, GetELaunchQueryVariables>;
export const GetBookingDetailDocument = gql`
    query getBookingDetail($salesId: String!) {
  GetBookingDetail(sale_id: $salesId) {
    id
    salesNo
    package
    salesPersonId
    salesPerson
    salesTeamId
    salesTeamName
    salesStatus
    agentPersonId
    agencyAcctId
    agencyContractId
    listPrice
    sellingPrice
    bookingRemark
    unit {
      builtUp
      unitNo
      facingDirection {
        name
      }
      floorLayoutUrl
      unitLayout {
        desc
      }
      price
    }
    discount {
      discount {
        title
      }
    }
    joinBuyer {
      isEInvoiceRequired
      isGovAuth
      id
      commonStatus
      contact {
        id
        fullName
        email
        phoneNo
      }
    }
    contact {
      id
      fullName
      email
      phoneNo
      icNo
      nationality {
        name
      }
    }
    bookingAttachments {
      bookingCcrAttachments {
        id
        accountId
        refTable
        refId
        refType
        mediaType
        resourceUrl
        fileName
        bucketFileName
        isPrivate
        seqNo
        allowDelete
      }
      bookingNricAttachments {
        id
        accountId
        refTable
        refId
        refType
        mediaType
        resourceUrl
        fileName
        bucketFileName
        isPrivate
        seqNo
        allowDelete
      }
      bookingFeeAttachments {
        id
        accountId
        refTable
        refId
        refType
        mediaType
        resourceUrl
        fileName
        bucketFileName
        isPrivate
        seqNo
        allowDelete
      }
      bookingPdpaAttachments {
        id
        accountId
        refTable
        refId
        refType
        mediaType
        resourceUrl
        fileName
        bucketFileName
        isPrivate
        seqNo
        allowDelete
      }
    }
  }
}
    `;

/**
 * __useGetBookingDetailQuery__
 *
 * To run a query within a React component, call `useGetBookingDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingDetailQuery({
 *   variables: {
 *      salesId: // value for 'salesId'
 *   },
 * });
 */
export function useGetBookingDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBookingDetailQuery, GetBookingDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBookingDetailQuery, GetBookingDetailQueryVariables>(GetBookingDetailDocument, baseOptions);
      }
export function useGetBookingDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBookingDetailQuery, GetBookingDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBookingDetailQuery, GetBookingDetailQueryVariables>(GetBookingDetailDocument, baseOptions);
        }
export type GetBookingDetailQueryHookResult = ReturnType<typeof useGetBookingDetailQuery>;
export type GetBookingDetailLazyQueryHookResult = ReturnType<typeof useGetBookingDetailLazyQuery>;
export type GetBookingDetailQueryResult = ApolloReactCommon.QueryResult<GetBookingDetailQuery, GetBookingDetailQueryVariables>;
export const GetBookingDetailDataDocument = gql`
    query GetBookingDetailData($saleId: String!) {
  GetBookingDetailData(saleId: $saleId) {
    salesId
    salesNo
    salesStatus
    bookingRemark
    salesPersonId
    salesTeamId
    buyerName
    buyerId
    buyerNric
    buyerNationality
    buyerPhoneNo
    buyerEmail
    isCompany
    buyerCompanyName
    isjointBuyerComplete
    isPurchaserInfoComplete
    unitId
    unitNo
    unitLayout
    unitLayoutDes
    landArea
    extraLandArea
    builtUp
    sellingPrice
    listPrice
    bookingRemark
    personName
    teamName
    agencyName
    agencyUserName
    agentPersonId
    agencyAcctId
    agencyContractId
    isBumi
    saleDiscount {
      discount {
        id
        title
      }
    }
    packageList {
      id
      name
      desc
    }
    joinBuyer {
      contact {
        fullName
      }
    }
    attachmentCount {
      isAttachmentComplete
      requiredDocCount
      completedDocCount
    }
    isEInvoiceRequired
    isGovAuth
    identityType
  }
}
    `;

/**
 * __useGetBookingDetailDataQuery__
 *
 * To run a query within a React component, call `useGetBookingDetailDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingDetailDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingDetailDataQuery({
 *   variables: {
 *      saleId: // value for 'saleId'
 *   },
 * });
 */
export function useGetBookingDetailDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBookingDetailDataQuery, GetBookingDetailDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBookingDetailDataQuery, GetBookingDetailDataQueryVariables>(GetBookingDetailDataDocument, baseOptions);
      }
export function useGetBookingDetailDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBookingDetailDataQuery, GetBookingDetailDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBookingDetailDataQuery, GetBookingDetailDataQueryVariables>(GetBookingDetailDataDocument, baseOptions);
        }
export type GetBookingDetailDataQueryHookResult = ReturnType<typeof useGetBookingDetailDataQuery>;
export type GetBookingDetailDataLazyQueryHookResult = ReturnType<typeof useGetBookingDetailDataLazyQuery>;
export type GetBookingDetailDataQueryResult = ApolloReactCommon.QueryResult<GetBookingDetailDataQuery, GetBookingDetailDataQueryVariables>;
export const DownloadAttachmentDocument = gql`
    mutation downloadAttachment($input: DownloadAttachmentInput!) {
  downloadAttachment(input: $input) {
    content
    fileName
  }
}
    `;
export type DownloadAttachmentMutationFn = ApolloReactCommon.MutationFunction<DownloadAttachmentMutation, DownloadAttachmentMutationVariables>;

/**
 * __useDownloadAttachmentMutation__
 *
 * To run a mutation, you first call `useDownloadAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadAttachmentMutation, { data, loading, error }] = useDownloadAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDownloadAttachmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DownloadAttachmentMutation, DownloadAttachmentMutationVariables>) {
        return ApolloReactHooks.useMutation<DownloadAttachmentMutation, DownloadAttachmentMutationVariables>(DownloadAttachmentDocument, baseOptions);
      }
export type DownloadAttachmentMutationHookResult = ReturnType<typeof useDownloadAttachmentMutation>;
export type DownloadAttachmentMutationResult = ApolloReactCommon.MutationResult<DownloadAttachmentMutation>;
export type DownloadAttachmentMutationOptions = ApolloReactCommon.BaseMutationOptions<DownloadAttachmentMutation, DownloadAttachmentMutationVariables>;
export const ELaunchDownloadAttachmentDocument = gql`
    query eLaunchDownloadAttachment($attachmentId: String!) {
  eLaunchDownloadAttachment(attachmentId: $attachmentId) {
    fileName
    base64
  }
}
    `;

/**
 * __useELaunchDownloadAttachmentQuery__
 *
 * To run a query within a React component, call `useELaunchDownloadAttachmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useELaunchDownloadAttachmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useELaunchDownloadAttachmentQuery({
 *   variables: {
 *      attachmentId: // value for 'attachmentId'
 *   },
 * });
 */
export function useELaunchDownloadAttachmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ELaunchDownloadAttachmentQuery, ELaunchDownloadAttachmentQueryVariables>) {
        return ApolloReactHooks.useQuery<ELaunchDownloadAttachmentQuery, ELaunchDownloadAttachmentQueryVariables>(ELaunchDownloadAttachmentDocument, baseOptions);
      }
export function useELaunchDownloadAttachmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ELaunchDownloadAttachmentQuery, ELaunchDownloadAttachmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ELaunchDownloadAttachmentQuery, ELaunchDownloadAttachmentQueryVariables>(ELaunchDownloadAttachmentDocument, baseOptions);
        }
export type ELaunchDownloadAttachmentQueryHookResult = ReturnType<typeof useELaunchDownloadAttachmentQuery>;
export type ELaunchDownloadAttachmentLazyQueryHookResult = ReturnType<typeof useELaunchDownloadAttachmentLazyQuery>;
export type ELaunchDownloadAttachmentQueryResult = ApolloReactCommon.QueryResult<ELaunchDownloadAttachmentQuery, ELaunchDownloadAttachmentQueryVariables>;
export const AddBookingAttachmentsDocument = gql`
    mutation addBookingAttachments($input: eLaunchAddAttachmentsInput!) {
  addBookingAttachments(input: $input) {
    id
    accountId
    bucketFileName
    fileName
    isPrivate
    mediaType
    refId
    refTable
    refType
    resourceUrl
    seqNo
  }
}
    `;
export type AddBookingAttachmentsMutationFn = ApolloReactCommon.MutationFunction<AddBookingAttachmentsMutation, AddBookingAttachmentsMutationVariables>;

/**
 * __useAddBookingAttachmentsMutation__
 *
 * To run a mutation, you first call `useAddBookingAttachmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBookingAttachmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBookingAttachmentsMutation, { data, loading, error }] = useAddBookingAttachmentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddBookingAttachmentsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddBookingAttachmentsMutation, AddBookingAttachmentsMutationVariables>) {
        return ApolloReactHooks.useMutation<AddBookingAttachmentsMutation, AddBookingAttachmentsMutationVariables>(AddBookingAttachmentsDocument, baseOptions);
      }
export type AddBookingAttachmentsMutationHookResult = ReturnType<typeof useAddBookingAttachmentsMutation>;
export type AddBookingAttachmentsMutationResult = ApolloReactCommon.MutationResult<AddBookingAttachmentsMutation>;
export type AddBookingAttachmentsMutationOptions = ApolloReactCommon.BaseMutationOptions<AddBookingAttachmentsMutation, AddBookingAttachmentsMutationVariables>;
export const UploadNricPassportDocument = gql`
    mutation uploadNricPassport($input: UploadNricPassportInput!) {
  uploadNricPassport(input: $input) {
    id
    accountId
    bucketFileName
    fileName
    isPrivate
    mediaType
    refId
    refTable
    refType
    resourceUrl
    seqNo
  }
}
    `;
export type UploadNricPassportMutationFn = ApolloReactCommon.MutationFunction<UploadNricPassportMutation, UploadNricPassportMutationVariables>;

/**
 * __useUploadNricPassportMutation__
 *
 * To run a mutation, you first call `useUploadNricPassportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadNricPassportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadNricPassportMutation, { data, loading, error }] = useUploadNricPassportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadNricPassportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadNricPassportMutation, UploadNricPassportMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadNricPassportMutation, UploadNricPassportMutationVariables>(UploadNricPassportDocument, baseOptions);
      }
export type UploadNricPassportMutationHookResult = ReturnType<typeof useUploadNricPassportMutation>;
export type UploadNricPassportMutationResult = ApolloReactCommon.MutationResult<UploadNricPassportMutation>;
export type UploadNricPassportMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadNricPassportMutation, UploadNricPassportMutationVariables>;
export const GetWaterMarkTextDocument = gql`
    query getWaterMarkText {
  GetWaterMarkText
}
    `;

/**
 * __useGetWaterMarkTextQuery__
 *
 * To run a query within a React component, call `useGetWaterMarkTextQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWaterMarkTextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWaterMarkTextQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWaterMarkTextQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWaterMarkTextQuery, GetWaterMarkTextQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWaterMarkTextQuery, GetWaterMarkTextQueryVariables>(GetWaterMarkTextDocument, baseOptions);
      }
export function useGetWaterMarkTextLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWaterMarkTextQuery, GetWaterMarkTextQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWaterMarkTextQuery, GetWaterMarkTextQueryVariables>(GetWaterMarkTextDocument, baseOptions);
        }
export type GetWaterMarkTextQueryHookResult = ReturnType<typeof useGetWaterMarkTextQuery>;
export type GetWaterMarkTextLazyQueryHookResult = ReturnType<typeof useGetWaterMarkTextLazyQuery>;
export type GetWaterMarkTextQueryResult = ApolloReactCommon.QueryResult<GetWaterMarkTextQuery, GetWaterMarkTextQueryVariables>;
export const GetSpecPolicyDetailDocument = gql`
    query getSpecPolicyDetail {
  GetAllMasterInfo {
    Nationalities {
      id
      name
    }
    PaymentMethods
  }
  getBookingFormData {
    projectSpec {
      bookingFeeMandatory
      bookingSignRequired
      bookingPDPAconsentRequired
      bookingRefundPolicyconsentRequired
      statementRefundPolicy
      bookingfeeAmountRequired
      minAge
      minAgeRequired
      minBookingfeeAmount
      paymentGatewayId
    }
  }
  getPDPADocument {
    id
    name
    templateBody
    letterType
  }
}
    `;

/**
 * __useGetSpecPolicyDetailQuery__
 *
 * To run a query within a React component, call `useGetSpecPolicyDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpecPolicyDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpecPolicyDetailQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSpecPolicyDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSpecPolicyDetailQuery, GetSpecPolicyDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSpecPolicyDetailQuery, GetSpecPolicyDetailQueryVariables>(GetSpecPolicyDetailDocument, baseOptions);
      }
export function useGetSpecPolicyDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSpecPolicyDetailQuery, GetSpecPolicyDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSpecPolicyDetailQuery, GetSpecPolicyDetailQueryVariables>(GetSpecPolicyDetailDocument, baseOptions);
        }
export type GetSpecPolicyDetailQueryHookResult = ReturnType<typeof useGetSpecPolicyDetailQuery>;
export type GetSpecPolicyDetailLazyQueryHookResult = ReturnType<typeof useGetSpecPolicyDetailLazyQuery>;
export type GetSpecPolicyDetailQueryResult = ApolloReactCommon.QueryResult<GetSpecPolicyDetailQuery, GetSpecPolicyDetailQueryVariables>;
export const GetEditJointBuyerDocument = gql`
    query getEditJointBuyer($contactId: String!) {
  GetJointBuyer(contactId: $contactId) {
    id
    nationality {
      id
      name
    }
    fullName
    icNo
    address {
      address
      state
      country
      city
      postCode
    }
    email
    phoneNo
    contactExt {
      id
      tin
      sstNo
      isForeignPurchase
      customerOrigin
      identityType
      isBumi
    }
  }
}
    `;

/**
 * __useGetEditJointBuyerQuery__
 *
 * To run a query within a React component, call `useGetEditJointBuyerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditJointBuyerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditJointBuyerQuery({
 *   variables: {
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useGetEditJointBuyerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEditJointBuyerQuery, GetEditJointBuyerQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEditJointBuyerQuery, GetEditJointBuyerQueryVariables>(GetEditJointBuyerDocument, baseOptions);
      }
export function useGetEditJointBuyerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEditJointBuyerQuery, GetEditJointBuyerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEditJointBuyerQuery, GetEditJointBuyerQueryVariables>(GetEditJointBuyerDocument, baseOptions);
        }
export type GetEditJointBuyerQueryHookResult = ReturnType<typeof useGetEditJointBuyerQuery>;
export type GetEditJointBuyerLazyQueryHookResult = ReturnType<typeof useGetEditJointBuyerLazyQuery>;
export type GetEditJointBuyerQueryResult = ApolloReactCommon.QueryResult<GetEditJointBuyerQuery, GetEditJointBuyerQueryVariables>;
export const EditJointBuyerDocument = gql`
    mutation editJointBuyer($input: EditELaunchBookingJointBuyerInput!) {
  EditJointBuyer(input: $input)
}
    `;
export type EditJointBuyerMutationFn = ApolloReactCommon.MutationFunction<EditJointBuyerMutation, EditJointBuyerMutationVariables>;

/**
 * __useEditJointBuyerMutation__
 *
 * To run a mutation, you first call `useEditJointBuyerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditJointBuyerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editJointBuyerMutation, { data, loading, error }] = useEditJointBuyerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditJointBuyerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditJointBuyerMutation, EditJointBuyerMutationVariables>) {
        return ApolloReactHooks.useMutation<EditJointBuyerMutation, EditJointBuyerMutationVariables>(EditJointBuyerDocument, baseOptions);
      }
export type EditJointBuyerMutationHookResult = ReturnType<typeof useEditJointBuyerMutation>;
export type EditJointBuyerMutationResult = ApolloReactCommon.MutationResult<EditJointBuyerMutation>;
export type EditJointBuyerMutationOptions = ApolloReactCommon.BaseMutationOptions<EditJointBuyerMutation, EditJointBuyerMutationVariables>;
export const DeactivateJointBuyerDocument = gql`
    mutation deactivateJointBuyer($jointBuyerId: String!) {
  deactivateJointBuyer(jointBuyerId: $jointBuyerId)
}
    `;
export type DeactivateJointBuyerMutationFn = ApolloReactCommon.MutationFunction<DeactivateJointBuyerMutation, DeactivateJointBuyerMutationVariables>;

/**
 * __useDeactivateJointBuyerMutation__
 *
 * To run a mutation, you first call `useDeactivateJointBuyerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateJointBuyerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateJointBuyerMutation, { data, loading, error }] = useDeactivateJointBuyerMutation({
 *   variables: {
 *      jointBuyerId: // value for 'jointBuyerId'
 *   },
 * });
 */
export function useDeactivateJointBuyerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeactivateJointBuyerMutation, DeactivateJointBuyerMutationVariables>) {
        return ApolloReactHooks.useMutation<DeactivateJointBuyerMutation, DeactivateJointBuyerMutationVariables>(DeactivateJointBuyerDocument, baseOptions);
      }
export type DeactivateJointBuyerMutationHookResult = ReturnType<typeof useDeactivateJointBuyerMutation>;
export type DeactivateJointBuyerMutationResult = ApolloReactCommon.MutationResult<DeactivateJointBuyerMutation>;
export type DeactivateJointBuyerMutationOptions = ApolloReactCommon.BaseMutationOptions<DeactivateJointBuyerMutation, DeactivateJointBuyerMutationVariables>;
export const CancelBookingDocument = gql`
    query cancelBooking($sale_id: String!, $reason: String!, $reason_id: String!) {
  CancelBooking(sale_id: $sale_id, reason: $reason, reason_id: $reason_id)
}
    `;

/**
 * __useCancelBookingQuery__
 *
 * To run a query within a React component, call `useCancelBookingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCancelBookingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCancelBookingQuery({
 *   variables: {
 *      sale_id: // value for 'sale_id'
 *      reason: // value for 'reason'
 *      reason_id: // value for 'reason_id'
 *   },
 * });
 */
export function useCancelBookingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CancelBookingQuery, CancelBookingQueryVariables>) {
        return ApolloReactHooks.useQuery<CancelBookingQuery, CancelBookingQueryVariables>(CancelBookingDocument, baseOptions);
      }
export function useCancelBookingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CancelBookingQuery, CancelBookingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CancelBookingQuery, CancelBookingQueryVariables>(CancelBookingDocument, baseOptions);
        }
export type CancelBookingQueryHookResult = ReturnType<typeof useCancelBookingQuery>;
export type CancelBookingLazyQueryHookResult = ReturnType<typeof useCancelBookingLazyQuery>;
export type CancelBookingQueryResult = ApolloReactCommon.QueryResult<CancelBookingQuery, CancelBookingQueryVariables>;
export const AddJointBuyerDocument = gql`
    mutation AddJointBuyer($input: AddELaunchBookingJointBuyerInput!) {
  AddJointBuyer(input: $input)
}
    `;
export type AddJointBuyerMutationFn = ApolloReactCommon.MutationFunction<AddJointBuyerMutation, AddJointBuyerMutationVariables>;

/**
 * __useAddJointBuyerMutation__
 *
 * To run a mutation, you first call `useAddJointBuyerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddJointBuyerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addJointBuyerMutation, { data, loading, error }] = useAddJointBuyerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddJointBuyerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddJointBuyerMutation, AddJointBuyerMutationVariables>) {
        return ApolloReactHooks.useMutation<AddJointBuyerMutation, AddJointBuyerMutationVariables>(AddJointBuyerDocument, baseOptions);
      }
export type AddJointBuyerMutationHookResult = ReturnType<typeof useAddJointBuyerMutation>;
export type AddJointBuyerMutationResult = ApolloReactCommon.MutationResult<AddJointBuyerMutation>;
export type AddJointBuyerMutationOptions = ApolloReactCommon.BaseMutationOptions<AddJointBuyerMutation, AddJointBuyerMutationVariables>;
export const EditPurchaserInfoDocument = gql`
    mutation EditPurchaserInfo($input: EditPurchaserInfoInput!) {
  EditPurchaserInfo(input: $input)
}
    `;
export type EditPurchaserInfoMutationFn = ApolloReactCommon.MutationFunction<EditPurchaserInfoMutation, EditPurchaserInfoMutationVariables>;

/**
 * __useEditPurchaserInfoMutation__
 *
 * To run a mutation, you first call `useEditPurchaserInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPurchaserInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPurchaserInfoMutation, { data, loading, error }] = useEditPurchaserInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditPurchaserInfoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditPurchaserInfoMutation, EditPurchaserInfoMutationVariables>) {
        return ApolloReactHooks.useMutation<EditPurchaserInfoMutation, EditPurchaserInfoMutationVariables>(EditPurchaserInfoDocument, baseOptions);
      }
export type EditPurchaserInfoMutationHookResult = ReturnType<typeof useEditPurchaserInfoMutation>;
export type EditPurchaserInfoMutationResult = ApolloReactCommon.MutationResult<EditPurchaserInfoMutation>;
export type EditPurchaserInfoMutationOptions = ApolloReactCommon.BaseMutationOptions<EditPurchaserInfoMutation, EditPurchaserInfoMutationVariables>;
export const GetEditProfileDocument = gql`
    query getEditProfile {
  profile {
    userId
    name
    email
    phoneNo
    pictureUrl
    elaunchUser {
      languageSet
    }
    getLangMasterData {
      langCode
      langName
    }
    userLanguageChoose {
      fileNames
      obj
    }
  }
}
    `;

/**
 * __useGetEditProfileQuery__
 *
 * To run a query within a React component, call `useGetEditProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEditProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEditProfileQuery, GetEditProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEditProfileQuery, GetEditProfileQueryVariables>(GetEditProfileDocument, baseOptions);
      }
export function useGetEditProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEditProfileQuery, GetEditProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEditProfileQuery, GetEditProfileQueryVariables>(GetEditProfileDocument, baseOptions);
        }
export type GetEditProfileQueryHookResult = ReturnType<typeof useGetEditProfileQuery>;
export type GetEditProfileLazyQueryHookResult = ReturnType<typeof useGetEditProfileLazyQuery>;
export type GetEditProfileQueryResult = ApolloReactCommon.QueryResult<GetEditProfileQuery, GetEditProfileQueryVariables>;
export const UpdateProfileDocument = gql`
    mutation updateProfile($input: UserContactInput!) {
  updateProfile(input: $input)
}
    `;
export type UpdateProfileMutationFn = ApolloReactCommon.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, baseOptions);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = ApolloReactCommon.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($input: UserChangePasswordInput!) {
  changePassword(input: $input)
}
    `;
export type ChangePasswordMutationFn = ApolloReactCommon.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, baseOptions);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = ApolloReactCommon.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const GetBrochureListDocument = gql`
    query getBrochureList {
  getBrochureList {
    docName
    attachment {
      resourceUrl
    }
  }
}
    `;

/**
 * __useGetBrochureListQuery__
 *
 * To run a query within a React component, call `useGetBrochureListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrochureListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrochureListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBrochureListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBrochureListQuery, GetBrochureListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBrochureListQuery, GetBrochureListQueryVariables>(GetBrochureListDocument, baseOptions);
      }
export function useGetBrochureListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBrochureListQuery, GetBrochureListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBrochureListQuery, GetBrochureListQueryVariables>(GetBrochureListDocument, baseOptions);
        }
export type GetBrochureListQueryHookResult = ReturnType<typeof useGetBrochureListQuery>;
export type GetBrochureListLazyQueryHookResult = ReturnType<typeof useGetBrochureListLazyQuery>;
export type GetBrochureListQueryResult = ApolloReactCommon.QueryResult<GetBrochureListQuery, GetBrochureListQueryVariables>;
export const GetSaleCancelReasonDocument = gql`
    query getSaleCancelReason {
  getSaleCancelReason {
    id
    name
    defaulted
  }
}
    `;

/**
 * __useGetSaleCancelReasonQuery__
 *
 * To run a query within a React component, call `useGetSaleCancelReasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSaleCancelReasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSaleCancelReasonQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSaleCancelReasonQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSaleCancelReasonQuery, GetSaleCancelReasonQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSaleCancelReasonQuery, GetSaleCancelReasonQueryVariables>(GetSaleCancelReasonDocument, baseOptions);
      }
export function useGetSaleCancelReasonLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSaleCancelReasonQuery, GetSaleCancelReasonQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSaleCancelReasonQuery, GetSaleCancelReasonQueryVariables>(GetSaleCancelReasonDocument, baseOptions);
        }
export type GetSaleCancelReasonQueryHookResult = ReturnType<typeof useGetSaleCancelReasonQuery>;
export type GetSaleCancelReasonLazyQueryHookResult = ReturnType<typeof useGetSaleCancelReasonLazyQuery>;
export type GetSaleCancelReasonQueryResult = ApolloReactCommon.QueryResult<GetSaleCancelReasonQuery, GetSaleCancelReasonQueryVariables>;
export const GetProjectChartMappedDocument = gql`
    query getProjectChartMapped {
  getProjectChartMapped
}
    `;

/**
 * __useGetProjectChartMappedQuery__
 *
 * To run a query within a React component, call `useGetProjectChartMappedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectChartMappedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectChartMappedQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProjectChartMappedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectChartMappedQuery, GetProjectChartMappedQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectChartMappedQuery, GetProjectChartMappedQueryVariables>(GetProjectChartMappedDocument, baseOptions);
      }
export function useGetProjectChartMappedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectChartMappedQuery, GetProjectChartMappedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectChartMappedQuery, GetProjectChartMappedQueryVariables>(GetProjectChartMappedDocument, baseOptions);
        }
export type GetProjectChartMappedQueryHookResult = ReturnType<typeof useGetProjectChartMappedQuery>;
export type GetProjectChartMappedLazyQueryHookResult = ReturnType<typeof useGetProjectChartMappedLazyQuery>;
export type GetProjectChartMappedQueryResult = ApolloReactCommon.QueryResult<GetProjectChartMappedQuery, GetProjectChartMappedQueryVariables>;
export const GetProjectDataDocument = gql`
    query getProjectData {
  getProject {
    distinctBlock
    saleschartBlock
    unitMaxPrice
    unitMinPrice
    unitTypes {
      builtUp
      id
      name
    }
  }
}
    `;

/**
 * __useGetProjectDataQuery__
 *
 * To run a query within a React component, call `useGetProjectDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProjectDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectDataQuery, GetProjectDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectDataQuery, GetProjectDataQueryVariables>(GetProjectDataDocument, baseOptions);
      }
export function useGetProjectDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectDataQuery, GetProjectDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectDataQuery, GetProjectDataQueryVariables>(GetProjectDataDocument, baseOptions);
        }
export type GetProjectDataQueryHookResult = ReturnType<typeof useGetProjectDataQuery>;
export type GetProjectDataLazyQueryHookResult = ReturnType<typeof useGetProjectDataLazyQuery>;
export type GetProjectDataQueryResult = ApolloReactCommon.QueryResult<GetProjectDataQuery, GetProjectDataQueryVariables>;
export const JointBuyerFormSourceDocument = gql`
    query jointBuyerFormSource {
  GetAllMasterInfo {
    Nationalities {
      id
      name
    }
  }
}
    `;

/**
 * __useJointBuyerFormSourceQuery__
 *
 * To run a query within a React component, call `useJointBuyerFormSourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useJointBuyerFormSourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJointBuyerFormSourceQuery({
 *   variables: {
 *   },
 * });
 */
export function useJointBuyerFormSourceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JointBuyerFormSourceQuery, JointBuyerFormSourceQueryVariables>) {
        return ApolloReactHooks.useQuery<JointBuyerFormSourceQuery, JointBuyerFormSourceQueryVariables>(JointBuyerFormSourceDocument, baseOptions);
      }
export function useJointBuyerFormSourceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JointBuyerFormSourceQuery, JointBuyerFormSourceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JointBuyerFormSourceQuery, JointBuyerFormSourceQueryVariables>(JointBuyerFormSourceDocument, baseOptions);
        }
export type JointBuyerFormSourceQueryHookResult = ReturnType<typeof useJointBuyerFormSourceQuery>;
export type JointBuyerFormSourceLazyQueryHookResult = ReturnType<typeof useJointBuyerFormSourceLazyQuery>;
export type JointBuyerFormSourceQueryResult = ApolloReactCommon.QueryResult<JointBuyerFormSourceQuery, JointBuyerFormSourceQueryVariables>;
export const GetSalesChartByBlockDocument = gql`
    query getSalesChartByBlock($keySearch: String, $block: String!) {
  GetSalesChartByBlock(keySearch: $keySearch, block: $block) {
    floors {
      floor
      floor_label
    }
    units {
      cell
      common_status
      floor
      floor_label
      price
      status
      unit_id
      unit_no
      unit_status
      unit_type
    }
  }
}
    `;

/**
 * __useGetSalesChartByBlockQuery__
 *
 * To run a query within a React component, call `useGetSalesChartByBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesChartByBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesChartByBlockQuery({
 *   variables: {
 *      keySearch: // value for 'keySearch'
 *      block: // value for 'block'
 *   },
 * });
 */
export function useGetSalesChartByBlockQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSalesChartByBlockQuery, GetSalesChartByBlockQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSalesChartByBlockQuery, GetSalesChartByBlockQueryVariables>(GetSalesChartByBlockDocument, baseOptions);
      }
export function useGetSalesChartByBlockLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSalesChartByBlockQuery, GetSalesChartByBlockQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSalesChartByBlockQuery, GetSalesChartByBlockQueryVariables>(GetSalesChartByBlockDocument, baseOptions);
        }
export type GetSalesChartByBlockQueryHookResult = ReturnType<typeof useGetSalesChartByBlockQuery>;
export type GetSalesChartByBlockLazyQueryHookResult = ReturnType<typeof useGetSalesChartByBlockLazyQuery>;
export type GetSalesChartByBlockQueryResult = ApolloReactCommon.QueryResult<GetSalesChartByBlockQuery, GetSalesChartByBlockQueryVariables>;
export const GetPublicProjectDocument = gql`
    query getPublicProject($accountCode: String!, $eLaunchCode: String!) {
  getPublicProject(accountCode: $accountCode, eLaunchCode: $eLaunchCode) {
    completionDate
    launchDate
    tenureDesc
    desc
    builtup
    unitMinBuiltUp
    unitMaxBuiltUp
    uomDesc
    galleryImage {
      url
    }
    embededVimeoLink {
      url
      thumbnail
    }
    embededYoutubeLink {
      url
      thumbnail
    }
    embededGoogleDriveLink {
      url
      thumbnail
    }
    minPrice
    maxPrice
    currency
    townshipName
    landTypeDesc
    amenities {
      category
      items
    }
    developmentCompany {
      name
      companyRegNo
      imageUrl
      address {
        address
      }
      officeTel
      email
      description
    }
    projectGps
    projectAddress
    saleGalleryAddress
    saleGalleryGps
    projectType {
      id
      name
    }
    virtualModelLink {
      caption
      url
    }
    priceRange
  }
}
    `;

/**
 * __useGetPublicProjectQuery__
 *
 * To run a query within a React component, call `useGetPublicProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicProjectQuery({
 *   variables: {
 *      accountCode: // value for 'accountCode'
 *      eLaunchCode: // value for 'eLaunchCode'
 *   },
 * });
 */
export function useGetPublicProjectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPublicProjectQuery, GetPublicProjectQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPublicProjectQuery, GetPublicProjectQueryVariables>(GetPublicProjectDocument, baseOptions);
      }
export function useGetPublicProjectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPublicProjectQuery, GetPublicProjectQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPublicProjectQuery, GetPublicProjectQueryVariables>(GetPublicProjectDocument, baseOptions);
        }
export type GetPublicProjectQueryHookResult = ReturnType<typeof useGetPublicProjectQuery>;
export type GetPublicProjectLazyQueryHookResult = ReturnType<typeof useGetPublicProjectLazyQuery>;
export type GetPublicProjectQueryResult = ApolloReactCommon.QueryResult<GetPublicProjectQuery, GetPublicProjectQueryVariables>;
export const GetPublicELaunchDocument = gql`
    query getPublicELaunch($accountCode: String!, $eLaunchCode: String!) {
  getPublicELaunch(accountCode: $accountCode, eLaunchCode: $eLaunchCode) {
    endDate
    startDate
    publishDate
    name
  }
}
    `;

/**
 * __useGetPublicELaunchQuery__
 *
 * To run a query within a React component, call `useGetPublicELaunchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicELaunchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicELaunchQuery({
 *   variables: {
 *      accountCode: // value for 'accountCode'
 *      eLaunchCode: // value for 'eLaunchCode'
 *   },
 * });
 */
export function useGetPublicELaunchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPublicELaunchQuery, GetPublicELaunchQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPublicELaunchQuery, GetPublicELaunchQueryVariables>(GetPublicELaunchDocument, baseOptions);
      }
export function useGetPublicELaunchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPublicELaunchQuery, GetPublicELaunchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPublicELaunchQuery, GetPublicELaunchQueryVariables>(GetPublicELaunchDocument, baseOptions);
        }
export type GetPublicELaunchQueryHookResult = ReturnType<typeof useGetPublicELaunchQuery>;
export type GetPublicELaunchLazyQueryHookResult = ReturnType<typeof useGetPublicELaunchLazyQuery>;
export type GetPublicELaunchQueryResult = ApolloReactCommon.QueryResult<GetPublicELaunchQuery, GetPublicELaunchQueryVariables>;
export const GetPublicBrochureListDocument = gql`
    query getPublicBrochureList($accountCode: String!, $eLaunchCode: String!) {
  getPublicBrochureList(accountCode: $accountCode, eLaunchCode: $eLaunchCode) {
    docName
    attachment {
      resourceUrl
    }
  }
}
    `;

/**
 * __useGetPublicBrochureListQuery__
 *
 * To run a query within a React component, call `useGetPublicBrochureListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicBrochureListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicBrochureListQuery({
 *   variables: {
 *      accountCode: // value for 'accountCode'
 *      eLaunchCode: // value for 'eLaunchCode'
 *   },
 * });
 */
export function useGetPublicBrochureListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPublicBrochureListQuery, GetPublicBrochureListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPublicBrochureListQuery, GetPublicBrochureListQueryVariables>(GetPublicBrochureListDocument, baseOptions);
      }
export function useGetPublicBrochureListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPublicBrochureListQuery, GetPublicBrochureListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPublicBrochureListQuery, GetPublicBrochureListQueryVariables>(GetPublicBrochureListDocument, baseOptions);
        }
export type GetPublicBrochureListQueryHookResult = ReturnType<typeof useGetPublicBrochureListQuery>;
export type GetPublicBrochureListLazyQueryHookResult = ReturnType<typeof useGetPublicBrochureListLazyQuery>;
export type GetPublicBrochureListQueryResult = ApolloReactCommon.QueryResult<GetPublicBrochureListQuery, GetPublicBrochureListQueryVariables>;
export const GetBlockUnitsDocument = gql`
    query getBlockUnits($block: String!) {
  GetBlockUnits(block: $block) {
    pictureBlockUrl
    salesChartUnitDisplay {
      unit_id
      unit_status
      unit_type_id
      time_lock
      user_id
      coordinates
      price
      common_status
    }
  }
}
    `;

/**
 * __useGetBlockUnitsQuery__
 *
 * To run a query within a React component, call `useGetBlockUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlockUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlockUnitsQuery({
 *   variables: {
 *      block: // value for 'block'
 *   },
 * });
 */
export function useGetBlockUnitsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBlockUnitsQuery, GetBlockUnitsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBlockUnitsQuery, GetBlockUnitsQueryVariables>(GetBlockUnitsDocument, baseOptions);
      }
export function useGetBlockUnitsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBlockUnitsQuery, GetBlockUnitsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBlockUnitsQuery, GetBlockUnitsQueryVariables>(GetBlockUnitsDocument, baseOptions);
        }
export type GetBlockUnitsQueryHookResult = ReturnType<typeof useGetBlockUnitsQuery>;
export type GetBlockUnitsLazyQueryHookResult = ReturnType<typeof useGetBlockUnitsLazyQuery>;
export type GetBlockUnitsQueryResult = ApolloReactCommon.QueryResult<GetBlockUnitsQuery, GetBlockUnitsQueryVariables>;
export const SaveBookingExpressDocument = gql`
    mutation saveBookingExpress($input: eLaunchAddBookingExpressInput!) {
  saveBookingExpress(input: $input) {
    saleId
    response
  }
}
    `;
export type SaveBookingExpressMutationFn = ApolloReactCommon.MutationFunction<SaveBookingExpressMutation, SaveBookingExpressMutationVariables>;

/**
 * __useSaveBookingExpressMutation__
 *
 * To run a mutation, you first call `useSaveBookingExpressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveBookingExpressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveBookingExpressMutation, { data, loading, error }] = useSaveBookingExpressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveBookingExpressMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SaveBookingExpressMutation, SaveBookingExpressMutationVariables>) {
        return ApolloReactHooks.useMutation<SaveBookingExpressMutation, SaveBookingExpressMutationVariables>(SaveBookingExpressDocument, baseOptions);
      }
export type SaveBookingExpressMutationHookResult = ReturnType<typeof useSaveBookingExpressMutation>;
export type SaveBookingExpressMutationResult = ApolloReactCommon.MutationResult<SaveBookingExpressMutation>;
export type SaveBookingExpressMutationOptions = ApolloReactCommon.BaseMutationOptions<SaveBookingExpressMutation, SaveBookingExpressMutationVariables>;
export const AddToFavouriteDocument = gql`
    mutation addToFavourite($unitId: String!) {
  addToFavourite(unitId: $unitId)
}
    `;
export type AddToFavouriteMutationFn = ApolloReactCommon.MutationFunction<AddToFavouriteMutation, AddToFavouriteMutationVariables>;

/**
 * __useAddToFavouriteMutation__
 *
 * To run a mutation, you first call `useAddToFavouriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToFavouriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToFavouriteMutation, { data, loading, error }] = useAddToFavouriteMutation({
 *   variables: {
 *      unitId: // value for 'unitId'
 *   },
 * });
 */
export function useAddToFavouriteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddToFavouriteMutation, AddToFavouriteMutationVariables>) {
        return ApolloReactHooks.useMutation<AddToFavouriteMutation, AddToFavouriteMutationVariables>(AddToFavouriteDocument, baseOptions);
      }
export type AddToFavouriteMutationHookResult = ReturnType<typeof useAddToFavouriteMutation>;
export type AddToFavouriteMutationResult = ApolloReactCommon.MutationResult<AddToFavouriteMutation>;
export type AddToFavouriteMutationOptions = ApolloReactCommon.BaseMutationOptions<AddToFavouriteMutation, AddToFavouriteMutationVariables>;
export const DeleteBookingAttachmentDocument = gql`
    mutation deleteBookingAttachment($attachmentId: String!) {
  deleteBookingAttachment(attachmentId: $attachmentId)
}
    `;
export type DeleteBookingAttachmentMutationFn = ApolloReactCommon.MutationFunction<DeleteBookingAttachmentMutation, DeleteBookingAttachmentMutationVariables>;

/**
 * __useDeleteBookingAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteBookingAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBookingAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBookingAttachmentMutation, { data, loading, error }] = useDeleteBookingAttachmentMutation({
 *   variables: {
 *      attachmentId: // value for 'attachmentId'
 *   },
 * });
 */
export function useDeleteBookingAttachmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteBookingAttachmentMutation, DeleteBookingAttachmentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteBookingAttachmentMutation, DeleteBookingAttachmentMutationVariables>(DeleteBookingAttachmentDocument, baseOptions);
      }
export type DeleteBookingAttachmentMutationHookResult = ReturnType<typeof useDeleteBookingAttachmentMutation>;
export type DeleteBookingAttachmentMutationResult = ApolloReactCommon.MutationResult<DeleteBookingAttachmentMutation>;
export type DeleteBookingAttachmentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteBookingAttachmentMutation, DeleteBookingAttachmentMutationVariables>;
export const GetSalePaymentDataBySaleIdDocument = gql`
    query getSalePaymentDataBySaleId($sale_id: String!) {
  getSalePaymentDataBySaleId(sale_id: $sale_id) {
    amount
    refNo
    companyName
    address {
      address
      address2
      country
      city
      state
      postCode
      AddressType
    }
    officeTel
    status
    unitNo
    unitId
    devName
    resultUrl
  }
}
    `;

/**
 * __useGetSalePaymentDataBySaleIdQuery__
 *
 * To run a query within a React component, call `useGetSalePaymentDataBySaleIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalePaymentDataBySaleIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalePaymentDataBySaleIdQuery({
 *   variables: {
 *      sale_id: // value for 'sale_id'
 *   },
 * });
 */
export function useGetSalePaymentDataBySaleIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSalePaymentDataBySaleIdQuery, GetSalePaymentDataBySaleIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSalePaymentDataBySaleIdQuery, GetSalePaymentDataBySaleIdQueryVariables>(GetSalePaymentDataBySaleIdDocument, baseOptions);
      }
export function useGetSalePaymentDataBySaleIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSalePaymentDataBySaleIdQuery, GetSalePaymentDataBySaleIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSalePaymentDataBySaleIdQuery, GetSalePaymentDataBySaleIdQueryVariables>(GetSalePaymentDataBySaleIdDocument, baseOptions);
        }
export type GetSalePaymentDataBySaleIdQueryHookResult = ReturnType<typeof useGetSalePaymentDataBySaleIdQuery>;
export type GetSalePaymentDataBySaleIdLazyQueryHookResult = ReturnType<typeof useGetSalePaymentDataBySaleIdLazyQuery>;
export type GetSalePaymentDataBySaleIdQueryResult = ApolloReactCommon.QueryResult<GetSalePaymentDataBySaleIdQuery, GetSalePaymentDataBySaleIdQueryVariables>;
export const GetRefundPolicyDocument = gql`
    query getRefundPolicy {
  getRefundPolicy
}
    `;

/**
 * __useGetRefundPolicyQuery__
 *
 * To run a query within a React component, call `useGetRefundPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRefundPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRefundPolicyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRefundPolicyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRefundPolicyQuery, GetRefundPolicyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRefundPolicyQuery, GetRefundPolicyQueryVariables>(GetRefundPolicyDocument, baseOptions);
      }
export function useGetRefundPolicyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRefundPolicyQuery, GetRefundPolicyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRefundPolicyQuery, GetRefundPolicyQueryVariables>(GetRefundPolicyDocument, baseOptions);
        }
export type GetRefundPolicyQueryHookResult = ReturnType<typeof useGetRefundPolicyQuery>;
export type GetRefundPolicyLazyQueryHookResult = ReturnType<typeof useGetRefundPolicyLazyQuery>;
export type GetRefundPolicyQueryResult = ApolloReactCommon.QueryResult<GetRefundPolicyQuery, GetRefundPolicyQueryVariables>;
export const UpdateStatusToInProgressSaleDocument = gql`
    mutation updateStatusToInProgressSale($sale_id: String!) {
  updateStatusToInProgressSale(sale_id: $sale_id)
}
    `;
export type UpdateStatusToInProgressSaleMutationFn = ApolloReactCommon.MutationFunction<UpdateStatusToInProgressSaleMutation, UpdateStatusToInProgressSaleMutationVariables>;

/**
 * __useUpdateStatusToInProgressSaleMutation__
 *
 * To run a mutation, you first call `useUpdateStatusToInProgressSaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStatusToInProgressSaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStatusToInProgressSaleMutation, { data, loading, error }] = useUpdateStatusToInProgressSaleMutation({
 *   variables: {
 *      sale_id: // value for 'sale_id'
 *   },
 * });
 */
export function useUpdateStatusToInProgressSaleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStatusToInProgressSaleMutation, UpdateStatusToInProgressSaleMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateStatusToInProgressSaleMutation, UpdateStatusToInProgressSaleMutationVariables>(UpdateStatusToInProgressSaleDocument, baseOptions);
      }
export type UpdateStatusToInProgressSaleMutationHookResult = ReturnType<typeof useUpdateStatusToInProgressSaleMutation>;
export type UpdateStatusToInProgressSaleMutationResult = ApolloReactCommon.MutationResult<UpdateStatusToInProgressSaleMutation>;
export type UpdateStatusToInProgressSaleMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStatusToInProgressSaleMutation, UpdateStatusToInProgressSaleMutationVariables>;
export const RevenueMonsterPaymentUrlSaleDocument = gql`
    query revenueMonsterPaymentUrlSale($sale_id: String!) {
  revenueMonsterPaymentUrlSale(sale_id: $sale_id)
}
    `;

/**
 * __useRevenueMonsterPaymentUrlSaleQuery__
 *
 * To run a query within a React component, call `useRevenueMonsterPaymentUrlSaleQuery` and pass it any options that fit your needs.
 * When your component renders, `useRevenueMonsterPaymentUrlSaleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRevenueMonsterPaymentUrlSaleQuery({
 *   variables: {
 *      sale_id: // value for 'sale_id'
 *   },
 * });
 */
export function useRevenueMonsterPaymentUrlSaleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RevenueMonsterPaymentUrlSaleQuery, RevenueMonsterPaymentUrlSaleQueryVariables>) {
        return ApolloReactHooks.useQuery<RevenueMonsterPaymentUrlSaleQuery, RevenueMonsterPaymentUrlSaleQueryVariables>(RevenueMonsterPaymentUrlSaleDocument, baseOptions);
      }
export function useRevenueMonsterPaymentUrlSaleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RevenueMonsterPaymentUrlSaleQuery, RevenueMonsterPaymentUrlSaleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RevenueMonsterPaymentUrlSaleQuery, RevenueMonsterPaymentUrlSaleQueryVariables>(RevenueMonsterPaymentUrlSaleDocument, baseOptions);
        }
export type RevenueMonsterPaymentUrlSaleQueryHookResult = ReturnType<typeof useRevenueMonsterPaymentUrlSaleQuery>;
export type RevenueMonsterPaymentUrlSaleLazyQueryHookResult = ReturnType<typeof useRevenueMonsterPaymentUrlSaleLazyQuery>;
export type RevenueMonsterPaymentUrlSaleQueryResult = ApolloReactCommon.QueryResult<RevenueMonsterPaymentUrlSaleQuery, RevenueMonsterPaymentUrlSaleQueryVariables>;
export const GetIpay88PaymentDataSaleDocument = gql`
    query getIpay88PaymentDataSale($payment_id: String!, $sale_id: String!) {
  getIpay88PaymentDataSale(payment_id: $payment_id, sale_id: $sale_id) {
    refNo
    amount
    currency
    prodDesc
    name
    email
    contactNo
    remark
    lang
    method
    paymentId
    signature
    signatureType
    paymentUrl
    merchantCode
    redirectUrl
    notifyUrl
  }
}
    `;

/**
 * __useGetIpay88PaymentDataSaleQuery__
 *
 * To run a query within a React component, call `useGetIpay88PaymentDataSaleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIpay88PaymentDataSaleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIpay88PaymentDataSaleQuery({
 *   variables: {
 *      payment_id: // value for 'payment_id'
 *      sale_id: // value for 'sale_id'
 *   },
 * });
 */
export function useGetIpay88PaymentDataSaleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetIpay88PaymentDataSaleQuery, GetIpay88PaymentDataSaleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetIpay88PaymentDataSaleQuery, GetIpay88PaymentDataSaleQueryVariables>(GetIpay88PaymentDataSaleDocument, baseOptions);
      }
export function useGetIpay88PaymentDataSaleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetIpay88PaymentDataSaleQuery, GetIpay88PaymentDataSaleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetIpay88PaymentDataSaleQuery, GetIpay88PaymentDataSaleQueryVariables>(GetIpay88PaymentDataSaleDocument, baseOptions);
        }
export type GetIpay88PaymentDataSaleQueryHookResult = ReturnType<typeof useGetIpay88PaymentDataSaleQuery>;
export type GetIpay88PaymentDataSaleLazyQueryHookResult = ReturnType<typeof useGetIpay88PaymentDataSaleLazyQuery>;
export type GetIpay88PaymentDataSaleQueryResult = ApolloReactCommon.QueryResult<GetIpay88PaymentDataSaleQuery, GetIpay88PaymentDataSaleQueryVariables>;
export const GetIpay88PaymentMethodEnumDocument = gql`
    query getIpay88PaymentMethodEnum {
  getIpay88PaymentMethodEnum
}
    `;

/**
 * __useGetIpay88PaymentMethodEnumQuery__
 *
 * To run a query within a React component, call `useGetIpay88PaymentMethodEnumQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIpay88PaymentMethodEnumQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIpay88PaymentMethodEnumQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIpay88PaymentMethodEnumQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetIpay88PaymentMethodEnumQuery, GetIpay88PaymentMethodEnumQueryVariables>) {
        return ApolloReactHooks.useQuery<GetIpay88PaymentMethodEnumQuery, GetIpay88PaymentMethodEnumQueryVariables>(GetIpay88PaymentMethodEnumDocument, baseOptions);
      }
export function useGetIpay88PaymentMethodEnumLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetIpay88PaymentMethodEnumQuery, GetIpay88PaymentMethodEnumQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetIpay88PaymentMethodEnumQuery, GetIpay88PaymentMethodEnumQueryVariables>(GetIpay88PaymentMethodEnumDocument, baseOptions);
        }
export type GetIpay88PaymentMethodEnumQueryHookResult = ReturnType<typeof useGetIpay88PaymentMethodEnumQuery>;
export type GetIpay88PaymentMethodEnumLazyQueryHookResult = ReturnType<typeof useGetIpay88PaymentMethodEnumLazyQuery>;
export type GetIpay88PaymentMethodEnumQueryResult = ApolloReactCommon.QueryResult<GetIpay88PaymentMethodEnumQuery, GetIpay88PaymentMethodEnumQueryVariables>;
export const GetSalePaymentDataByRefNoDocument = gql`
    query getSalePaymentDataByRefNo($ref_no: String!) {
  getSalePaymentDataByRefNo(ref_no: $ref_no) {
    amount
    refNo
    companyName
    address {
      address
      address2
      country
      city
      state
      postCode
      AddressType
    }
    officeTel
    status
    unitNo
    unitId
    devName
    resultUrl
    paymentId
  }
}
    `;

/**
 * __useGetSalePaymentDataByRefNoQuery__
 *
 * To run a query within a React component, call `useGetSalePaymentDataByRefNoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalePaymentDataByRefNoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalePaymentDataByRefNoQuery({
 *   variables: {
 *      ref_no: // value for 'ref_no'
 *   },
 * });
 */
export function useGetSalePaymentDataByRefNoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSalePaymentDataByRefNoQuery, GetSalePaymentDataByRefNoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSalePaymentDataByRefNoQuery, GetSalePaymentDataByRefNoQueryVariables>(GetSalePaymentDataByRefNoDocument, baseOptions);
      }
export function useGetSalePaymentDataByRefNoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSalePaymentDataByRefNoQuery, GetSalePaymentDataByRefNoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSalePaymentDataByRefNoQuery, GetSalePaymentDataByRefNoQueryVariables>(GetSalePaymentDataByRefNoDocument, baseOptions);
        }
export type GetSalePaymentDataByRefNoQueryHookResult = ReturnType<typeof useGetSalePaymentDataByRefNoQuery>;
export type GetSalePaymentDataByRefNoLazyQueryHookResult = ReturnType<typeof useGetSalePaymentDataByRefNoLazyQuery>;
export type GetSalePaymentDataByRefNoQueryResult = ApolloReactCommon.QueryResult<GetSalePaymentDataByRefNoQuery, GetSalePaymentDataByRefNoQueryVariables>;
export const GetShareableLinkDocument = gql`
    query getShareableLink {
  getShareableLink
}
    `;

/**
 * __useGetShareableLinkQuery__
 *
 * To run a query within a React component, call `useGetShareableLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShareableLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShareableLinkQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetShareableLinkQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShareableLinkQuery, GetShareableLinkQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShareableLinkQuery, GetShareableLinkQueryVariables>(GetShareableLinkDocument, baseOptions);
      }
export function useGetShareableLinkLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShareableLinkQuery, GetShareableLinkQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShareableLinkQuery, GetShareableLinkQueryVariables>(GetShareableLinkDocument, baseOptions);
        }
export type GetShareableLinkQueryHookResult = ReturnType<typeof useGetShareableLinkQuery>;
export type GetShareableLinkLazyQueryHookResult = ReturnType<typeof useGetShareableLinkLazyQuery>;
export type GetShareableLinkQueryResult = ApolloReactCommon.QueryResult<GetShareableLinkQuery, GetShareableLinkQueryVariables>;
export const GetLoginDetailDocument = gql`
    query getLoginDetail {
  getLoginDetail {
    loginId
  }
}
    `;

/**
 * __useGetLoginDetailQuery__
 *
 * To run a query within a React component, call `useGetLoginDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoginDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoginDetailQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLoginDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLoginDetailQuery, GetLoginDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLoginDetailQuery, GetLoginDetailQueryVariables>(GetLoginDetailDocument, baseOptions);
      }
export function useGetLoginDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLoginDetailQuery, GetLoginDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLoginDetailQuery, GetLoginDetailQueryVariables>(GetLoginDetailDocument, baseOptions);
        }
export type GetLoginDetailQueryHookResult = ReturnType<typeof useGetLoginDetailQuery>;
export type GetLoginDetailLazyQueryHookResult = ReturnType<typeof useGetLoginDetailLazyQuery>;
export type GetLoginDetailQueryResult = ApolloReactCommon.QueryResult<GetLoginDetailQuery, GetLoginDetailQueryVariables>;
export const GetProfileDetailDocument = gql`
    query getProfileDetail {
  profile {
    name
    email
  }
}
    `;

/**
 * __useGetProfileDetailQuery__
 *
 * To run a query within a React component, call `useGetProfileDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileDetailQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProfileDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProfileDetailQuery, GetProfileDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProfileDetailQuery, GetProfileDetailQueryVariables>(GetProfileDetailDocument, baseOptions);
      }
export function useGetProfileDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProfileDetailQuery, GetProfileDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProfileDetailQuery, GetProfileDetailQueryVariables>(GetProfileDetailDocument, baseOptions);
        }
export type GetProfileDetailQueryHookResult = ReturnType<typeof useGetProfileDetailQuery>;
export type GetProfileDetailLazyQueryHookResult = ReturnType<typeof useGetProfileDetailLazyQuery>;
export type GetProfileDetailQueryResult = ApolloReactCommon.QueryResult<GetProfileDetailQuery, GetProfileDetailQueryVariables>;
export const GetProjectUrlDocument = gql`
    query getProjectUrl {
  getProject {
    id
    city
    state
    name
    virtualModelLink {
      caption
      url
    }
  }
}
    `;

/**
 * __useGetProjectUrlQuery__
 *
 * To run a query within a React component, call `useGetProjectUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectUrlQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProjectUrlQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectUrlQuery, GetProjectUrlQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectUrlQuery, GetProjectUrlQueryVariables>(GetProjectUrlDocument, baseOptions);
      }
export function useGetProjectUrlLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectUrlQuery, GetProjectUrlQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectUrlQuery, GetProjectUrlQueryVariables>(GetProjectUrlDocument, baseOptions);
        }
export type GetProjectUrlQueryHookResult = ReturnType<typeof useGetProjectUrlQuery>;
export type GetProjectUrlLazyQueryHookResult = ReturnType<typeof useGetProjectUrlLazyQuery>;
export type GetProjectUrlQueryResult = ApolloReactCommon.QueryResult<GetProjectUrlQuery, GetProjectUrlQueryVariables>;
export const GetELetterPdfDocument = gql`
    query getELetterPDF($projectId: String!, $letterTemplateId: String!, $saleId: String!) {
  getELetterPDF(projectId: $projectId, letterTemplateId: $letterTemplateId, saleId: $saleId) {
    fileName
    content
  }
}
    `;

/**
 * __useGetELetterPdfQuery__
 *
 * To run a query within a React component, call `useGetELetterPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetELetterPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetELetterPdfQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      letterTemplateId: // value for 'letterTemplateId'
 *      saleId: // value for 'saleId'
 *   },
 * });
 */
export function useGetELetterPdfQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetELetterPdfQuery, GetELetterPdfQueryVariables>) {
        return ApolloReactHooks.useQuery<GetELetterPdfQuery, GetELetterPdfQueryVariables>(GetELetterPdfDocument, baseOptions);
      }
export function useGetELetterPdfLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetELetterPdfQuery, GetELetterPdfQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetELetterPdfQuery, GetELetterPdfQueryVariables>(GetELetterPdfDocument, baseOptions);
        }
export type GetELetterPdfQueryHookResult = ReturnType<typeof useGetELetterPdfQuery>;
export type GetELetterPdfLazyQueryHookResult = ReturnType<typeof useGetELetterPdfLazyQuery>;
export type GetELetterPdfQueryResult = ApolloReactCommon.QueryResult<GetELetterPdfQuery, GetELetterPdfQueryVariables>;
export const GetProjectLetterForDocListingDocument = gql`
    query getProjectLetterForDocListing($saleId: String!) {
  getProjectLetterForDocListing(saleId: $saleId) {
    letterTemplateId
    name
    letterType
  }
}
    `;

/**
 * __useGetProjectLetterForDocListingQuery__
 *
 * To run a query within a React component, call `useGetProjectLetterForDocListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectLetterForDocListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectLetterForDocListingQuery({
 *   variables: {
 *      saleId: // value for 'saleId'
 *   },
 * });
 */
export function useGetProjectLetterForDocListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectLetterForDocListingQuery, GetProjectLetterForDocListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectLetterForDocListingQuery, GetProjectLetterForDocListingQueryVariables>(GetProjectLetterForDocListingDocument, baseOptions);
      }
export function useGetProjectLetterForDocListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectLetterForDocListingQuery, GetProjectLetterForDocListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectLetterForDocListingQuery, GetProjectLetterForDocListingQueryVariables>(GetProjectLetterForDocListingDocument, baseOptions);
        }
export type GetProjectLetterForDocListingQueryHookResult = ReturnType<typeof useGetProjectLetterForDocListingQuery>;
export type GetProjectLetterForDocListingLazyQueryHookResult = ReturnType<typeof useGetProjectLetterForDocListingLazyQuery>;
export type GetProjectLetterForDocListingQueryResult = ApolloReactCommon.QueryResult<GetProjectLetterForDocListingQuery, GetProjectLetterForDocListingQueryVariables>;
export const GetMultiELetterBySaleIdDocument = gql`
    query getMultiELetterBySaleId($saleId: String!) {
  getMultiELetterBySaleId(saleId: $saleId) {
    letterTypeName
    letter {
      letterTemplateId
      name
      letterType
      defaulted
    }
  }
}
    `;

/**
 * __useGetMultiELetterBySaleIdQuery__
 *
 * To run a query within a React component, call `useGetMultiELetterBySaleIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMultiELetterBySaleIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMultiELetterBySaleIdQuery({
 *   variables: {
 *      saleId: // value for 'saleId'
 *   },
 * });
 */
export function useGetMultiELetterBySaleIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMultiELetterBySaleIdQuery, GetMultiELetterBySaleIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMultiELetterBySaleIdQuery, GetMultiELetterBySaleIdQueryVariables>(GetMultiELetterBySaleIdDocument, baseOptions);
      }
export function useGetMultiELetterBySaleIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMultiELetterBySaleIdQuery, GetMultiELetterBySaleIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMultiELetterBySaleIdQuery, GetMultiELetterBySaleIdQueryVariables>(GetMultiELetterBySaleIdDocument, baseOptions);
        }
export type GetMultiELetterBySaleIdQueryHookResult = ReturnType<typeof useGetMultiELetterBySaleIdQuery>;
export type GetMultiELetterBySaleIdLazyQueryHookResult = ReturnType<typeof useGetMultiELetterBySaleIdLazyQuery>;
export type GetMultiELetterBySaleIdQueryResult = ApolloReactCommon.QueryResult<GetMultiELetterBySaleIdQuery, GetMultiELetterBySaleIdQueryVariables>;
export const GetUserTranslationDocument = gql`
    query getUserTranslation {
  getUserTranslation {
    obj
    fileNames
  }
}
    `;

/**
 * __useGetUserTranslationQuery__
 *
 * To run a query within a React component, call `useGetUserTranslationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserTranslationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserTranslationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserTranslationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserTranslationQuery, GetUserTranslationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserTranslationQuery, GetUserTranslationQueryVariables>(GetUserTranslationDocument, baseOptions);
      }
export function useGetUserTranslationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserTranslationQuery, GetUserTranslationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserTranslationQuery, GetUserTranslationQueryVariables>(GetUserTranslationDocument, baseOptions);
        }
export type GetUserTranslationQueryHookResult = ReturnType<typeof useGetUserTranslationQuery>;
export type GetUserTranslationLazyQueryHookResult = ReturnType<typeof useGetUserTranslationLazyQuery>;
export type GetUserTranslationQueryResult = ApolloReactCommon.QueryResult<GetUserTranslationQuery, GetUserTranslationQueryVariables>;
export const GetDefaultTranslationDocument = gql`
    query getDefaultTranslation {
  getDefaultTranslation {
    obj
    fileNames
  }
}
    `;

/**
 * __useGetDefaultTranslationQuery__
 *
 * To run a query within a React component, call `useGetDefaultTranslationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultTranslationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultTranslationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDefaultTranslationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDefaultTranslationQuery, GetDefaultTranslationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDefaultTranslationQuery, GetDefaultTranslationQueryVariables>(GetDefaultTranslationDocument, baseOptions);
      }
export function useGetDefaultTranslationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDefaultTranslationQuery, GetDefaultTranslationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDefaultTranslationQuery, GetDefaultTranslationQueryVariables>(GetDefaultTranslationDocument, baseOptions);
        }
export type GetDefaultTranslationQueryHookResult = ReturnType<typeof useGetDefaultTranslationQuery>;
export type GetDefaultTranslationLazyQueryHookResult = ReturnType<typeof useGetDefaultTranslationLazyQuery>;
export type GetDefaultTranslationQueryResult = ApolloReactCommon.QueryResult<GetDefaultTranslationQuery, GetDefaultTranslationQueryVariables>;
export const GetCustomerOriginDocument = gql`
    query getCustomerOrigin {
  getCustomerOrigin {
    id
    name
  }
}
    `;

/**
 * __useGetCustomerOriginQuery__
 *
 * To run a query within a React component, call `useGetCustomerOriginQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerOriginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerOriginQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomerOriginQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCustomerOriginQuery, GetCustomerOriginQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCustomerOriginQuery, GetCustomerOriginQueryVariables>(GetCustomerOriginDocument, baseOptions);
      }
export function useGetCustomerOriginLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomerOriginQuery, GetCustomerOriginQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCustomerOriginQuery, GetCustomerOriginQueryVariables>(GetCustomerOriginDocument, baseOptions);
        }
export type GetCustomerOriginQueryHookResult = ReturnType<typeof useGetCustomerOriginQuery>;
export type GetCustomerOriginLazyQueryHookResult = ReturnType<typeof useGetCustomerOriginLazyQuery>;
export type GetCustomerOriginQueryResult = ApolloReactCommon.QueryResult<GetCustomerOriginQuery, GetCustomerOriginQueryVariables>;
export const GetGeneralTinByPartyDocument = gql`
    query getGeneralTinByParty($party: String) {
  getGeneralTinByParty(party: $party) {
    id
    tinParty
    tin
    displaySeq
  }
}
    `;

/**
 * __useGetGeneralTinByPartyQuery__
 *
 * To run a query within a React component, call `useGetGeneralTinByPartyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralTinByPartyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralTinByPartyQuery({
 *   variables: {
 *      party: // value for 'party'
 *   },
 * });
 */
export function useGetGeneralTinByPartyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGeneralTinByPartyQuery, GetGeneralTinByPartyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGeneralTinByPartyQuery, GetGeneralTinByPartyQueryVariables>(GetGeneralTinByPartyDocument, baseOptions);
      }
export function useGetGeneralTinByPartyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGeneralTinByPartyQuery, GetGeneralTinByPartyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGeneralTinByPartyQuery, GetGeneralTinByPartyQueryVariables>(GetGeneralTinByPartyDocument, baseOptions);
        }
export type GetGeneralTinByPartyQueryHookResult = ReturnType<typeof useGetGeneralTinByPartyQuery>;
export type GetGeneralTinByPartyLazyQueryHookResult = ReturnType<typeof useGetGeneralTinByPartyLazyQuery>;
export type GetGeneralTinByPartyQueryResult = ApolloReactCommon.QueryResult<GetGeneralTinByPartyQuery, GetGeneralTinByPartyQueryVariables>;
export const GetIdentityTypeDocument = gql`
    query getIdentityType {
  getIdentityType {
    id
    name
  }
}
    `;

/**
 * __useGetIdentityTypeQuery__
 *
 * To run a query within a React component, call `useGetIdentityTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIdentityTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIdentityTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIdentityTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetIdentityTypeQuery, GetIdentityTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetIdentityTypeQuery, GetIdentityTypeQueryVariables>(GetIdentityTypeDocument, baseOptions);
      }
export function useGetIdentityTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetIdentityTypeQuery, GetIdentityTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetIdentityTypeQuery, GetIdentityTypeQueryVariables>(GetIdentityTypeDocument, baseOptions);
        }
export type GetIdentityTypeQueryHookResult = ReturnType<typeof useGetIdentityTypeQuery>;
export type GetIdentityTypeLazyQueryHookResult = ReturnType<typeof useGetIdentityTypeLazyQuery>;
export type GetIdentityTypeQueryResult = ApolloReactCommon.QueryResult<GetIdentityTypeQuery, GetIdentityTypeQueryVariables>;